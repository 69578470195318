import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import EyesOpen from "../iconsopen/EyesOpen";
import EyesClose from "../iconsopen/EyesClose";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import ReCAPTCHA from "react-google-recaptcha";

function AdminLogin() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [data, setData] = useState({
    username: "",
    password: "",
    userType: "admin",
    adminSecretKey: ""
  });
  const navigate = useNavigate();

  // const onChangeRecaptcha = (token) => {
  //   setRecaptchaToken(token);
  // };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLoadingNextPage(true);

    // if (!recaptchaToken) {
    //   toast.error("Please complete the CAPTCHA");
    //   setLoading(false);
    //   return;
    // }

    try {
      const response = await axios.post("https://api.huebits.in/api/adminlogin", 
        { ...data }, 
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { token, message } = response.data;

      if (response.data.error) {
        toast.error(message);
      } else {
        toast.success(message);
        localStorage.setItem('token', token);
        localStorage.setItem('role', 'admin');

        setTimeout(() => {
          navigate("/admin-dashboard");
        }, 1000);
      }
    } catch (error) {
      console.error('Error during login:', error);

      if (error.response) {
        toast.error(error.response.data.message || "An unexpected error occurred");
      } else {
        toast.error("Network error: Please check your connection and try again.");
      }
    } finally {
      setLoading(false);
      setLoadingNextPage(false);
    }
  };

  return (
    <div className="admin-dashboard-users-data">
      <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />
        {loadingNextPage && <div className="spinner"></div>}
        <div className="admin-signup-flex">
        <div className="admin-signup-background-color">
              <h1 className="center-signup">Admin Log in</h1>
                <form onSubmit={submitHandler}>
                <div className="form-group">
                  <label htmlFor="email" className="loginlabel">Enter Email</label>
                  <input type="email" className="login-form-control" id="username" name="username" placeholder="Email address" autoFocus="off" value={data.username} disabled={loading} onChange={handleOnChange} required />
                </div>
                <div className="form-group">
                  <label htmlFor="password" className="loginlabel">Enter Password</label>
                  <input type={showPassword ? "text" : "password"} className="login-form-control" id="password" name="password" placeholder="Enter your password" autoFocus="off" value={data.password} onChange={handleOnChange} required />
                  <div className='icons-password-login' onClick={() => setShowPassword(prev => !prev)}>
                    {showPassword ? <EyesOpen /> : <EyesClose />}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="admin_secret_key" className="signup-label">Admin Secret Key</label>
                  <input
                    type="text"
                    id="admin_secret_key"
                    name="adminSecretKey"
                    className="signup-form-control"
                    placeholder="Enter admin secret key"
                    value={data.adminSecretKey}
                    onChange={handleOnChange}
                    required
                    disabled={loading}
                  />
                </div>
                {/* <div className="students-checkboxlogin">
                  <ReCAPTCHA sitekey="6LdcQAkqAAAAAIeIrHfqOtb_Jb9wst0fWQ9tvaGb" onChange={onChangeRecaptcha}/>
                </div> */}
                <div>
                  <button type="submit" className="students-signin-btn" value="submit" disabled={loading}>{loading ? 'Log in...' : 'Log in'}</button>
                  <p><Link to="/adminforgot-password" className="students-frogot-password">Forgot Password</Link></p>
                </div>
              </form>
              <div className="students-account">
                <p>
                  <span className="students-accounts-p">Don't have an account?{" "} </span>
                  <Link to="/admindashboard" className="loginhere" id="loginhere">
                    Create an account
                  </Link>
                </p>
              </div>
            </div>
      </div>
    </div>
  );
};

export default AdminLogin;
