import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Userprofile = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    programmingskills: "",
    toolstechnologies: "",
  });
  const [userId, setUserId] = useState("");
  const [showToast, setShowToast] = useState(false);
  const token = localStorage.getItem("token");

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUserProfile = useCallback(async (updatedData) => {
    try {
      const response = await axios.put(`https://api.huebits.in/api/userprofile/${userId}`, updatedData, {
        headers: {
          "content-type": "application/json",
          authorization: `jwt ${localStorage.getItem('token')}`
        },
      });

      if (response.data.success) {
        toast.success(response.data.message, { autoClose: 2000 });
        setShowToast(true);
        setData(response.data.data);
        console.log("Updated user data:", response.data.data);
      } else {
        toast.error(response.data.message, { autoClose: 2000 });
        setShowToast(true);
      }
    } catch (error) {
      console.error('Failed to update profile:', error);
      toast.error("Network error: Please check your connection and try again.", { autoClose: 2000 });
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const updatedData = { ...data };
    await handleUserProfile(updatedData);
  };

  useEffect(() => {
    if (token) {
      setUserId(userId);
      handleUserProfile();
    }
  }, [token, userId, handleUserProfile]);

  return (
    <div>
      {showToast && <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />}
      <h1 className="form-profileh1">Technical Skills</h1>
      <div className="students-dashboard-profile">
        <form id="lable" onSubmit={(e) => handleSubmit(e)}>
          <div className="form-group students-dashboard-profile-label">
              <div className="students-dashboard-profile-skills">
                <label htmlFor="programmingskills">Programming Languages:</label>
                <input type="text" name="programmingskills" id="programmingskills" value={data.programmingskills} className="form-control students-profile-label" placeholder='e.g., IoT, JavaScript, Python, Data Science' onChange={handleOnChange} />
              </div>
              <div className="students-dashboard-profile-skills">
                <label htmlFor="toolstechnologies">Tools and Technologies:</label>
                <input type="text" name="toolstechnologies" id="toolstechnologies" className="form-control students-profile-label" placeholder='e.g., IoT, Embedded Systems, Data Science' value={data.toolstechnologies} onChange={handleOnChange} />
              </div>
              {loading && <p>Loading...</p>}
              <button type="submit" className="user-update">Save Data</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Userprofile;