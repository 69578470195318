import React, { useState, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight,faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Headrepage from "../../Homepages/Headerpage/CompainesHeaderpage";
import { ToastContainer, toast } from 'react-toastify';

const Postproject5 = () => {
  const navigate = useNavigate();
  const [completionPercentage, setCompletionPercentage] = useState(60);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

   let Projects = {
      type: document.getElementById("type").value,
      Type: document.getElementById("Type").value,

    };

    const toastId = toast.loading("Submitting your data...");

    try {
      const token = localStorage.getItem('token');
      if (!token) {
      throw new Error('User not authenticated');
       }
      const response = await axios.post(
        "https://api.huebits.in/api/postproject5",{Projects},
        {
            headers: {
              "content-type": "application/json",
              "Authorization": `Bearer ${token}`
            }, 
    });

      if (response.data.status === "Success") {
        console.log("Data submitted successfully");
        console.log("Updated User Data:", response.data.data);
        toast.update(toastId, { render: "Data submitted successfully!", type: "success", isLoading: false, autoClose: 3000 });
        setCompletionPercentage(prevPercentage => prevPercentage + 20);
        setTimeout(() => {
            toast.error(null);
            navigate("/postproject6");
        }, 1500);
      } else {
        console.error("Data submission failed");
        toast.update(toastId, { render: "Submission failed. Please try again.", type: "error", isLoading: false, autoClose: 3000 });
      }
    } catch (error) {
      console.error("An error occurred during Postproject submission",error );
      console.error("Error response:", error.response);
      const errorMessage = error.response?.data?.message || "Network error: Please check your connection and try again.";
      toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 5000 });
    } finally {
    setLoading(false); 
    }
  };

  return (
    <div className="students-flex companies-flex">
    <Headrepage />
    <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />
    <div className="homepagecolores">
    <div className="postprojects1-css">
    <div className="postprojects-css-1">
    <form action="/postproject5" method="POST" id="lable" onSubmit={handleSubmit}>
        <div className="postprojects-css-2">
          <h1 className="postprojects1-container">When do you need the project completed?</h1>
          <p className="yourproject1-p">
          <span>Tell us about the timeframe and budget for the project.</span>
          </p>
          <h2 className="yourproject1-h1">Expected timeframe</h2>
          <input type="text" name="type" id="type" className="postprojects6-css-input" placeholder="No.of days" autofocus="" required />
          <h2 className="yourproject1-h1">Budget</h2>
          <input type="text" name="Type" id="Type" className="postprojects6-css-input" placeholder="$000" autofocus="" required />
          </div>
          <div className="form-group">
          <div className="postprojects1-css-button">
          </div>
          </div>
          <div className="form-group ">
          <div className="postprojects5-css-button">
              <Link to="/postproject4"><button className="postprojects1-button">Back
              <FontAwesomeIcon icon={faArrowLeft} className="faArrowRight" />
              </button></Link>
              <error id="alert "></error>
              <button type="submit" className="postprojects1-button-4" disabled={loading}>
                  {loading ? "Submitting..." : "Next"}
              <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
              </button>
        </div>
        </div>
      </form>
      </div>
      <div className="postprojects1-rangs-steps-1">
          <p>Step 6 of 7</p>
          <div className="completion-percentage">{completionPercentage}%</div>
          <meter className="postprojects1-range-meter" type="Range" name="Range" min="0" max="100" value={completionPercentage} required ></meter>
      </div>
          <hr className="postprojects1-hr"/>
      <div className="postprojects1-rangs-steps-2">
          <p><span>@2023 Pangaea x All Rights Reserved</span></p>
          <p className="p">Terms of Bussiness</p>
      </div>
    </div>
    </div>
    </div>
  );
};


export default Postproject5;
