import React, { useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { Link } from "react-router-dom";
import axios from "axios";
import './topbar.css';

const Topbar = () => {
  const [showNotifications, setShowNotifications] = useState(false);
  const [showUserprofile, setshowUserprofile] = useState(false);
  const [data, setData] = useState({
    fullname: "",
    profilePic: "",
  });

  const token = localStorage.getItem("token");

  const handleUserProfile = async () => {
    try {
      const response = await axios.post('https://api.huebits.in/api/userprofile', {}, {
        headers: {
          "content-type": "application/json",
          Authorization: `jwt ${localStorage.getItem('token')}`
        },
      });

      setData(response.data.data);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  useEffect(() => {
    if (token) {
      handleUserProfile();
    }
  }, [token]);

  const handleNotificationsClick = () => {
    setShowNotifications(!showNotifications);
  };

  const handleUserprofileClick = () => {
    setshowUserprofile(!showUserprofile);
  };

  return (
    <div className="topbardashboard">
      <div className="dashboard">
        <div className="dashboard1">
          <span className="name">
                Welcome <span>{data?.displayName || data?.fullname}</span> Keep Going 
          <span className="name1">You are close to realizing your dreams.</span>
          </span>
          <Box display="flex" justifyContent="space-between" p={2}>
            <div className="darknotification1">
              <Box className="topbar-darknotification">
                <Link to="./notification" style={{ textDecoration: "none" }}>
                  <IconButton onClick={handleNotificationsClick}>
                    <NotificationsOutlinedIcon className="topbar-darknotification2" />
                  </IconButton>
                </Link>
                <IconButton>
                  <SettingsOutlinedIcon className="topbar-darknotification2" />
                </IconButton>
                <Link to="./userprofile" style={{ textDecoration: "none" }}>
                  <IconButton onClick={handleUserprofileClick}>
                    <div className="profile-icon-container">
                      <PersonOutlinedIcon className="topbar-darknotification-profile" />
                      <img src={data.profilePic} className="profile" alt="" />
                    </div>
                  </IconButton>
                </Link>
              </Box>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
