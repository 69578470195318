import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';

const Reveal = ({ children, className }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const variants = {
    hidden: { opacity: 0, y: 100, scale: 0.9 },
    visible: { 
      opacity: 1, 
      y: 0, 
      scale: 1, 
      transition: { duration: 1, type: 'spring' } 
    },
  };

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={variants}
      className={className}
    >
      <div className="homepagehubits-for-students-courses-students-projects1">
        <div className="homepagehubits-for-students-courses-projects1">
          <p className="homepagehubits-for-students-courses-p">
            {inView && (
              <span className="homepagehubits-for-students-courses-pspan1">
                <CountUp end={200} duration={10} />+
              </span>
            )} 
             <span> Courses and Projects</span>
          </p>
          <hr className="homepagehubits-for-students-courses-hr1" />
          <p className="homepagehubits-for-students-courses-p1">
            {inView && (
              <span className="homepagehubits-for-students-courses-pspan">
                <CountUp end={100} duration={10} />+
              </span>
            )}
            <span> Collaborations</span>
          </p>
          <hr className="homepagehubits-for-students-courses-hr2" />
          <p className="homepagehubits-for-students-courses-p2">
            {inView && (
              <span className="homepagehubits-for-students-courses-pspan1">
                <CountUp end={100} duration={10} />%
              </span>
            )}
            <span> Guaranteed Internships, Jobs</span>
          </p>
          <hr className="homepagehubits-for-students-courses-hr3" />
          <p className="homepagehubits-for-students-courses-p3">
            {inView && (
              <span className="homepagehubits-for-students-courses-pspan1">
                <CountUp end={100} duration={10} />%
              </span>
            )}
            <span> Exposure To The Real Industry Experience</span>
          </p>
          <div className="fire"></div>
        </div>
      </div>
      {children}
    </motion.div>
  );
};

export default Reveal;
