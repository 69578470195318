import React from "react";
import './learningpathprogress.css';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Learningpathprogess = () => {
  const percentage = 0;
  
  return (
    <div className="learningpathprogresesbar-backend">
    <div style={{ padding: "10px 10px 10px 10px" }} className="learningpathprogressbar-backend">
      <div className="default1">
      <h1 className="learningpathprogressbar">Learning Path Progress</h1>
        <div className="default" id="default">
          <CircularProgressbar 
            value={percentage} 
            text={`${percentage}%`}
            className="progressbar-text"
            styles={buildStyles({
              pathTransitionDuration: 0.5,
              pathColor: ' #052659 ',
              textColor: "#052659",
              trailColor: "white",
              textSize:"18px",
            })}
          />
        </div>
      </div>
    </div>
    </div>
  );
}

export default Learningpathprogess;
