import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faCloudUploadAlt,faCode,faDatabase,faMobileAlt,faCodeBranch  } from "@fortawesome/free-solid-svg-icons";
import Iot1 from "../../../assets/icons/New folder/iot-1.png";
import Iot2 from "../../../assets/icons/New folder/iot-7.png";
import Iot3 from "../../../assets/icons/New folder/iot-2.png";
import Iot4 from "../../../assets/icons/New folder/iot-3.png";
import Iot5 from "../../../assets/icons/New folder/iot-4.png";
import Iot6 from "../../../assets/icons/New folder/iot-8.png";
import Iot7 from "../../../assets/icons/New folder/iot-9.png";
import Iot8 from "../../../assets/icons/New folder/iot-5.png";
import Iot9 from "../../../assets/icons/New folder/iot-6.png";
import Reveal from '../../Homepages/hook/useScrollposition';

const Internetlearning = () => {
  return (
    <div>
      <Reveal>
      <div className="internet-courses-ourney1">
        <ul className="internet-courses-ourney-navbar-nav">
          <li className="internet-courses-ourney-nav-item">
          <img className="iot-development-icon__microchip" src={Iot1} alt="Internet"/>
           IoT using development boards<FontAwesomeIcon icon={faChevronDown} />
            <ul className="internet-courses-ourney-dropdown-menu">
              <li>NodeMCU, ESP32 Development Board, Raspberry Pi, Arduino, TI boards and many more
              </li>
            </ul>
          </li>
        </ul>
        <ul className="internet-courses-ourney-navbar-nav">
          <li className="internet-courses-ourney-nav-item">
          <img className="iot-development-icon__microchip" src={Iot2} alt="Internet"/>
           Networking Technologies<FontAwesomeIcon icon={faChevronDown} />
            <ul className="internet-courses-ourney-dropdown-menu">
              <li>WiFi, Bluetooth,BLE, Zigbee, GSM, LoRa, 3G/4G, Ethernet, GPS, TCP/IP, UDP, WebSocket’s Data Protocols: HTTP, MQTT, COAP, AMQP
              </li>
            </ul>
          </li>
        </ul>
        <ul className="internet-courses-ourney-navbar-nav">
          <li className="internet-courses-ourney-nav-item">
          <img className="iot-development-icon__microchip" src={Iot3} alt="Internet"/>
          IoT Cloud Platforms<FontAwesomeIcon icon={faChevronDown} />
            <ul className="internet-courses-ourney-dropdown-menu">
              <li>Amazon Web Services, Microsoft Azure, Blynk, Thingspeak, Thingsboard and Thingworx
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="internet-courses-ourney2">
        <ul className="internet-courses-ourney-navbar-nav">
          <li className="internet-courses-ourney-nav-item">
          <img className="iot-development-icon__microchip" src={Iot4} alt="Internet"/>
          IoT Application Development<FontAwesomeIcon icon={faChevronDown} />
          <ul className="internet-courses-ourney-dropdown-menu">
            <li> MERN stack, Grafana, Node-Red, Flutter, React Native
            </li>
          </ul>
          </li>
        </ul>
        <ul className="internet-courses-ourney-navbar-nav">
          <li className="internet-courses-ourney-nav-item">
          <img className="iot-development-icon__microchip" src={Iot5} alt="Internet"/>
            programming for IoT<FontAwesomeIcon icon={faChevronDown} />
            <ul className="internet-courses-ourney-dropdown-menu">
              <li>Python, Micropython, C/C++, Arduino, Embedded C, Node-RED, Linux, JavaScript
              </li>
            </ul>
          </li>
        </ul>
        <ul className="internet-courses-ourney-navbar-nav">
          <li className="internet-courses-ourney-nav-item">
          <img className="iot-development-icon__microchip" src={Iot6} alt="Internet"/>
            Industrial IoT<FontAwesomeIcon icon={faChevronDown} />
            <ul className="internet-courses-ourney-dropdown-menu">
              <li>Modbus, RS232, CAN Bus, OPCUA,  Profibus. 
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="internet-courses-ourney3">
        <ul className="internet-courses-ourney-navbar-nav">
          <li className="internet-courses-ourney-nav-item">
          <img className="iot-development-icon__microchip" src={Iot7} alt="Internet"/>
          IoT Analytics<FontAwesomeIcon icon={faChevronDown} />
          <ul className="internet-courses-ourney-dropdown-menu">
            <li>Analytics, Machine Learning edge computing, AWS Greengrass, Azure edge
            </li>
          </ul>
          </li>
        </ul>
        <ul className="internet-courses-ourney-navbar-nav">
          <li className="internet-courses-ourney-nav-item">
          <img className="iot-development-icon__microchip" src={Iot8} alt="Internet"/>
            Data bases<FontAwesomeIcon icon={faChevronDown} />
            <ul className="internet-courses-ourney-dropdown-menu">
              <li>SQL, Mongodb and Influxdb
              </li>
            </ul>
          </li>
        </ul>
        <ul className="internet-courses-ourney-navbar-nav">
          <li className="internet-courses-ourney-nav-item">
          <img className="iot-development-icon__microchip" src={Iot9} alt="Internet"/>
            Edge Computing<FontAwesomeIcon icon={faChevronDown} />
            <ul className="internet-courses-ourney-dropdown-menu">
              <li>Python, Tensorflow lite, AWS IoT Greengrass, Azure IoT Edge
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </Reveal>
    </div>
  );
}

const Embeddedlearning = () => {
  return (
    <div>
      <Reveal>
      <div className="internet-courses-ourney1">
        <ul className="internet-courses-ourney-navbar-nav">
          <li className="internet-courses-ourney-nav-item">
           Embedded Systems using development boards<FontAwesomeIcon icon={faChevronDown} />
            <ul className="internet-courses-ourney-dropdown-menu">
              <li>NodeMCU, ESP32 Development Board, Raspberry Pi, Arduino, TI boards and many more
              </li>
            </ul>
          </li>
        </ul>
        <ul className="internet-courses-ourney-navbar-nav">
          <li className="internet-courses-ourney-nav-item">
           Networking Technologies<FontAwesomeIcon icon={faChevronDown} />
            <ul className="internet-courses-ourney-dropdown-menu">
              <li>WiFi, Bluetooth,BLE, Zigbee, GSM, LoRa, 3G/4G, Ethernet, GPS, TCP/IP, UDP, WebSocket’s Data Protocols: HTTP, MQTT, COAP, AMQP
              </li>
            </ul>
          </li>
        </ul>
        <ul className="internet-courses-ourney-navbar-nav">
          <li className="internet-courses-ourney-nav-item">
          <FontAwesomeIcon icon={faCloudUploadAlt} className="iot-development-icon__microchip" />
          Embedded Systems Cloud Platforms<FontAwesomeIcon icon={faChevronDown} />
            <ul className="internet-courses-ourney-dropdown-menu">
              <li>Amazon Web Services, Microsoft Azure, Blynk, Thingspeak, Thingsboard and Thingworx
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="internet-courses-ourney2">
        <ul className="internet-courses-ourney-navbar-nav">
          <li className="internet-courses-ourney-nav-item">
          <FontAwesomeIcon icon={faMobileAlt  } className="iot-development-icon__microchip" />
          Embedded Systems Application Development<FontAwesomeIcon icon={faChevronDown} />
          <ul className="internet-courses-ourney-dropdown-menu">
            <li> MERN stack, Grafana, Node-Red, Flutter, React Native
            </li>
          </ul>
          </li>
        </ul>
        <ul className="internet-courses-ourney-navbar-nav">
          <li className="internet-courses-ourney-nav-item">
          <FontAwesomeIcon icon={faCode} className="iot-development-icon__microchip" />
           programming for Embedded Systems<FontAwesomeIcon icon={faChevronDown} />
            <ul className="internet-courses-ourney-dropdown-menu">
              <li>Python, Micropython, C/C++, Arduino, Embedded C, Node-RED, Linux, JavaScript
              </li>
            </ul>
          </li>
        </ul>
        <ul className="internet-courses-ourney-navbar-nav">
          <li className="internet-courses-ourney-nav-item">
           Industrial Embedded Systems<FontAwesomeIcon icon={faChevronDown} />
            <ul className="internet-courses-ourney-dropdown-menu">
              <li>Modbus, RS232, CAN Bus, OPCUA,  Profibus. 
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="internet-courses-ourney3">
        <ul className="internet-courses-ourney-navbar-nav">
          <li className="internet-courses-ourney-nav-item">
          Embedded Systems Analytics<FontAwesomeIcon icon={faChevronDown} />
          <ul className="internet-courses-ourney-dropdown-menu">
            <li>Analytics, Machine Learning edge computing, AWS Greengrass, Azure edge
            </li>
          </ul>
          </li>
        </ul>
        <ul className="internet-courses-ourney-navbar-nav">
          <li className="internet-courses-ourney-nav-item">
            <FontAwesomeIcon icon={faDatabase} className="iot-development-icon__microchip" />
            Data bases<FontAwesomeIcon icon={faChevronDown} />
            <ul className="internet-courses-ourney-dropdown-menu">
              <li>SQL, Mongodb and Influxdb
              </li>
            </ul>
          </li>
        </ul>
        <ul className="internet-courses-ourney-navbar-nav">
          <li className="internet-courses-ourney-nav-item">
          <FontAwesomeIcon icon={ faCodeBranch  } className="iot-development-icon__microchip" />
            Edge Computing<FontAwesomeIcon icon={faChevronDown} />
            <ul className="internet-courses-ourney-dropdown-menu">
              <li>Python, Tensorflow lite, AWS IoT Greengrass, Azure IoT Edge
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </Reveal>
    </div>
  );
}

export {Internetlearning, Embeddedlearning};
