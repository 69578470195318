import React, { useEffect, useState, useCallback } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import axios from "axios";
import Getaccesstoallcourses from "../courses/Get_access_to_all_courses";
import "./courses.css";

const CoursesProgress = () => {
  const [courses, setCourses] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");

  const handleUserProfile = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://api.huebits.in/api/learningpaths/courses/",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("API Response:", response.data);

      if (response.status === 200) {
        const fetchedCourses = response.data.data?.courses || [];
        setCourses(fetchedCourses);
        setIsSubscribed(response.data.isSubscribed);
      } else {
        console.error("API Error:", response.statusText);
        setError("Error fetching data.");
      }
    } catch (error) {
      console.error("Error fetching user details:", error.message);
      setError("Error fetching data.");
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    handleUserProfile();
  }, [handleUserProfile]);

  // Limit the courses displayed to 6 and filter for progress >= 1%
  const displayedCourses = isSubscribed
    ? courses.filter(course => course.progress >= 0).slice(0, 6)
    : []; // Show courses only if subscribed

  const getColorForProgress = (progress) => {
    return progress >= 1 && progress <= 100 ? "#052659" : "Indigo";
  };

  return (
    <div className="CoursesProgress">
      <h1 className="CoursesProgress1">Courses Progress</h1>
      <div className="courses-container">
      {loading ? (
          <p>Loading...</p>
        ) : displayedCourses.length === 0 && !isSubscribed ? (
          <Getaccesstoallcourses />
        ) : displayedCourses.length === 0 ? (
          <p></p>
        ) : (
          displayedCourses.map((course, index) => (
            <div className="course" key={index}>
              <div style={{ padding: "0px" }}>
                <h2 className="course-progress-title">{course.courseTitle}</h2>
                <ProgressBar
                  completed={course.progress}
                  animateOnRender={true}
                  bgColor={getColorForProgress(course.progress)}
                  className="course-bar"
                  text={`${course.progress}%`}
                />
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default CoursesProgress;

