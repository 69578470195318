import React from "react";
import {Link} from "react-router-dom";
import Congratulations from "../../../assets/congratulations.png";
import Headrepage from "../../Homepages/Headerpage/CompainesHeaderpage";

const PostprojectsUploads = () => {
    return (
        <div className="students-flex companies-flex">
        <Headrepage />
        <div className="homepagecolores">
        <div className="projects-submit-css">
        <div className="postprojects-css-1">
        <img className="projects-huebitsimg" src={Congratulations} alt=""/>
        <h1 className="postprojects1-container">Congratulations!</h1>
        <h1 className="postprojects1-container">Your project is received</h1>
        <p className="yourproject1-p">Expect further instructions via email, including a meeting to discuss technical details.</p>
        <form action="/projectssubmit" method="POST" className="projects-publish">
        <Link  to="/postproject1">
        <button className="publish-button">
        Post A Project 
        </button>
        </Link>
        <Link to="/compainesdashboard">
        <button  className="publish-button1">VIEW MY DASHBOARD</button>
        </Link>
        </form>
        </div>
       </div>
      </div>
    </div>
    )
}


export default PostprojectsUploads;