import React,{useState} from "react";
import axios from 'axios';
import SmartShoeImage from "../../assets/dashboard-images/images/images/projects/16.png";
import Amazon from "../../assets/dashboard-images/images/images/15.jpg";
import Microsoft  from "../../assets/dashboard-images/images/images/7.png";
import Internet from "../../assets/dashboard-images/images/images/4.png";
import SmartHome from "../../assets/dashboard-images/images/images/3.png";
import ESP32Arduino from "../../assets/dashboard-images/images/images/2.png";
import NodeMCUMATLAB from "../../assets/dashboard-images/images/images/9.png";
import NternetESP32Arduino from "../../assets/dashboard-images/images/images/projects/26.png";
import NodeRed from "../../assets/dashboard-images/images/images/projects/21.png";
import BluetoothTechnology from "../../assets/dashboard-images/images/images/16.jpg";
import JavaScript from "../../assets/dashboard-images/images/images/10.png";
import SystemsProject from "../../assets/dashboard-images/images/images/projects/10.png";
import SmartusingESP32 from "../../assets/images/course.png";
import IndustrialInternet from "../../assets/dashboard-images/images/images/17.jpg";
import IndustrialMATLAB from "../../assets/dashboard-images/images/images/6.png";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Projects from "../learningpath/projects/Iot-developer";
import 'bootstrap/dist/css/bootstrap.min.css';

import './start.css';

const courses = [
  {
    courseId: 0.1,
    courseTitle: "Introduction to Internet of Things",
    videoUrl: "https://learn.huebits.in/courses/Introduction-to-Internet-of-Things--66e8028064721c6daa143ae8",
    image: SmartShoeImage,
    completion: 0
  },
  {
    courseId: 0.2,
    courseTitle: "Python Programming",
    videoUrl: "https://learn.huebits.in/courses/Python-Programming-660e8bc9699598329d883cf6",
    image: Amazon,
    completion: 0
  },
  {
    courseId: 0.3,
    courseTitle: "IoT and Embedded Systems with ESP32",
    videoUrl: "https://learn.huebits.in/courses/Internet-of-Things-using-ESP32-and-Arduino-660e7e601e0fac451502fb0c",
    image: Microsoft,
    completion: 0
  },
  {
    courseId: 0.4,
    courseTitle: "IoT using Raspberry Pi and Python",
    videoUrl: "https://learn.huebits.in/courses/Internet-of-Things-using-Raspberry-Pi-and-Python-66044b9cdf77f21d16e2c40c",
    image: Internet,
    completion: 0
  },
  {
    courseId: 0.5,
    courseTitle: "Azure IoT Certification Training",
    videoUrl: "https://learn.huebits.in/courses/Microsoft-Azure-IoT-Certification--660ea20144dad02ad4c6bfcb",
    image: SmartHome,
    completion: 0
  },
  {
    courseId: 0.6,
    courseTitle: "AWS IoT Certification Training",
    videoUrl: "https://learn.huebits.in/courses/Amazon-Web-Services-AWS-IoT-Certification-660ea25494fd573437e5c251",
    image:  ESP32Arduino,
    completion: 0
  },
  {
    courseId: 0.7,
    courseTitle: "Master ESP32, Bluetooth, and BLE",
    videoUrl: "https://learn.huebits.in/courses/Master-BLE-and-Bluetooth-Technology-660e80340229d83cda0e6e48", 
    image: NodeMCUMATLAB,
    completion: 0
  },
  {
    courseId: 0.8,
    courseTitle: "Master WIFI communication",
    videoUrl: "https://learn.huebits.in/courses/Master-WIFI-communication-66acd995d6f9082bdc424251",
    image: NternetESP32Arduino,
    completion: 0
  },
  {
    courseId: 0.9,
    courseTitle: "Long distance wireless networks: GSM, Lora, NBIOT",
    videoUrl: "https://learn.huebits.in/courses/Long-distance-wireless-networks-Cellular-LoRa-NBIOT-66acdc5519d25e5892c64c42", 
    image: NodeRed,
    completion: 0
  },
  {
    courseId: 0.10,
    courseTitle: "Master MQTT",
    videoUrl: "https://learn.huebits.in/courses/Mastering-MQTT-660e8acf44dad02ad4c6b89c", 
    image: BluetoothTechnology,
    completion: 0
  },
  {
    courseId: 0.11,
    courseTitle: "Full stack IoT using JavaScript, MERN stack and Raspberry Pi",
    videoUrl: "https://learn.huebits.in/courses/Full-Stack-IoT-using-Raspberry-Pi-and-JavaScript-660e7f660229d83cda0e6da7", 
    image: JavaScript,
    completion: 0
  },
  {
    courseId: 0.12,
    courseTitle: "Embedded Systems using ESP32 and ESP-IDF",
    videoUrl: "https://learn.huebits.in/courses/IoT-and-Embedded-Systems-using-ESP32-and-ESP-IDF-66acdb6a19d25e5892c64c12", 
    image: SystemsProject,
    completion: 0
  },
  {
    courseId: 0.13,
    courseTitle: "Developing Web Applications for IoT Using Python and JavaScript",
    videoUrl: "https://learn.huebits.in/courses/Developing-Web-Applications-for-IoT-Using-Python-andJavaScript-66e807c6ece5194df3bc5213", 
    image: SmartusingESP32,
    completion: 0
  },
  {
    courseId: 0.14,
    courseTitle: "IoT Data analysis using python",
    videoUrl: "https://learn.huebits.in/courses/IoT-Data-Analysis-using-Python-660eb969cf0168138b68bec0",
    image: IndustrialInternet,
    completion: 0
  },
  {
    courseId: 0.15,
    courseTitle: "IoT Data analysis using MATLAB",
    videoUrl: "https://learn.huebits.in/courses/IoT-Data-Analytics-using-NodeMCU-and-MATLAB-660e7e9334780c3dfae59a8f",
    image: IndustrialMATLAB,
    completion: 0
  }
];

const Start = () => {
    const [, setMessage] = useState(null);
  
    const handleCourseClick = async (courseTitle, courseId, videoUrl) => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('User not authenticated');
        }
        window.open(videoUrl);
        const response = await axios.post(
          `https://api.huebits.in/api/learningpaths/courses/`,
          { courseId, courseTitle },
          {
            headers: {
              "content-type": "application/json",
              "Authorization": `Bearer ${token}`
            },
          }
        );
        setMessage(response.courses.message);
      } catch (error) {
        console.error('Error handling course click:', error);
        setMessage('Error: Unable to save course information.');
      }
    };


  return (
    <div>
    <div className="mylearningpaths">
      <h1 className="mylearningpaths-h1">IoT developer / Engineer</h1>
      <p className="mylearningpaths-p">Courses</p>
      <div className="mylearningpath">
        <div className="Wrapper-sc-fvyqwl-1 iZEyIQ AssignmentList__Timeline-sc-1t9paan-2 chIzFH">
          <ul className="Circle-sc-fvyqwl-1">
            {courses.map((course, index) => (
              <li key={course.courseId}>
                <div className="learningpath-courses-dashboard">
                  <div>
                  <img src={course.image} alt={course.courseTitle} className="smart-shoe-development" />
                  </div>
                  <div>
                  <p className={`pythoncourses${course.courseId}`}>
                  {index + 1}. {course.courseTitle}
                  </p>
                  <div className="learningpath-courses-dashboard-progressbar">
                  <p className="learningpath-courses-dashboard-skills-completed">{course.completion}%</p>
                  <ProgressBar now={course.completion} className={`custom-progress-bar`} />
                  </div>
                  <div>
                     <div>
                    {course.completion === 100 ? (
                      <>
                      <button onClick={() => handleCourseClick(course.courseTitle, course.courseId, course.videoUrl)} className="learningpath-courses-dashboard-button-start">Download Certificate</button>
                      </>
                    ) : course.completion >= 10 ? (
                      <button onClick={() => handleCourseClick(course.courseTitle, course.courseId, course.videoUrl)}  className="learningpath-courses-dashboard-button-start">Continue Learning</button>
                    ) : (
                      <button onClick={() => handleCourseClick(course.courseTitle, course.courseId, course.videoUrl)} className="learningpath-courses-dashboard-button-start">Subscribe</button>
                    )}
                  </div>
                  </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Projects />
    </div>
  </div>
);
};

export default Start;