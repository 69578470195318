import React from 'react';

export const Active = () => {
  return (
    <div>
        <h1>Active</h1>
    </div>
  )
}
export default Active;
