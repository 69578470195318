import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import Homepage from './components/Homepages/Homepage/Homepage';
//Students
import Students from './components/studentspages/Students/Students';
import InternetofThings from './components/studentspages/Students-Learnmore/Internet-of-things';
import EmbeddedSystems from './components/studentspages/Students-Learnmore/Embedded-Systems';
import DataSciences from './components/studentspages/Students-Learnmore/Data-Science-AI-ML-DL';
//Comanies
import Companies from './components/companiespages/Companies/Companies';
import InternetofThing from './components/companiespages/Companies-Learnmore/Internet-of-things';
import EmbeddedSystem from './components/companiespages/Companies-Learnmore/Embedded-Systems';
import DataScience from './components/companiespages/Companies-Learnmore/Data-Science-AI-ML-DL';
import Brilliant from './components/companiespages/Register-Compaines/Register';
import Transformation from './components/companiespages/Login-Compaines/Login';
import Forgotpasswordcompanies from './components/companiespages/Forgot-Password-companies/Forgot-Password';
import Homepagespostprojects from './components/companiespages/companiespopup/companies-homepage';
import Postproject1 from './components/companiespages/Compaines-Projects/postproject1';
import Postproject2 from './components/companiespages/Compaines-Projects/postproject2';
import Postproject3 from './components/companiespages/Compaines-Projects/postproject3';
import Postproject4 from './components/companiespages/Compaines-Projects/postproject4';
import Postproject5 from './components/companiespages/Compaines-Projects/postproject5';
import Postproject6 from './components/companiespages/Compaines-Projects/postproject6';
import PostprojectsUploads from './components/companiespages/Compaines-Projects/postprojectsuploads';
import Compainesdashboard from './components/companiespages/Compaines-Projects/Compaines-Dashboard';
//Students dashboard
import Signup from './dashboard/Register/Register';
import Sigin from './dashboard/Login/Login';
import ForgotPassword from './dashboard/Forgot-Password/Forgot-Password';
import Topbar from './dashboard/global/Topbar';
import NotificationComponent from './dashboard/global/notification/notification';
import Userprofile from './dashboard/global/userprofile/User-Profile';
import Dashboard from './dashboard/dashboard';
import Sidebar from './dashboard/global/Sidebar';
import Learningpath from './dashboard/learningpath';
import Iotdeveloper from "./dashboard/learningpath/Iot-developer";
import Industrialiot from "./dashboard/learningpath/Industrial-iot-and-insutry";
import Iotsecurity from "./dashboard/learningpath/Iot-security-specialist";
import Iotdatascientist from "./dashboard/learningpath/Iot-data-scientist";
import Iothardware from "./dashboard/learningpath/Iot-hardware-engineer";
import Started from './dashboard/learningpath/Customizedpath';
import Courses from './dashboard/courses/courses';
import Projects from './dashboard/projects/projects';
import Capstoneaimagic from './dashboard/capstoneaimagic/capstoneaimagic';
import LiveSessions from './dashboard/Live-Sessions/Live-Sessions';
import Work from './dashboard/Work/Work';
import Learnerdataapi from './learnerdataapi/learnerdata';
import AdminSignup from './Adminsignup/Adminsignup';
import AdminLogin from './Adminlogin/Adminlogin';
import AdminForgotpassword from "./AdminForgotpassword/Forgot-Password";
import AdminDashboard from './AdminDashboard/AdminDashboard';


function App() {
    return (
        <>
            <CssBaseline />
            <div className="app">
                <Routes>
                    {/* Homepages routes */}
                    <Route path="/" element={<Homepage />} />
                    {/* Students routes*/}
                    <Route path="/students" element={<Students />} />
                    <Route path="/internetofthings" element={<InternetofThings />} />
                    <Route path="/embeddedsystems" element={<EmbeddedSystems />} />
                    <Route path="/datascience" element={<DataSciences />} />
                    {/* Companies routes */}
                    <Route path="/companies" element={<Companies />} />
                    <Route path="/internet-of-things" element={<InternetofThing/>} />
                    <Route path="/embedded-systems" element={<EmbeddedSystem/>} />
                    <Route path="/data-science" element={<DataScience/>} />
                    <Route path="/companiessignup" element={<Brilliant />} />
                    <Route path="/companiessignin" element={<Transformation />} />
                    <Route path="/companiesforgotpassword" element={<Forgotpasswordcompanies />} />
                    <Route path="/homepagespostprojects" element={<Homepagespostprojects />} />
                    <Route path="/postproject1" element={<Postproject1 />} />
                    <Route path="/postproject2" element={<Postproject2 />} />
                    <Route path="/postproject3" element={<Postproject3 />} />
                    <Route path="/postproject4" element={<Postproject4 />} />
                    <Route path="/postproject5" element={<Postproject5 />} />
                    <Route path="/postproject6" element={<Postproject6 />} />
                    <Route path="/projectssubmit" element={<PostprojectsUploads />} />
                    <Route path="/compainesdashboard" element={<Compainesdashboard />} />
                    {/* Students Authentication routes */}
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/login" element={<Sigin />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    {/* Students Learner data API */}
                    <Route path="/learnerdataapi" element={<Learnerdataapi />} />
                    {/* Admindashboard */}
                    <Route path="/admindashboard" element={<AdminSignup />} />
                    <Route path="/adminlogin" element={<AdminLogin />} />
                    <Route path="/adminforgot-password" element={<AdminForgotpassword/>} />
                    <Route path="/admin-dashboard" element={<AdminDashboard />} />
                     {/* Students  Dashboard routes */}
                     <Route path="/dashboard/*" element={<DashboardLayout />} />
                </Routes>
            </div>
        </>
    );
}

function DashboardLayout() {
    return (
        <>
            <Sidebar />
            <main className="content">
                <Topbar />
                <Routes>
                    <Route index element={<Dashboard />} />
                    <Route path="notification" element={<NotificationComponent />} />
                    <Route path="userprofile" element={<Userprofile />} />
                    <Route path="learningpath" element={<Learningpath />} />
                    <Route path="learningpath/iot-developer-engineer" element={<Iotdeveloper />} />
                    <Route path="learningpath/industrial-iot-and-insutry4.0" element={<Industrialiot />} />
                    <Route path="learningpath/iot-security-specialist" element={<Iotsecurity />} />
                    <Route path="learningpath/iot-data-scientist" element={<Iotdatascientist />} />
                    <Route path="learningpath/iot-hardware-engineer" element={<Iothardware />} />
                    <Route path="learningpath/started" element={<Started />} />
                    <Route path="courses" element={<Courses />} />
                    <Route path="projects" element={<Projects />} />
                    <Route path="capstoneaimagic" element={<Capstoneaimagic />} />
                    <Route path="livesessions" element={<LiveSessions />} />
                    <Route path="work" element={<Work />} />
                </Routes>
            </main>
        </>
    );
}

export default App;
