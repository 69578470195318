import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "./userprofile.css";

const Userprofile = () => {
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(null);
  const [data, setData] = useState({
    fullname: "",
    dateofbirth: "",
    gender: "",
  });
  const [userId, setUserId] = useState("");
  const [showToast, setShowToast] = useState(false);
  const token = localStorage.getItem("token");

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUserProfile = useCallback(async (updatedData) => {
    try {
      const response = await axios.put(`https://api.huebits.in/api/userprofile/`, updatedData, {
        headers: {
          "content-type": "application/json",
          authorization: `jwt ${localStorage.getItem('token')}`,
        },
      });

      console.log("Received response:", response.data);

      if (response.data.success) {
        toast.success(response.data.message, { autoClose: 2000 });
        setShowToast(true);
        setData(response.data.data);
        console.log("Updated user profile data:", response.data.data);
      } else {
        toast.error(response.data.message, { autoClose: 2000 });
        setShowToast(true);
      }
    } catch (error) {
      console.error('Failed to update profile:', error);
      toast.error("Network error: Please check your connection and try again.", { autoClose: 2000 });
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    const updatedData = { ...data };

    console.log("Submitting user profile update:", updatedData);
    
    await handleUserProfile(updatedData);
  };

  useEffect(() => {
    if (token) {
      setUserId(userId);
      handleUserProfile();
    }
  }, [token, userId, handleUserProfile]);

  return (
    <div>
      {showToast && <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />}
      <h1 className="form-profileh1">Basic Information</h1>
      <div className="students-dashboard-profile">
        <form id="lable" onSubmit={handleSubmit}>
          <div className="form-group students-dashboard-profile-label">
            <div>
              <label htmlFor="fullname">Full Name:</label>
              <input
                type="text"
                name="fullname"
                id="fullname"
                value={data.fullname}
                className="form-control students-profile-label"
                placeholder="e.g., John Doe"
                onChange={handleOnChange}
              />
            </div>
            <div>
              <label htmlFor="dateofbirth">Date of Birth:</label>
              <input
                type="date"
                name="dateofbirth"
                id="dateofbirth"
                className="form-control"
                placeholder="Date of Birth"
                value={data.dateofbirth}
                onChange={handleOnChange}
              />
            </div>
            <div>
              <label htmlFor="gender" className="gender">Gender:</label>
              <div className="custom-select-wrapper">
                <select
                  name="gender"
                  id="gender"
                  className="form-control"
                  value={data.gender}
                  onChange={handleOnChange}
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                <i className="fas fa-caret-down dropdown-icon"></i>
              </div>
            </div>
            <div>
              {loading && <p>Loading...</p>}
              <button type="submit" className="user-update">Update Profile</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Userprofile;
