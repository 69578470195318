import React, { useState, useEffect } from 'react';
import axios from 'axios';

export const CompaniesDashboard = () => {
    const [postProjects, setPostProjects] = useState([]);

    useEffect(() => {
        const fetchPostProjects = async () => {
            try {
                const response = await axios.post('https://api.huebits.in/api/companiesprofile', {}, {
                    headers: {
                        "content-type": "application/json",
                        authorization: `jwt ${localStorage.getItem('token')}`
                    },
                });
                setPostProjects(response.data.data.postprojects);
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };
        fetchPostProjects();
    }, []);

    return (
        <div className="companies-dashboard">
            <h1>Pending Projects</h1>
            <ul>
                {postProjects.map(project => (
                    <li key={project._id} className='companies-dashboard-project'>
                        <div className='companies-dashboard-projects'>
                            {project.projectselectname && <p>Title: {project.projectselectname}</p>}
                        </div>
                        <div className='companies-dashboard-title'>
                            {project.title1 && <p>Title: {project.title1}</p>}
                        </div>
                        <div className='companies-dashboard-textarea'>
                            {project.textarea && <p>Textarea: {project.textarea}</p>}
                        </div>
                        <div className='companies-dashboard-skills'>
                            {project.skills && <p>Skills: {project.skills}</p>}
                            {project.skills1 && <p>Skills1: {project.skills1}</p>}
                            {project.skills3 && <p>Skills3: {project.skills3}</p>}
                        </div>
                        <div className='companies-dashboard-types'>
                            {project.type && <p>Type: {project.type}</p>}
                            {project.Type && <p>Type: {project.Type}</p>}
                        </div>
                        {project.path && (
                            <div className='companies-dashboard-path'>
                                <p>Path: <a href={`https://api.huebits.in/uploads/${project.path}`} download>
                                    {project.originalname && <span>{project.originalname}</span>}
                                </a></p>
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CompaniesDashboard;