import React, {useState,useEffect, useCallback} from 'react';
import {Link} from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Headrepage from "../../Homepages/Headerpage/CompainesHeaderpage";
import Active from './Active';
import Pending from './Pending';
import Completed from './Completed';
import avatar from '../../../helper/profile.png';
import convertToBase64 from '../../../helper/Convert';
import { ToastContainer, toast } from 'react-toastify';
import "./Homepagespostprojects.css";

export const CompainesDashboard = () => {
    const [activeLink, setActiveLink] = useState(null);
    const [favoriteLink, setFavoriteLink] = useState(null);
    const [launchedLink, setLaunchedLink] = useState(null);
    const [loading, setLoading] = useState(false);
    const [, setError] = useState(null);
    const [, setSuccessVisible] = useState(false); 
    const [, setFile] = useState(null);
    const [data,setData] = useState({
      companyName: "",
        name: "",
        mobile: "",
        profilePic : "",
      })
  
      const handleLinkClick = (linkName) => {
          setActiveLink(linkName);
          setFavoriteLink(linkName);
          setLaunchedLink(linkName);
      };

      const [userId, setUserId] = useState("");
      const [showToast, setShowToast] = useState(false); 
      const handleOnChange = (e) => {
        const { name, value } = e.target;
    
        setData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }

      const navigate = useNavigate()
      const token = localStorage.getItem("token")

      const onUpload = async (e) => {
        const base64 = await convertToBase64(e.target.files[0]);
        setFile(base64);
        setData((prevData) => ({
          ...prevData,
          profilePic: base64 
        }));
      };
    
      const handleUserProfile = useCallback(async (updatedData) => {
        try {
          const response = await axios.post(`https://api.huebits.in/api/companiesprofile/${userId}`, updatedData, {
            headers : {
            "content-type" : "application/json",
            authorization : `jwt ${localStorage.getItem('token')}`
          },
          });
          
          if (response.data.success) {
            toast.success(response.data.message, { autoClose: 3000 });
            setShowToast(true);
            setData(response.data.data);
            console.log("Updated user profile data:", response.data.data);
            setSuccessVisible(true); 
            setTimeout(() => {
              setSuccessVisible(false); 
            }, 2000);
          } else {
            toast.error(response.data.message, { autoClose: 3000 });
            setShowToast(true);
          }
        } catch (error) {
          console.error('Failed to update profile:', error);
          toast.error("Network error: Please check your connection and try again.", { autoClose: 3000 });
          setShowToast(true);
        } finally {
          setLoading(false);
        }
      }, [userId]);
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);
        const updatedData = { ...data,
          companyName: data.companyName,
            name: data.name,
            mobile: data.mobile, 
            profilePic: data.profilePic, 
          };
        
          await handleUserProfile(updatedData);
        }
        
        useEffect(() => {
          if (!token) {
            navigate("/");
          } else {
            setUserId(userId);
            handleUserProfile();
          }
        }, [token, userId, handleUserProfile, navigate]);

    const handleLogOut = ()=>{
        console.log("Logout button clicked");
        localStorage.clear()
        navigate("/")
  }

  return (
    <div className="students-flex companies-flex">
      <Headrepage />
       <div className="homepagecolores">
        {showToast && <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />}
        <div className='compaines-dashboards-profiles'>
        <ul className='compaines-dashboard-flex'>
        <li className='compaines-project-profiles'>
        <div>
        <p className='compaines-project-profiles-p'>Welcome back <span>{data?.companyName}</span></p>
        </div>
        <div className='compaines-dashboard'>
        <Link
            className={activeLink === 'active'}
            onClick={() => handleLinkClick('active')}
            style={{ color: 'inherit' }} >
            <h1 className='compaines-active'>Active</h1>
            </Link>
        <Link
            className={favoriteLink === 'favorite'}
            onClick={() => handleLinkClick('favorite')}
            style={{ color: 'inherit' }} >
            <h1 className='compaines-pending'>Pending</h1>
        </Link>
        <Link
            className={launchedLink === 'launched'}
            onClick={() => handleLinkClick('launched')}
            style={{ color: 'inherit' }} >
        <h1 className='compaines-completed'>Completed</h1>
        </Link>
        </div>
        <div className='compaines-project-profiles-active'>
        {activeLink === 'active' && <Active />}
        {favoriteLink === 'favorite' && <Pending />}
        {launchedLink === 'launched' && <Completed />}
        </div>
        </li>
        <div>
        <div className='compaines-profile-logout'>
        <h1 className='compaines-profile-h1'>Compaines Profile</h1>
        <div className='compaines-dashboard-profile-logout'>
        <button className='compaines-logout' onClick={handleLogOut}>Logout</button>
        <div className='dashboard-companies-postprojects'>
        <Link to="/Homepagespostprojects"><button className="postprojects1-button">Postprojects</button></Link>
        </div>
        </div>
        </div>
        <div className='compaines-profile'>
        <li>
        <div>
        <form action="/companiesprofile" method="POST" id="lable" onSubmit={(e) => handleSubmit(e)}>
        <label htmlFor="profile" className='compaines-profilepic'>
          {data.profilePic ? (
          <img src={data.profilePic} alt="Profile" className="compaines-profilepic1" />
          ) : (
          <img src={avatar} alt="Default Avatar" className="compaines-profilepic1" />
          )}
          </label>
          <input onChange={onUpload} type="file" id="profile" name="profile" className='user-profile-pic' />
          <div>
            <label htmlFor="companyName" className="compaines-firstname">Compaine Name: </label>
            <input type="text" name="companyName" id="companyName" value={data.companyName} className="form-control form-compaines-firstname" placeholder='Company Name' onChange={handleOnChange} />
          </div>
          <div>
            <label htmlFor="name" className="compaines-firstname">Full Name: </label>
            <input type="text" name="name" id="name" value={data.name} className="form-control form-compaines-firstname" placeholder='First Name' onChange={handleOnChange} />
          </div>
          <div>
            <label htmlFor="name" className="compaines-firstname">Mobile No: </label>
            <input type="text" name="mobile" id="mobile" value={data.mobile} className="form-control form-compaines-firstname" placeholder='Please enter mobile number' onChange={handleOnChange} />
          </div>
          <div>
            <p className='compaines-profile-since'>Member Since:</p>
            <p className='compaines-profile-since'>Total Payouts</p>
            <p className='compaines-profile-since'>$0.00</p>
          </div>
          <div>
            {loading && <p>Loading...</p>}
            <button type="submit" className="compaines-profile-update">Profile Update</button>
          </div>
          </form>
        </div>
        </li>
        </div>
        </div>
        </ul>
        </div>
       </div>
    </div>
  )
}

export default CompainesDashboard;
