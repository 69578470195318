import React from "react";
import Applyforworks from "./Apply-for-works";

const Work = () => {
    return (
     <div className="dashboardheader2">
      <div className="students-works">
        <Applyforworks />
     </div>
    </div>
    )
}

export default Work;