import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Userprofile = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    currenteducational: "",
    studymajor: "",
    yearofstudy: "",
    gpaacademic: "",
  });
  const [userId, setUserId] = useState("");
  const [showToast, setShowToast] = useState(false);
  const token = localStorage.getItem("token");

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUserProfile = useCallback(async (updatedData) => {
    try {
      const response = await axios.put(`https://api.huebits.in/api/userprofile/${userId}`, updatedData, {
        headers: {
          "content-type": "application/json",
          authorization: `jwt ${localStorage.getItem('token')}`
        },
      });
      if (response.data.success) {
        toast.success(response.data.message, { autoClose: 2000 });
        setShowToast(true);
        setData(response.data.data);
        console.log("Updated user profile data:", response.data.data);
      } else {
        toast.error(response.data.message, { autoClose: 2000 });
        setShowToast(true);
      }
    } catch (error) {
      console.error('Failed to update profile:', error);
      toast.error("Network error: Please check your connection and try again.", { autoClose: 2000 });
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

     // Validate GPA/Academic performance field
     if (!/^\d+(\.\d+)?%$/.test(data.gpaacademic)) {
      toast.error("Invalid GPA! Please enter a valid percentage like '85%'");
      setLoading(false);
      return;
    }

    const updatedData = { ...data };
    await handleUserProfile(updatedData);
  };

  useEffect(() => {
    if (token) {
      setUserId(userId);
      handleUserProfile();
    }
  }, [token, userId, handleUserProfile]);

  return (
    <div>
      {showToast && <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />}
      <h1 className="form-profileh1">Academic Background</h1>
      <div className="students-dashboard-profile">
        <form id="lable" onSubmit={(e) => handleSubmit(e)}>
          <div className="form-group students-dashboard-profile-label">
            <div className="students-dashboard-profile-label">
              <div className="students-dashboard-profile-skills">
                <label htmlFor="currenteducational">Organization / Institution:</label>
                <input type="text" name="currenteducational" id="currenteducational"  className="form-control form-firstname" placeholder='e.g., Harvard University, Stanford University' value={data.currenteducational} onChange={handleOnChange}/>
              </div>
              <div className="students-dashboard-profile-skills">
                <label htmlFor="studymajor">Field of Study:</label>
                <input type="text" name="studymajor" id="studymajor" className="form-control students-profile-label" placeholder='e.g., Computer Science, Business Administration' value={data.studymajor} onChange={handleOnChange}/>
              </div>
              <div className="students-dashboard-profile-skills">
                <label htmlFor="yearofstudy">Year of Study:</label>
                <input type="text" name="yearofstudy" id="yearofstudy" className="form-control students-profile-label" placeholder='e.g., Sophomore, Junior, Senior' value={data.yearofstudy} onChange={handleOnChange} />
              </div>
              <div className="students-dashboard-profile-skills">
                <label htmlFor="gpaacademic">GPA/Academic Performance:</label>
                <input type="text" name="gpaacademic" id="gpaacademic" className="form-control students-profile-label" placeholder='e.g.,85%' value={data.gpaacademic} onChange={handleOnChange} />
              </div>
            </div>
            <div>
              {loading && <p>Loading...</p>}
              <button type="submit" className="user-update">Save Data</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Userprofile;
