import React, {useState} from "react";
import { Link, useNavigate} from "react-router-dom";
import axios from 'axios';
import Headrepage from "../../Homepages/Headerpage/CompainesHeaderpage";
import EyesOpen from "../../../iconsopen/EyesOpen";
import EyesClose from "../../../iconsopen/EyesClose";
import behaves from "../../../assets/pexels copy.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import ReCAPTCHA from "react-google-recaptcha";

function Login() {
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [data, setData] = useState({
        username: "",
        password: "",
        userType: "company",
    });

    const navigate = useNavigate();

    // const onChangeRecaptcha = (token) => {
    //   setRecaptchaToken(token);
    // };

    const handleOnChange = (e) => {
        const { name, value } = e.target;

        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);

        // if (!recaptchaToken) {
        //     toast.error("Please complete the CAPTCHA");
        //     setLoading(false);
        //     return;
        // }

        const toastId = toast.loading("Your login request is being processed...");
        try {
            const response = await axios.post("https://api.huebits.in/api/companiessignin",
                { ...data },
                { 
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const dataResponse = response.data;

            if (dataResponse.error) {
                toast.update(toastId, { render: dataResponse.message, type: "error", isLoading: false, autoClose: 5000 });
            } else {
                toast.update(toastId, { render: dataResponse.message, type: "success", isLoading: false, autoClose: 8000 });
                setTimeout(() => {
                    localStorage.setItem('token', dataResponse.token);
                    navigate("/Homepagespostprojects")
                }, 1500);
            }
        } catch (error) {
            let errorMessage = "An unexpected error occurred. Please try again.";
      
            if (error.response) {
              errorMessage = error.response.data.message || errorMessage;
            } else if (error.request) {
              errorMessage = "Network error: Please check your connection and try again.";
            } else {
              errorMessage = `Request error: ${error.message}`;
            }
      
            toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 5000 });
          } finally {
            setLoading(false);
          }
        };

    return (
        <div className="students-flex">
            <Headrepage />
            <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />
            <div className="studentssign-cssimagesflex-color">
                <div className="studentssign-cssimagesflex">
                    <div className="students-signimage">
                        <div className="students-login-flex" id="students-signimage">
                            <p className="companies-center-login">
                            Log in to your Huebits account
                            </p>
                            <form onSubmit={submitHandler}>
                                <div className="form-group compaines-login-label">
                                    <label htmlFor="email" className="loginlabel">Enter Email</label>
                                    <input type="email" className="login-form-control" id="username" name="username" placeholder="Email address" autoFocus="off" value={data.username} disabled={loading} onChange={handleOnChange} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password" className="loginlabel">Enter Password</label>
                                    <input type={showPassword ? "text" : "password"} className="login-form-control" id="password" name="password" placeholder="Enter your password" autoFocus="off" value={data.password} onChange={handleOnChange} required />
                                    <div className='icons-password-login' onClick={() => setShowPassword(prev => !prev)}>
                                        {showPassword ? <EyesOpen /> : <EyesClose />}
                                    </div>
                                </div>
                                {/* <div className="students-checkboxlogin">
                                <ReCAPTCHA sitekey="6LdcQAkqAAAAAIeIrHfqOtb_Jb9wst0fWQ9tvaGb" onChange={onChangeRecaptcha}/>
                                </div> */}
                                <div className="companies-signin-btn">
                                    <button type="submit" className="students-signin-btn" value="submit" disabled={loading}>{loading ? 'Log in...' : 'Log in'}</button>
                                </div>
                            </form>
                            <p><Link to="/companiesforgotpassword" className="students-frogot-password">Forgot Password</Link></p>
                            <div className="comapnies-accounts">
                                <p>
                                    <span className="students-accounts-p">Don't have an account?{" "} </span>
                                    <Link to="/companiessignup" className="loginhere">
                                        Create an account
                                    </Link>
                                </p>
                            </div>
                        </div>
                        <img className="students-login-image" src={behaves} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
