import React, { useEffect, useState } from "react";
import axios from "axios";
import avatar from '../../../helper/profile.png';

const Photo = () => {
  const [data, setData] = useState({
    profilePic: "",
  });

  const token = localStorage.getItem("token");

  const handleUserProfile = async () => {
    try {
      const response = await axios.post('https://api.huebits.in/api/userprofile', {}, {
        headers: {
          "content-type": "application/json",
          authorization: `jwt ${localStorage.getItem('token')}`,
        },
      });

      setData(response.data.data);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  useEffect(() => {
    if (token) {
      handleUserProfile();
    }
  }, [token]);

  return (
    <div>
      <label htmlFor="profile" className='user-profile-image'>
        {data.profilePic ? (
          <img src={data.profilePic} alt="Profile" className="user-profile-image" />
        ) : (
          <img src={avatar} alt="Default Avatar" className="user-profile-image" />
        )}
      </label>
    </div>
  );
};

export default Photo;
