import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Iotdeveloper from '../../assets/dashboard-images/images/New folder/1.png';
import Industrialiot from '../../assets/dashboard-images/images/New folder/2.png';
import Iotsecurity from '../../assets/dashboard-images/images/New folder/3.png';
import Iotdatascientist from '../../assets/dashboard-images/images/New folder/4.png';
import Iothardware from '../../assets/dashboard-images/images/New folder/5.png';
import Starts from '../../assets/dashboard-images/images/4.png';
import axios from 'axios';
import './learningpath.css';

const LearningPath = () => {
    const [showIotdeveloper, setShowIotdeveloper] = useState(true);
    const [showIndustrial, setShowIndustrial] = useState(true);
    const [showIotsecurity, setShowIotsecurity] = useState(true);
    const [showIotdatascientist, setShowIotdatascientist] = useState(true);
    const [showIothardware, setShowIothardware] = useState(true);
    const [showStarted, setShowStarted] = useState(true);

    useEffect(() => {
        const hasClicked = localStorage.getItem('hasClicked');
        if (hasClicked) {
            setShowStarted(false);
            setShowIndustrial(false);
            setShowIotsecurity(false);
            setShowIotdatascientist(false);
            setShowIothardware(false);
            setShowIotdeveloper(false);
        }
    }, []);

    const handleStartClick = async () => {
        try {
            await axios.post('/api/learningPath/start', {});
            setShowIotdeveloper(false);
            setShowIndustrial(false);
            setShowIotsecurity(false);
            setShowIotdatascientist(false);
            setShowIothardware(false);
            setShowStarted(false);
            localStorage.setItem('hasClicked', true);
        } catch (error) {
            console.error('Error starting learning path:', error);
        }
    };

    return (
        <div>
            <div className="dashboardheader2">
            <div className='standardsflex'>
                <div className="standardflex">
                    <div className="standard1">
                        <div>
                            <img className="dashboard-start-image" src={Iotdeveloper} alt="" />
                        </div>
                        <h1 className="standard">IoT developer / Engineer</h1>
                        {showIotdeveloper && (
                                <Link to="./iot-developer-engineer" style={{ textDecoration: 'none' }}>
                                   <button className="start" onClick={handleStartClick}>
                                    START
                                  </button>
                                </Link>
                            )}
                    </div>
                    <div className="standard1">
                        <div>
                            <img className="dashboard-start-image" src={Iotdatascientist} alt="" />
                        </div>
                        <h1 className="standard">IoT Data Scientist</h1>
                        {showIotdatascientist && (
                            <Link to="./iot-data-scientist" style={{ textDecoration: 'none' }}>
                                <button className="start" onClick={handleStartClick}>
                                    START
                                </button>
                            </Link>
                        )}
                    </div>
                    <div className="standard1">
                        <div>
                            <img className="dashboard-start-image" src={Industrialiot} alt="" />
                        </div>
                        <h1 className="standard">Industrial IoT and Insutry4.0</h1>
                        {showIndustrial && (
                            <Link to="./industrial-iot-and-insutry4.0" style={{ textDecoration: 'none' }}>
                                <button className="start" onClick={handleStartClick}>
                                    START
                                </button>
                            </Link>
                        )}
                    </div>
                </div>
                <div className="standardflex">
                <div className="standard1">
                        <div>
                            <img className="dashboard-start-image" src={Iotsecurity} alt="" />
                        </div>
                        <h1 className="standard">IoT Security Specialist</h1>
                        {showIotsecurity && (
                           // ./iot-security-specialist
                            <Link style={{ textDecoration: 'none' }}>
                                <button className="start" onClick={handleStartClick}>
                                Coming Soon
                                </button>
                            </Link>
                        )}
                    </div>
                    <div className="standard1">
                        <div>
                            <img className="dashboard-start-image" src={Iothardware} alt="" />
                        </div>
                        <h1 className="standard">IoT Hardware Engineer</h1>
                        {showIothardware && (
                            // ./iot-hardware-engineer
                            <Link style={{ textDecoration: 'none' }}>
                                <button className="start" onClick={handleStartClick}>
                                Coming Soon
                                </button>
                            </Link>
                        )}
                    </div>
                <div className="standard1">
                    <div>
                        <img className="dashboard-start-images" src={Starts} alt="" />
                    </div>
                    <h1 className="standard">Customized Learning Path With AI</h1>
                    {showStarted && (
                        //./started
                        <Link style={{ textDecoration: 'none' }}>
                            <button className="start" onClick={handleStartClick}>
                            Coming Soon
                            </button>
                        </Link>
                    )}
                </div>
            </div>
            </div>
            </div>
        </div>
    );
};

export default LearningPath;