import React from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import carousel5 from '../../../assets/1.png';
import carousel6 from "../../../assets/2.png"; 
import carousel7 from "../../../assets/3.png";
import carousel8 from '../../../assets/4.png';
import carousel9 from "../../../assets/5.png"; 
import carousel10 from "../../../assets/6.png";
import carousel11 from "../../../assets/7.png";
import carousel12 from "../../../assets/8.png";
import carousel13 from "../../../assets/9.png";
import "./carousel.css";

const Customersreviews = () => {
  const images = [
    carousel5,
    carousel6,
    carousel7,
    carousel8,
    carousel9,
    carousel10,
    carousel11,
    carousel12,
    carousel13
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true, 
    autoplaySpeed: 2000, 
    prevArrow: <button className="slick-prev"><i className="fas fa-chevron-left"></i></button>,
    nextArrow: <button className="slick-next"><i className="fas fa-chevron-right"></i></button>,
    responsive: [
      {
          breakpoint: 1024, 
          settings: {
              slidesToShow: 2, 
          }
      },
      {
          breakpoint: 768, 
          settings: {
              slidesToShow: 1, 
          }
      }
  ]
  };

  return (
    <div className="companies-creativeproducts">
      <h1 className="creativeproductsh1">Success Stories</h1>
      <p className="creativeproductsh1-spans-2">Creating Proof Of Concepts (POC), Prototypes and Consulting Services For Digital Transformation</p>
       <p className="creativeproductsh1-spans-2">Discover our innovative solutions and products developed for clients</p>
    <div className='carouselflexs'>
     <div className="students-Reviews1">
    <Slider {...settings}>
      {images.map((image, index) => (
        <div key={index} className='carouselflex'>
          <img src={image} alt={`Slide ${index + 4}`} className='carousels' />
        </div>
      ))}
    </Slider>
    </div>
    </div>
    </div>
  );
};

export { Customersreviews };
