import React from "react";
import GaugeChart from 'react-gauge-chart';
import "./hoescore.css";
import ChangingProgressProvider from "./projectsprogeesprojects.jsx";

const VerticalProgressBar = () => {
  const hoeScore = 0;
  const maxScore = 10;
  return (
    <div className="hoescoreprogreses-container">
    <div className="hoescoreprogresscontainer">
      <h1 className="hoescoreprogressheading">Hoe Score Progress</h1>
      <div className="hoescoreprogress">
        <ChangingProgressProvider values={[hoeScore]}>
          {value => (
            <div className="gauge-chart-container">
              <GaugeChart
                id="gauge-chart5"
                percent={(value / 10)}
                nrOfLevels={420}
                arcsLength={[0.1, 0.1, 0.1,0.1]}
                arcPadding={0.01}
                cornerRadius={4}
                arcWidth={0.3} 
                animate={false} 
                colors={["  #fb7403","#fbb003", "#bffb03", "#0BDA51"]}
                formatTextValue={() => `${value}/${maxScore}`} 
                needleColor="gray"
                needleBaseColor="darkgray"
                hideText={true} 
              />
              <div className="needle-base">
                <span>{hoeScore}</span>
                <span>/</span>
                <span>{maxScore}</span>
              </div>
            </div>
          )}
        </ChangingProgressProvider>
      </div>
    </div>
    </div>
  );
};

export default VerticalProgressBar;










