import React from "react";
import Headway from "../../../assets/images/10.png";
import Headway1 from "../../../assets/images/9.png";

const Background = () => {
    return (
        <div>
        <div className="backgroundimages-1"> 
        <div className="backgroundcolorin-images">
            <div className="backgroundcolorin-images-ul">
            <ul>
            <li className="backgroundcolorin-images-li">
            <p className="backgroundcolorin-experience-learn-h1">Business Partnership</p>
            <p className="backgroundcolorin-experience-p">Partner with Huebits to Fast-track</p>
            <p className="backgroundcolorin-experience-p">Talent Engagement and industry Solutions</p>
            </li>
            </ul>
            <div className="headway-images">
            <img className="headway-image" src={Headway} alt=""/>
            <img className="headway-image" src={Headway1} alt=""/>
            </div>
            </div>
        </div>
        </div>
        </div>
    )
}

export default Background;