import React, { useRef } from "react";
import Headerpage from "../Headerpage/Headerpage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Huebits from "./Huebits";
import Reviews from "./Students-Reviews";
import Students from "../../../assets/Students2.png";
import product1 from "../../../assets/Organization.png";
import heroinegenerativeblob from "../../../assets/heroine-generative_blob.png";
import Internet from "../../../assets/cloud-computing_5586209.png";
import Embedde from "../../../assets/images.png";
import DataScience from "../../../assets/Data-Science.png";
import Robotics from "../../../assets/robot_4973328.png";
import DigitalMarketing from "../../../assets/Digital-Marketing.png";
import Empower from "../../../assets/employment.png";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import Footer from "../Footer/Footer";
import { FaTwitter, FaInstagram, FaArrowUp,FaLinkedin, FaYoutube  } from 'react-icons/fa';
import Reveal from '../hook/useScrollposition';
import ScrollCount from "../hook/Scrollopositioncount";
import Type from "./type";
import "./Homepages.css";

const Homepage = ({ isPopupOpen }) => { 
  const footerSectionRef = useRef(null);
  
  const scrollToFooterSection = () => {
    footerSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };


  return (
    <div className={`students-flex companies-flex ${isPopupOpen ? 'popup-open' : ''}`}>
      <Headerpage />
      <div className="homepagecolores">
                <div ref={footerSectionRef} className="homepagecolores-background-flex">  
                  <ul>
                    <li>
                      {/* <h1 className="huebits-homepages-spark-spark">Experience<span className="span">Meets</span> Opportunity</h1> */}
                      {/* <h1 className="huebits-homepages-spark-spark1"><span className="span">Into</span> Opportunity</h1> */}
                      <Type />
                    </li>
                    <div className="homepage-students-companies-buttonget">
                    <Link to="/students">
                    <button className="homepage-students-buttonget-1" id="homepage-students-buttonget-1">Students</button></Link>
                    <Link to="/companies">
                    <button className="homepage-students-buttonget-1">Companies</button></Link>
                    </div>
                  </ul>
                  <div>
                  </div>
                </div>
                <div className="huebits-homepages-students-color">
                <div>
                <Reveal>
                  <h1 className="huebits-homepages1-h1">Unlock Your Potential and Shape Your Future Today!</h1>
                </Reveal>
            <Reveal>
            <div className="huebits-homepages1-students-flex-huebitses">
            <h1 className="huebits-homepages1-h2">
            Students
                <FontAwesomeIcon icon={faUsers} className="students-icon__users-huebits" />
            </h1>
            <div className="huebits-homepages1-students-flex-huebits">
              <div className="huebitsimgdiv">
                <img className="huebitsimage" src={Students} alt="" />
              </div>
              <div className="students-Organizationsul-huebits-flex">
                <div className='students-Organizationsul-huebits-homepages'>
                  <p className="huebits-homepages1-span">Personalized learning paths</p>
                  </div>
                  <div className='students-Organizationsul-huebits-homepages'>
                  <p className="huebits-homepages1-span">Thousands of videos for all skill levels</p>
                  </div>
                  <div className='students-Organizationsul-huebits-homepages'>
                  <p className="huebits-homepages1-span">Real-world projects in every course</p>
                  </div>
                  </div>
                  <div>
                  <hr className="homepage-vertical-line"/>
                  </div>
                  <div className='students-Organizationsul-huebits-homepage-flex'>
                  <div className='students-Organizationsul-huebits-homepage'>
                  <p className="huebits-homepages1-span">Hybrid learning through bootcamps</p>
                  </div>
                  <div className='students-Organizationsul-huebits-homepage'>
                  <p className="huebits-homepages1-span">Get Practical Experience</p>
                  </div>
                    <div className='students-Organizationsul-huebits-homepage'>
                  <p className="huebits-homepages1-span">Mentorship and Support in every phase</p>
                  </div>
                  </div>
                  </div>
                  </div>
                  </Reveal>
                  <div className="homepagehubits-for-students-color">
                  <Reveal>
                  <p className="homepagehubits-for-students-p">Get Job-Ready With Our Trending Engineering Courses, Projects and Experiences In</p>
                  <div className="homepagehubits-for-students-div">
                    <div className="homepage-internet-of-things-images">
                      <div className="homepage-internet-of-things-images-inter">
                      <img className="homepage-internet-of-things"
                        srcSet="https://cloud-1de12d.b-cdn.net/media/iW=68&amp;iH=68&amp;oX=0&amp;oY=0&amp;cW=68&amp;cH=68/5523477f9dd176e073c79d71938ea7c2/iot.png 1x, https://cloud-1de12d.b-cdn.net/media/iW=136&amp;iH=136&amp;oX=0&amp;oY=0&amp;cW=136&amp;cH=136/5523477f9dd176e073c79d71938ea7c2/iot.png 2x"
                        src="https://cloud-1de12d.b-cdn.net/media/iW=54&amp;iH=54&amp;oX=0&amp;oY=0&amp;cW=54&amp;cH=54/5523477f9dd176e073c79d71938ea7c2/iot.png"
                        alt="" draggable="false" loading="lazy" />
                      <div className="homepagehubits-for-students-p">
                      <p className="homepagehubits-for-students-p1">Internet of Things</p>
                      </div>
                      </div>
                      </div>
                      <div className="homepage-internet-of-things-images">
                      <img className="homepage-embedded"
                        srcSet="https://cloud-1de12d.b-cdn.net/media/iW=68&amp;iH=68&amp;oX=0&amp;oY=0&amp;cW=68&amp;cH=68/f830078b71a07fee371ca1a945cbbde4/chip.png 1x, https://cloud-1de12d.b-cdn.net/media/iW=136&amp;iH=136&amp;oX=0&amp;oY=0&amp;cW=136&amp;cH=136/f830078b71a07fee371ca1a945cbbde4/chip.png 2x"
                        src="https://cloud-1de12d.b-cdn.net/media/iW=54&amp;iH=54&amp;oX=0&amp;oY=0&amp;cW=54&amp;cH=54/f830078b71a07fee371ca1a945cbbde4/chip.png"
                        alt="" draggable="false" loading="lazy" />
                      <div className="homepagehubits-for-students-p">
                      <p className="homepagehubits-for-students-p2">Embedded Systems</p>
                      </div>
                      </div>
                      <div className="homepage-internet-of-things-images">
                      <img className="homepage-machine-learning"
                        srcSet="https://cloud-1de12d.b-cdn.net/media/iW=68&amp;iH=68&amp;oX=0&amp;oY=0&amp;cW=68&amp;cH=68/ba362530c23a4e35eff5b6e70334c116/data-science.png 1x, https://cloud-1de12d.b-cdn.net/media/iW=136&amp;iH=136&amp;oX=0&amp;oY=0&amp;cW=136&amp;cH=136/ba362530c23a4e35eff5b6e70334c116/data-science.png 2x"
                        src="https://cloud-1de12d.b-cdn.net/media/iW=54&amp;iH=54&amp;oX=0&amp;oY=0&amp;cW=54&amp;cH=54/ba362530c23a4e35eff5b6e70334c116/data-science.png"
                        alt="" draggable="false" loading="lazy" />
                      <div className="homepagehubits-for-students-p">
                      <p className="homepagehubits-for-students-p3">AI and Machine Learning</p>
                      </div>
                      </div>
                      <div className="homepage-internet-of-things-images">
                      <img className="homepage-cyber-security"
                        srcSet="https://cloud-1de12d.b-cdn.net/media/iW=68&amp;iH=68&amp;oX=0&amp;oY=0&amp;cW=68&amp;cH=68/b59cf78e4e96b421abe3b802a0f61e10/cyber-security.png 1x, https://cloud-1de12d.b-cdn.net/media/iW=136&amp;iH=136&amp;oX=0&amp;oY=0&amp;cW=136&amp;cH=136/b59cf78e4e96b421abe3b802a0f61e10/cyber-security.png 2x"
                        src="https://cloud-1de12d.b-cdn.net/media/iW=54&amp;iH=54&amp;oX=0&amp;oY=0&amp;cW=54&amp;cH=54/b59cf78e4e96b421abe3b802a0f61e10/cyber-security.png"
                        alt="" draggable="false" loading="lazy" />
                      <div className="homepagehubits-for-students-p">
                      <p className="homepagehubits-for-students-p4">Cyber Security</p>
                      </div>
                      </div>
                      <div className="homepage-internet-of-things-images">
                      <img className="homepage-generativeai" src={heroinegenerativeblob} alt="" />
                      <div className="homepagehubits-for-students-p">
                      <p className="homepagehubits-for-students-p5">Generative AI</p>
                      </div>
                      </div>
                      <div className="homepage-internet-of-things-images">
                      <img className="homepage-robotics-and-automation"
                        srcSet="https://cloud-1de12d.b-cdn.net/media/iW=68&amp;iH=68&amp;oX=0&amp;oY=0&amp;cW=68&amp;cH=68/b02e9d8c883b38d776bd961594950f39/robotic-arm.png 1x, https://cloud-1de12d.b-cdn.net/media/iW=136&amp;iH=136&amp;oX=0&amp;oY=0&amp;cW=136&amp;cH=136/b02e9d8c883b38d776bd961594950f39/robotic-arm.png 2x"
                        src="https://cloud-1de12d.b-cdn.net/media/iW=54&amp;iH=54&amp;oX=0&amp;oY=0&amp;cW=54&amp;cH=54/b02e9d8c883b38d776bd961594950f39/robotic-arm.png"
                        alt="" draggable="false" loading="lazy" />
                      <div className="homepagehubits-for-students-p">
                      <p className="homepagehubits-for-students-p6">Robotics and Automation</p>
                     </div>
                     </div>
                     </div>
                  </Reveal>
                  </div>
                  <Reveal>
                    <ScrollCount />
                  </Reveal>
                </div>
                <div>
                <Reveal>
                <Huebits />
                </Reveal>
                </div>
                </div>
                <div className="Organizationsul-huebits-color">
                <Reveal>
                <h1 className="huebits-homepages1-h2">
              Companies <img className="students-icon__users-empower" src={Empower} alt="" /></h1>
            <div className="huebits-homepages1-companies-flex-huebits">
              <div className="students-Organizationsul-huebits-flex">
                <div className='students-Organizationsul-huebits-homepages'>
                  <p className="huebits-homepages1-span">Cutting-edge IoT and data solutions</p>
                  </div>
                  <div className='students-Organizationsul-huebits-homepages'>
                  <p className="huebits-homepages1-span">Industry 4.0 <span>Solutions</span></p>
                  </div>
                  </div>
                  <div>
                  <hr className="homepage-companies-vertical-line" />
                  </div>
                  <div className='students-Organizationsul-huebits-homepage-flex'>
                  <div className='students-Organizationsul-huebits-homepage'>
                  <p className="huebits-homepages1-span">Maintain competitive edge</p>
                  </div>
                  <div className='students-Organizationsul-huebits-homepage'>
                  <p className="huebits-homepages1-span">Access skilled industry talent</p>
                  </div>
                  </div>
                  <div className="huebitsimgdiv">
                <img className="huebitsimage-1-companies-image" src={product1} alt="" />
              </div>
              </div>
              </Reveal>
              <Reveal>
              <div className="homepagehubits-for-companies-color">
                <p className="homepagehubits-for-students-p">Bringing Your Ideas To Life. From Definition To Deployment. We Provide Solutions In</p>
                <div className="homepagehubits-for-students-div">
                <div className="homepage-internet-images-of-things-images">
                    <img className="homepage-internet-of-things" src={Internet} alt="Internet" />
                  <div className="homepagehubits-for-students-p">
                    <p className="homepagehubits-for-students-p1">Internet of Things</p>
                </div>
                </div>
                <div className="homepage-internet-images-of-things-images">
                    <img className="homepage-embedded" src={Embedde} alt="Internet" />
                <div className="homepagehubits-for-students-p">
                    <p className="homepagehubits-for-students-p2">Embedded Systems</p>
                </div>
                </div>
                <div className="homepage-internet-images-of-things-images">
                    <img className="homepage-machine-learning" src={DataScience} alt="Internet" />
                <div className="homepagehubits-for-students-p">
                    <p className="homepagehubits-for-students-p3">AI and Machine Learning</p>
                </div>
                </div>
                <div className="homepage-internet-images-of-things-images">
                    <img className="homepage-cyber-security" src={DigitalMarketing} alt="Internet" />
                <div className="homepagehubits-for-students-p">
                    <p className="homepagehubits-for-students-p4">Cyber Security</p>
                </div>
                </div>
                <div className="homepage-internet-images-of-things-images">
                    <img className="homepage-generativeai" src={heroinegenerativeblob} alt="" />
                <div className="homepagehubits-for-students-p">
                    <p className="homepagehubits-for-students-p5">Generative AI</p>
                </div>
                </div>
                <div className="homepage-internet-images-of-things-images">
                    <img className="homepage-robotics-and-automation" src={Robotics} alt="Internet" />
                <div className="homepagehubits-for-students-p">
                    <p className="homepagehubits-for-students-p6">Robotics and Automation</p>
                </div>
                </div>
                </div>
                </div>
                </Reveal>
                <Reveal>
                <div className="homepagehubits-for-companies-courses-projectses">
                  <div className="homepagehubits-for-companies-courses-projects1">
                  <p className="homepagehubits-for-companies-courses-p1">10X Cost-Effective and 10X Superior Solutions</p>
                  <p className="homepagehubits-for-companies-courses-p2">Pool Of Skilled Individuals With Real-World Project Exposure</p>
                  </div>
                  <div className="homepagehubits-for-companies-courses-projects1">
                  <p className="homepagehubits-for-companies-courses-p3">Accelerating Ideas From Concept To Reality</p>
                  <p className="homepagehubits-for-companies-courses-p4">Connect With Innovators and Early Career Professionals</p>
                </div>
                </div>
                </Reveal>
                <div className="homepagehubits-for-companies-courses-btn">
                <Reveal>
                  <Link to="/companies" >
                  <button className="compaines-buttonget-1 link">Learn More
                    <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
                  </button>
                  </Link>
                </Reveal>
                </div>
              </div>
              <Reveal>
              <div>
                <Reviews />
              </div>
              </Reveal>
              <div className="homepage-footers-images">
              <Footer />
             <footer className="homepage-footers">
              <hr className='homepage-footer-hr'/>
                <div className="footer-social">
                <div className="homepage-footer-copyright">
                  <p className="homepage-footer-copyrightp">© Huebits[2024].All Rights Reserved.</p>
                </div>
                <div className='footer-social-icons'>
                  <Link to="https://www.linkedin.com/company/huebits/mycompany" target="_blank" rel="noopener noreferrer"><FaLinkedin  className="social-icon"/></Link>
                  <Link to="https://x.com/HuebitsTech" target="_blank" rel="noopener noreferrer"><FaTwitter className="social-icon" /></Link>
                  <Link to="https://www.instagram.com/huebits_tech/" target="_blank" rel="noopener noreferrer"><FaInstagram className="social-icon" /></Link>
                  <Link to="https://www.youtube.com/@HuebitsTech" target="_blank" rel="noopener noreferrer"><FaYoutube className="social-icon" /></Link>
                  {/* <Link><FaFacebook className="social-icon" /></Link> */}
                  <Link onClick={scrollToFooterSection}>
                  <FaArrowUp className="homepage-social-icon-link" />
                  </Link>
                </div>
                </div>
              </footer>
              </div>
        </div>
      </div>
  );
}

export default Homepage;
