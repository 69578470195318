import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SmartCity from "../../../assets/New folder/images/7.png";
import LightingSystem from "../../../assets/New folder/images/8.png";
import Reveal from '../../Homepages/hook/useScrollposition';
import "./Studentscaursers.css";

const Caursers = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        prevArrow: <button className="slick-prev"><i className="fas fa-chevron-left"></i></button>,
        nextArrow: <button className="slick-next"><i className="fas fa-chevron-right"></i></button>,
        responsive: [
            {
                breakpoint: 1024, 
                settings: {
                    slidesToShow: 1, 
                }
            },
            {
                breakpoint: 480, 
                settings: {
                    slidesToShow: 1, 
                }
            }
        ]
    };

    return (
        <div className="students-Mentorship-slider-container">
        <Slider {...settings}>
            <div className="students-Mentorship-slide-item">
            <img className="studentsMentorship-image" src={SmartCity} alt="Smart City"/>
              <ul>
                    <li><h1 className="students-Mentorship-slide-item-h1">Mentorship and Support</h1></li>
                    <li><p className="students-Mentorship-slide-item-p">
                      1. Expert mentorship for job seekers and learning challenges
                    </p></li>
                    <li><p className="students-Mentorship-slide-item-p">
                      2. Personalized guidance to overcome barriers
                    </p></li>
                    <li><p className="students-Mentorship-slide-item-p">
                      3. Access to valuable industry professional networks
                    </p></li>
              </ul>
            </div>
            <div className="students-Mentorship-slide-item">
            <img className="studentsMentorship-image" src={LightingSystem} alt="Smart City"/>
              <ul>
                    <li><h1 className="students-Mentorship-slide-item-h1">Tailored Learning Solutions for Skill Advancement</h1></li>
                    <li><p className="students-Mentorship-slide-item-p">
                      1. Tailored learning paths for all skill levels
                    </p></li>
                    <li><p className="students-Mentorship-slide-item-p">
                      2. 100% practical, solution-oriented training
                    </p></li>
                    <li><p className="students-Mentorship-slide-item-p">
                      3. Strategically close skill gaps with precision
                    </p></li>
                </ul>
            </div>
        </Slider>
        </div>
    );
};


const Studentsreviews = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    prevArrow: <button className="slick-prev"><i className="fas fa-chevron-left"></i></button>,
    nextArrow: <button className="slick-next"><i className="fas fa-chevron-right"></i></button>,
    responsive: [
        {
            breakpoint: 1024, 
            settings: {
                slidesToShow: 2, 
            }
        },
        {
            breakpoint: 768, 
            settings: {
                slidesToShow: 2, 
            }
        },
        {
            breakpoint: 480, 
            settings: {
                slidesToShow: 1, 
            }
        }
    ]
  };
    return (
        <Reveal>
        <div className="experience-learners">
          <div className="homepage-reviewsflexs">
        <Slider {...settings}>
            <div className='homepage-slide-items-color'>
             <div className="homepage-slide-item">
                <p  className='homepage-slide-item-p'>KR</p>
                <div className="homepage-card-body">
                <h1 className="homepage-card-title-paths">Kishore Rao</h1>
                    <p className="homepage-card-title-paths-p">
                    Service is really good and I like his explanation. 
                    I have taken IOt course from here and they provide kit also. 
                    There team helped me a lot and this is perfectly for me and I really learned.
                    </p>
                </div>
            </div>
            </div>
            <div className='homepage-slide-items-color'>
            <div className="homepage-slide-item">
                <p className='homepage-slide-item-p'>SB</p>
                <div className="homepage-card-body">
                <h1 className="homepage-card-title-paths">Sai Bhaskar</h1>
                    <p className="homepage-card-title-paths-p">
                    Thanks for educating us for IOT , Especially BAPUJI Sir thanks for teaching us learned 
                    well and good and we want more opportunities from ur Huebits platform like 
                    Free Interships or Jobs with best packages #GVPCDPGC #Rushikonda branch
                    </p>
                </div>
            </div>
            </div>
            <div className='homepage-slide-items-color'>
            <div className="homepage-slide-item">
                <p className='homepage-slide-item-p'>RM</p>
                <div className="homepage-card-body">
                <h1 className="homepage-card-title-paths">Ravikiran Munganda</h1>
                    <p className="homepage-card-title-paths-p">
                    It was amazing, expirenced and the best faculty at Huebits platform,
                    it was my dream to learn projects Huebits platform made it come true.
                    The Very best place to learn IOT (Internet of things), Live projects and many more, thankyou Huebits platform.
                    </p>
                </div>
            </div>
            </div>
            <div className='homepage-slide-items-color'>
            <div className="homepage-slide-item">
                <p  className='homepage-slide-item-p'>SK</p>
                <div className="homepage-card-body">
                <h1 className="homepage-card-title-paths">SaSi Kanth</h1>
                    <p className="homepage-card-title-paths-p">
                    It's a good organisation learnt a lot of knowledge in just 15 days of internship.
                    The Management is there for to distribute there knowledge to everyone.Bapuji sir and Simhadri sir 
                    are great mentors have good knowledge and strive for success of them and everybody's
                    </p>
                </div>
            </div>
            </div>
            <div className='homepage-slide-items-color'>
            <div className="homepage-slide-item">
               <p className='homepage-slide-item-p'>SN</p>
                <div className="homepage-card-body">
                <h1 className="homepage-card-title-paths">Suryarao Nagiredla</h1>
                    <p className="homepage-card-title-paths-p">
                    Best place to learn machine learning course. 
                    Organizers are very friendly and will provide good amount of content. 
                    I recommend this to any beginner who is interested in this topic
                    </p>
                </div>
            </div>
            </div>
            <div className='homepage-slide-items-color'>
            <div className="homepage-slide-item">
                <p  className='homepage-slide-item-p'>DG</p>
                <div className="homepage-card-body">
                <h1 className="homepage-card-title-paths">Divya Gedela</h1>
                    <p className="homepage-card-title-paths-p">
                    It's fortune to have internship at Huebits platform. 
                    They clearly explains the concept you won't understand. 
                    And they care about you like their children. 
                    They intended to teach you more and more things which are useful for your successful future.
                    </p>
                </div>
            </div>
          </div>
        </Slider>
      </div>
  </div>
  </Reveal>
)
}

export {Caursers,Studentsreviews};