import React, { useEffect, useState } from 'react';
import axios from 'axios';

function LearnerComponent() {
    const [learnerData, setLearnerData] = useState(null);

    useEffect(() => {
        const fetchLearnerData = async () => {
            try {
                const response = await axios.get('https://api.huebits.in/api/learner/:learnerId');
                setLearnerData(response.data);
            } catch (error) {
                console.error('Error fetching learner data:', error);
            }
        };

        fetchLearnerData();
    }, []);

    return (
        <div>
            {learnerData ? (
                <div>
                    <h1>Learner Details</h1>
                    <p>Name: {learnerData.name}</p>
                    <p>Email: {learnerData.email}</p>
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
}

export default LearnerComponent;
