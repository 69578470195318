import React, { useState } from "react";
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import EyesOpen from "../iconsopen/EyesOpen";
import EyesClose from "../iconsopen/EyesClose";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AdminSignup() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [data, setData] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    userType: "admin",
    adminSecretKey: ""
  });

  const navigate = useNavigate();

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLoadingNextPage(true);

    const regExspecialChars = /^(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-z])(?!.*\s).{8,}$/;

    if (data.password.length < 8) {
      toast.error("Password should be more than 8 characters");
    } else if (!regExspecialChars.test(data.password)) {
      toast.error("Password should contain at least 1 digit, 1 special character, and 1 lower and upper case letter");
    } else if (data.password !== data.confirmPassword) {
      toast.error("Confirm password does not match");
      setLoading(false);
      setLoadingNextPage(false);
    } else {
      try {
        const response = await axios.post("https://api.huebits.in/api/adminsignup", data, {
          headers: { "Content-Type": "application/json" },
        });

        if (response.data.error) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
          setData({ username: "", password: "", confirmPassword: "", adminSecretKey: "" });
          setTimeout(() => navigate('/adminlogin'), 500);
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message || "An error occurred");
        } else {
          toast.error("Network error: Please check your connection and try again.");
        }
      } finally {
        setLoading(false);
        setLoadingNextPage(false);
      }
    }
  };

  return (
    <div className="admin-dashboard-users-data">
      <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />
          {loadingNextPage && <div className="spinner"></div>}
          <div className="admin-signup-flex">
            <div className="admin-signup-background-color">
              <h1 className="center-signup">Admin Sign Up</h1>
              <form id="signup-form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="email" className="signup-label">Enter Email</label>
                  <input
                    type="email"
                    id="username"
                    name="username"
                    className="signup-form-control"
                    placeholder="Enter email@gmail.com"
                    value={data.username}
                    onChange={handleOnChange}
                    required
                    disabled={loading}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password" className="signup-label">Enter Password</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    className="signup-form-control"
                    placeholder="Enter your password"
                    value={data.password}
                    onChange={handleOnChange}
                    required
                    disabled={loading}
                  />
                  <div className='icons-password' onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <EyesOpen /> : <EyesClose />}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="confirm_password" className="signup-label">Confirm Password</label>
                  <input
                    type="password"
                    id="confirm_password"
                    className="signup-form-control"
                    name="confirmPassword"
                    placeholder="Confirm password"
                    value={data.confirmPassword}
                    onChange={handleOnChange}
                    required
                    disabled={loading}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="admin_secret_key" className="signup-label">Admin Secret Key</label>
                  <input
                    type="text"
                    id="admin_secret_key"
                    name="adminSecretKey"
                    className="signup-form-control"
                    placeholder="Enter admin secret key"
                    value={data.adminSecretKey}
                    onChange={handleOnChange}
                    required
                    disabled={loading}
                  />
                </div>
                <div>
                  <button type="submit" className="students-signup-btn" disabled={loading}>{loading ? 'Sign up...' : 'Sign up'}</button>
                </div>
              </form>
              <div className="students-signup-accounts">
                <p>
                  <span className="signup-already-account">Already have an account? </span>
                  <Link to="/adminlogin" className="signuphere">Login here</Link>
                </p>
              </div>
            </div>
          </div>
    </div>
  );
}

export default AdminSignup;
