import React,{useState} from "react";
import axios from 'axios';
import Project1 from "../../../assets/dashboard-images/images/images/project16.jpg";
import Project2 from "../../../assets/dashboard-images/images/images/project17.jpg";
import Project3  from "../../../assets/dashboard-images/images/images/project18.jpg";
import Project4 from "../../../assets/dashboard-images/images/images/project19.jpg";
import Project5 from "../../../assets/dashboard-images/images/images/projects/24.png";
import Project6 from "../../../assets/dashboard-images/images/images/projects/13.png";
import Project7 from "../../../assets/dashboard-images/images/images/projects/3.png";
import Project8 from "../../../assets/dashboard-images/images/images/projects/25.png";
import Project9 from "../../../assets/dashboard-images/images/images/projects/2.png";
import Project10 from "../../../assets/dashboard-images/images/images/projects/36.png";
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'bootstrap/dist/css/bootstrap.min.css';


const projects = [
  {
    projectId: 1.16,
    projectTitle: "Smart Energy Analytics using IoT and Power BI",
    videoUrl: "https://learn.huebits.in/courses/Smart-Energy-Analytics-using-IoT-and-Power-BI--660e89ac32d94d683a15c142",
    image: Project1,
    completion: 0
  },
  {
    projectId: 1.17,
    projectTitle: "Agriculture Monitoring using IoT and Power BI",
    videoUrl: "https://learn.huebits.in/courses/Agriculture-Monitoring-using-IoT-and-Power-BI--660e8a2b699598329d883b55",
    image: Project2,
    completion: 0
  },
  {
    projectId: 1.18,
    projectTitle: "Real Time Health Monitoring Dashboard using IoT and Google Looker Studio",
    videoUrl: "https://learn.huebits.in/courses/Real-Time-Health-Monitoring-Dashboard-using-IoT-and-Google-Looker-Studio-660e8a7f7104a33722b472c9",
    image: Project3,
    completion: 0
  },
  {
    projectId: 1.19,
    projectTitle: "Smart Air Quality Monitoring using ESP32 and Node-Red",
    videoUrl: "https://learn.huebits.in/courses/Smart-Air-Quality-Monitoring-using-ESP32-660e8b75547d1953124c3fb5",
    image: Project4,
    completion: 0
  },
  {
    projectId: 1.20,
    projectTitle: "Machine Health Monitoring Using Power BI",
    videoUrl: "https://learn.huebits.in/courses/Machine-Health-Monitoring-Using-Power-BI--66e8363468f4bf5bfc36162a",
    image: Project5,
    completion: 0
  },
  {
    projectId: 1.21,
    projectTitle: "Indoor Occupancy Detection Based on Environmental Data using Tableau",
    videoUrl: "https://learn.huebits.in/courses/Indoor-Occupancy-Detection-Based-on-Environmental-Data-using-Tableau-66e836b5268fd80c0d02faf8",
    image:  Project6,
    completion: 0
  },
  {
    projectId: 1.22,
    projectTitle: "Anomaly detection in the temperature of an ac motor using embedded machine learning",
    videoUrl: "https://learn.huebits.in/courses/Anomaly-detection-in-the-temperature-of-an-ac-motor-using-embedded-machine-learning-66e8371c688b0e0b4daf356c", 
    image: Project7,
    completion: 0
  },
  {
    projectId: 1.23,
    projectTitle: "Machine Learning in AWS for IoT-based Oil Pipeline Monitoring System",
    videoUrl: "https://learn.huebits.in/courses/Machine-Learning-in-AWS-for-IoT-based-Oil-Pipeline-Monitoring-System-66e837d5688b0e0b4daf35b5",
    image: Project8,
    completion: 0
  },
  {
    projectId: 1.24,
    projectTitle: "AI Voice Assistant using Raspberry Pi and Gen AI",
    videoUrl: "https://learn.huebits.in/courses/AI-Voice-Assistant-using-Raspberry-Pi-and-Gen-AI-66e8385668f4bf5bfc3616bf", 
    image: Project9,
    completion: 0
  },
  {
    projectId: 1.25,
    projectTitle: "Smart Waste Management using IoT and ML with ESP32 and AWS",
    videoUrl: "https://learn.huebits.in/courses/Smart-Waste-Management-using-IoT-and-ML-with-ESP32-and-AWS-66e838c8b546865c28df1863", 
    image: Project10,
    completion: 0
  }
];

const Start = () => {
    const [, setMessage] = useState(null);
  
    const handleCourseClick = async (projectTitle, projectId, videoUrl) => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('User not authenticated');
        }
        window.open(videoUrl);
        const response = await axios.post(
          `https://api.huebits.in/api/learningpaths/projects/`,
          { projectId, projectTitle },
          {
            headers: {
              "content-type": "application/json",
              "Authorization": `Bearer ${token}`
            },
          }
        );
        setMessage(response.projects.message);
      } catch (error) {
        console.error('Error handling course click:', error);
        setMessage('Error: Unable to save course information.');
      }
    };


  return (
    <div>
      <p className="mylearningpaths-p">Recommended Projects</p>
      <div className="mylearningpath">
        <div className="Wrapper-sc-fvyqwl-1 iZEyIQ AssignmentList__Timeline-sc-1t9paan-2 chIzFH">
          <ul className="Circle-sc-fvyqwl-1">
            {projects.map((project, index) => (
              <li key={project.projectId}>
                <div className="learningpath-courses-dashboard">
                  <div>
                  <img src={project.image} alt={project.projectTitle} className="smart-shoe-development" />
                  </div>
                  <div>
                  <p className={`pythoncourses${project.projectId}`}>
                  {index + 1}. {project.projectTitle}
                  </p>
                  <div className="learningpath-courses-dashboard-progressbar">
                  <p className="learningpath-courses-dashboard-skills-completed">{project.completion}%</p>
                  <ProgressBar now={project.completion} className={`custom-progress-bar`} />
                  </div>
                  <div>
                     <div>
                    {project.completion === 100 ? (
                      <>
                      <button onClick={() => handleCourseClick(project.projectTitle, project.projectId, project.videoUrl)} className="learningpath-courses-dashboard-button-start">Download Certificate</button>
                      </>
                    ) : project.completion >= 10 ? (
                      <button onClick={() => handleCourseClick(project.projectTitle, project.projectId, project.videoUrl)}  className="learningpath-courses-dashboard-button-start">Continue Learning</button>
                    ) : (
                      <button onClick={() => handleCourseClick(project.projectTitle, project.projectId, project.videoUrl)} className="learningpath-courses-dashboard-button-start">Subscribe</button>
                    )}
                  </div>
                  </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
);
};

export default Start;