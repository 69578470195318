import React, { useState } from "react";
import { Link } from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import Huebits from "../../../assets/hue2.png";
import "./Header.css";

const Pages = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleMenu = () => {
    console.log("Toggle menu clicked");
    setMenuActive(!menuActive);
  };

  const closeMenu = () => {
    setMenuActive(false);
  };

  const handleLoginClick = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <div className="hubits-div">
      <Link to="/">
        <img className="homehuebits" src={Huebits} alt="" />
      </Link>
      <div className="hubits1">
        <div className="responsive-menu" onClick={toggleMenu}>
          <MenuOutlinedIcon className="menu-icon" />
        </div>
        <div className="hubitsp-1">
          <ul className={`hubits ${menuActive ? 'active' : ''}`} id="huebits">
            <li className="companies-pages">
              <Link to='/students' className="aboutuses" onClick={closeMenu}>Students</Link>
            </li>
            <li>
              <Link to="/login" className="aboutuses" onClick={handleLoginClick}>
                {loading ? "Loading..." : "Log in"}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Pages;
