import React from "react";
//import axios from 'axios';
import Project1 from "../../../assets/dashboard-images/images/images/projects/14.png";
import Project2 from "../../../assets/dashboard-images/images/images/projects/12.png";
import Project3  from "../../../assets/dashboard-images/images/images/projects/22.png";
import Project4 from "../../../assets/dashboard-images/images/images/projects/28.png";
import Project5 from "../../../assets/dashboard-images/images/images/projects/20.png";
import Project6 from "../../../assets/dashboard-images/images/images/projects/5.png";
import Project7 from "../../../assets/dashboard-images/images/images/projects/23.png";
import Project8 from "../../../assets/dashboard-images/images/images/projects/1.png";
import Project9 from "../../../assets/dashboard-images/images/images/projects/15.png";
import Project10 from "../../../assets/dashboard-images/images/images/projects/37.png";
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'bootstrap/dist/css/bootstrap.min.css';


const projects = [
  {
    projectId: 1,
    projectTitle: "Industrial IoT Gateway using Raspberry Pi",
    videoUrl: "",
    image: Project1,
    completion: 0
  },
  {
    projectId: 2,
    projectTitle: "Fault identification model using IIoT for industrial application",
    videoUrl: "",
    image: Project2,
    completion: 0
  },
  {
    projectId: 3,
    projectTitle: "Low Cost Industrial IoT System for Wireless Monitoring of Electric Motors Condition",
    videoUrl: "",
    image: Project3,
    completion: 0
  },
  {
    projectId: 4,
    projectTitle: "Monitoring of Industrial Electrical Equipment using IoT",
    videoUrl: "",
    image: Project4,
    completion: 0
  },
  {
    projectId: 5,
    projectTitle: "IoT-Based HVAC Monitoring",
    videoUrl: "",
    image: Project5,
    completion: 0
  },
  {
    projectId: 6,
    projectTitle: "Design PLC using Raspberry Pi",
    videoUrl: "",
    image:  Project6,
    completion: 0
  },
  {
    projectId: 7,
    projectTitle: "Low-Cost ESP32, Raspberry Pi, Node-Red, and MQTT Protocol Based SCADA System",
    videoUrl: "", 
    image: Project7,
    completion: 0
  },
  {
    projectId: 8,
    projectTitle: "A Low-Cost IoT-Based System for Manufacturing Process Data Acquisition",
    videoUrl: "",
    image: Project8,
    completion: 0
  },
  {
    projectId: 9,
    projectTitle: "Intelligent Low-Cost Monitoring for Smart Digital Manufacturing",
    videoUrl: "", 
    image: Project9,
    completion: 0
  },
  {
    projectId: 10,
    projectTitle: "Supervisory Control and Data Acquisition Approach PLC in Node-RED",
    videoUrl: "", 
    image: Project10,
    completion: 0
  }
];

const Start = () => {
    //const [, setMessage] = useState(null);
  
    // const handleCourseClick = async (projectTitle, projectId, videoUrl) => {
    //   try {
    //     const token = localStorage.getItem('token');
    //     if (!token) {
    //       throw new Error('User not authenticated');
    //     }
    //     window.open(videoUrl);
    //     const response = await axios.post(
    //       `https://api.huebits.in/api/learningpaths/projects/`,
    //       { projectId, projectTitle },
    //       {
    //         headers: {
    //           "content-type": "application/json",
    //           "Authorization": `Bearer ${token}`
    //         },
    //       }
    //     );
    //     setMessage(response.projects.message);
    //   } catch (error) {
    //     console.error('Error handling course click:', error);
    //     setMessage('Error: Unable to save course information.');
    //   }
    // };


  return (
    <div>
      <p className="mylearningpaths-p">Recommended Projects</p>
      <div className="mylearningpath">
        <div className="Wrapper-sc-fvyqwl-1 iZEyIQ AssignmentList__Timeline-sc-1t9paan-2 chIzFH">
          <ul className="Circle-sc-fvyqwl-1">
            {projects.map((project, index) => (
              <li key={project.projectId}>
                <div className="learningpath-courses-dashboard">
                  <div>
                  <img src={project.image} alt={project.projectTitle} className="smart-shoe-development" />
                  </div>
                  <div>
                  <p className={`pythoncourses${project.projectId}`}>
                  {index + 1}. {project.projectTitle}
                  </p>
                  <div className="learningpath-courses-dashboard-progressbar">
                  <p className="learningpath-courses-dashboard-skills-completed">{project.completion}%</p>
                  <ProgressBar now={project.completion} className={`custom-progress-bar`} />
                  </div>
                  <div>
                     <div>
                    {project.completion === 100 ? (
                      <>
                      <button className="learningpath-courses-dashboard-button-start">Download Certificate</button>
                      </>
                    ) : project.completion >= 10 ? (
                      <button className="learningpath-courses-dashboard-button-start">Continue Learning</button>
                    ) : (
                      <button className="learningpath-courses-dashboard-button-start">Subscribe</button>
                    )}
                  </div>
                  </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
);
};

export default Start;