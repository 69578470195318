import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown,faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Reveal from '../../Homepages/hook/useScrollposition';
import { Link } from "react-router-dom";
import Internet from "../../../assets/images/4.png";
import Embedded from "../../../assets/images/5.png";
import Datasciences from "../../../assets/images/6.png";


const Companieslearningpaths = () => {
    return (
      <div>
      <Reveal>
      <div className="students-learning-patha-cardflexs">
    <div className="companies-learning-patha-card">
   <img className="students-learning-patha-card-image" src={Internet} alt="" />
      <div className="students-learning-patha-card-body">
      <h5 className="students-learning-patha-card-title">Internet of Things</h5>
      <p className="students-learning-patha-card-text"><span className="spansp">We have a unique approach for developing IoT PoC. This helps you get the maximum out of your IoT Product!</span></p>
       <div>
        <p className="btn-Learn-Morep1">
      <Link><button className="students-btn-Learn-More">Coming Soon
      <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
      </button></Link>
      </p>
      </div>
    </div>
    </div>
    <div className="companies-learning-patha-card">
    <img className="students-learning-patha-card-image" src={Embedded} alt="" />
      <div className="students-learning-patha-card-body">
      <h5 className="students-learning-patha-card-title">Embedded Systems</h5>
      <p className="students-learning-patha-card-text"><span className="spansp">We design and build embedded systems products for startups and tech companies.</span></p>
       <div>
        <p className="btn-Learn-Morep2">
      <Link><button className="students-btn-Learn-More">Coming Soon
      <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
      </button></Link>
      </p>
      </div>
    </div>
    </div>
    <div className="companies-learning-patha-card">
      <img className="students-learning-patha-card-image" src={Datasciences} alt="" />
      <div className="students-learning-patha-card-body">
      <h5 className="students-learning-patha-card-title">Data Science- AI/ML/DL</h5>
      <p className="students-learning-patha-card-text"><span className="spansp">Driving enterprise transformation by accelerating analytics initiatives and promoting intelligent insights in near real-time</span></p>
      <p className="btn-Learn-Morep1">
      <Link><button className="students-btn-Learn-More">Coming Soon
      <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
      </button></Link>
      </p>
      </div>
      </div>
      </div>
    </Reveal>
    </div>
    )
  }

const Companieslearning = () => {
  return (
    <div>
      <Reveal>
      <div className="learningpath-ourney1">
        <ul className="learningpath-ourney-navbar-nav">
          <li className="learningpath-ourney-nav-item">
          Talent Connection Hub <FontAwesomeIcon icon={faChevronDown} />
            <ul className="learningpath-ourney-dropdown-menu">
              <li>Connect with innovators and early career professionals to boost awareness about your company.</li>
            </ul>
          </li>
        </ul>
        <ul className="learningpath-ourney-navbar-nav">
          <li className="learningpath-ourney-nav-item">
          Future-Ready Insights <FontAwesomeIcon icon={faChevronDown} />
            <ul className="learningpath-ourney-dropdown-menu">
              <li>Stay ahead with insights from future leaders for shaping your organization's future.</li>
            </ul>
          </li>
        </ul>
        <ul className="learningpath-ourney-navbar-nav">
          <li className="learningpath-ourney-nav-item">
          Skill-Verified Talent Pool <FontAwesomeIcon icon={faChevronDown} />
            <ul className="learningpath-ourney-dropdown-menu">
              <li>Tap into a diverse pool of skilled individuals with real-world project experience.</li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="learningpath-ourneyes">
      <ul className="learningpath-ourney-navbar-nav">
        <li className="learningpath-ourney-nav-item">
        Problem-Solving Partners <FontAwesomeIcon icon={faChevronDown} />
          <ul className="learningpath-ourney-dropdown-menu">
            <li>Access innovative solutions for industry challenges.</li>
          </ul>
        </li>
      </ul>
    </div>
    </Reveal>
    </div>
  );
}


export {Companieslearningpaths,Companieslearning};

