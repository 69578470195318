import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';
import Image1 from "../../../assets/courses-achievements/1.png";
import Image2 from "../../../assets/courses-achievements/2.png";
import Image3 from "../../../assets/courses-achievements/3.png";
import Image4 from "../../../assets/courses-achievements/4.png";
import Image5 from "../../../assets/courses-achievements/5.png";
import Image from "../../../assets/courses-achievements/courses-achievements.png";
import "./courses.css";

const Reveal = ({ children, className }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.1,
    });
  
    useEffect(() => {
      if (inView) {
        controls.start('visible');
      }
    }, [controls, inView]);
  
    const variants = {
      hidden: { opacity: 0, y: 100, scale: 0.9 },
      visible: { 
        opacity: 1, 
        y: 0, 
        scale: 1, 
        transition: { duration: 1, type: 'spring' } 
      },
    };

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={variants}
      className={className}
    >
    <div className='students-courses-achievements'>
        <div className='students-courses-achievements-1'>
        <div className='students-courses-achievements-students1'>
        <div className='students-courses-achievements-students1-h1'>
            <h1>Students</h1>
        </div>
        <div className='students-courses-achievements-students1-p'>
        {inView && (
            <h1><CountUp end={20} duration={20} />K+</h1>
        )}
            <img src={Image1} alt='' className='students-courses-achievements-image1'/>
        </div>
        </div>
        <div className='students-courses-achievements-students2'>
        <div className='students-courses-achievements-students2-h1'>
            <h1>Courses</h1>
        </div>
        <div className='students-courses-achievements-students2-p'>
        {inView && (
            <h1><CountUp end={50} duration={20} />+</h1>
        )}
            <img src={Image2} alt='' className='students-courses-achievements-image1'/>
        </div>
        </div>
        <div className='students-courses-achievements-students3'>
        <div className='students-courses-achievements-students3-h1'>
            <h1>Projects</h1>
        </div>
        <div className='students-courses-achievements-students3-p'>
        {inView && (
            <h1><CountUp end={100} duration={20} />+</h1>
        )}
            <img src={Image3} alt='' className='students-courses-achievements-image1'/>
        </div>
        </div>
        </div>
        <div>
            <img src={Image} alt='' className='students-courses-achievements-image'/>
        </div>
        <div className='students-courses-achievements-2'>
            <div className='students-courses-achievements-students2-1'>
            <div className='students-courses-achievements-students-1-p'>
            {inView && (
                <h1><CountUp end={50} duration={20} />+</h1>
            )}
                <img src={Image4} alt='' className='students-courses-achievements-image1'/>
            </div>
            <div className='students-courses-achievements-students-1-h1'>
                <h1>Companies</h1>
            </div>
            </div>
            <div className='students-courses-achievements-students2-2'>
            <div className='students-courses-achievements-students-2-p'>
            {inView && (
                <h1><CountUp end={5} duration={20} />+</h1>
            )}
                <img src={Image5} alt='' className='students-courses-achievements-image1'/>
            </div>
            <div className='students-courses-achievements-students-2-h1'>
                <h1>Domains</h1>
            </div>
            </div>
        </div>
   </div>
   {children}
    </motion.div>
  )
}

export default Reveal;
