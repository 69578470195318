import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Students from "./Students";
import Companies from "./Companies";
import "./Admindashboard.css";

const AdminDashboard = () => {
  const [activeLink, setActiveLink] = useState('students');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/');
      return;
    }
  }, [navigate]);

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  return (
    <div className="admin-dashboard-users-data">
      <div className="admin-dashboard-users-logout">
      <h1 className="admin-dashboard-users-data-h1">Admin Dashboard</h1>
      <button onClick={handleLogout} className="admin-dashboard-logout-button">
        Log Out
        </button>
      </div>
      <div className="admin-dashboard-users-datas">
        <div className="admindashboard-link" onClick={() => handleLinkClick('students')}>
          <Link to="#" className={activeLink === 'students' ? 'active-students-link' : ''}>
            <p className="admindashboard">
              Students Data
            </p>
          </Link>
        </div>
        <div className="admindashboard-link" onClick={() => handleLinkClick('companies')}>
          <Link to="#" className={activeLink === 'companies' ? 'active-companies-link' : ''}>
            <p className="admindashboard">
              Companies Data
            </p>
          </Link>
        </div>
        </div>
        <div className="admindashboardlinks">
          {activeLink === 'students' && <div className="admindashboardlink active"><Students /></div>}
          {activeLink === 'companies' && <div className="admindashboardlink active"><Companies /></div>}
        </div>
      </div>
  );
};

export default AdminDashboard;