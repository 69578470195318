import React, {useState} from "react";
import axios from 'axios';
import SmartShoeImage from "../../assets/dashboard-images/images/images/projects/16.png";
import Amazon from "../../assets/dashboard-images/images/images/15.jpg";
import Microsoft  from "../../assets/dashboard-images/images/images/7.png";
import Internet from "../../assets/dashboard-images/images/images/4.png";
import SmartHome from "../../assets/dashboard-images/images/images/3.png";
import ESP32Arduino from "../../assets/dashboard-images/images/images/2.png";
import NodeMCUMATLAB from "../../assets/dashboard-images/images/images/17.jpg";
import NternetESP32Arduino from "../../assets/dashboard-images/images/images/16.jpg";
import NodeRed from "../../assets/dashboard-images/images/images/18.jpg";
import BluetoothTechnology from "../../assets/dashboard-images/images/images/19.jpg";
import JavaScript from "../../assets/dashboard-images/images/images/13.png";
import SystemsProject from "../../assets/dashboard-images/images/images/projects/30.png";
import SmartusingESP32 from "../../assets/dashboard-images/images/images/projects/27.png";
import Industry from "../../assets/dashboard-images/images/images/projects/35.png";
import IndustrialInternet from "../../assets/dashboard-images/images/images/10.png";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Industrialiot from "../learningpath/projects/Industrial-IoT-projects";
import 'bootstrap/dist/css/bootstrap.min.css';

import './start.css';

const courses = [
  {
    courseId: 0.1,
    courseTitle: "Introduction to Internet of Things",
    videoUrl: "https://learn.huebits.in/courses/Introduction-to-Internet-of-Things--66e8028064721c6daa143ae8",
    image: SmartShoeImage,
    completion: 0
  },
  {
    courseId: 0.2,
    courseTitle: "Python Programming",
    videoUrl: "https://learn.huebits.in/courses/Python-Programming-660e8bc9699598329d883cf6",
    image: Amazon,
    completion: 0
  },
  {
    courseId: 0.3,
    courseTitle: "IoT and Embedded Systems with ESP32",
    videoUrl: "https://learn.huebits.in/courses/Internet-of-Things-using-ESP32-and-Arduino-660e7e601e0fac451502fb0c",
    image: Microsoft,
    completion: 0
  },
  {
    courseId: 0.4,
    courseTitle: "IoT using Raspberry Pi and Python",
    videoUrl: "https://learn.huebits.in/courses/Internet-of-Things-using-Raspberry-Pi-and-Python-66044b9cdf77f21d16e2c40c",
    image: Internet,
    completion: 0
  },
  {
    courseId: 0.5,
    courseTitle: "Azure IoT Certification Training",
    videoUrl: "https://learn.huebits.in/courses/Microsoft-Azure-IoT-Certification--660ea20144dad02ad4c6bfcb",
    image: SmartHome,
    completion: 0
  },
  {
    courseId: 0.6,
    courseTitle: "AWS IoT Certification Training",
    videoUrl: "https://learn.huebits.in/courses/Amazon-Web-Services-AWS-IoT-Certification-660ea25494fd573437e5c251",
    image:  ESP32Arduino,
    completion: 0
  },
  {
    courseId: 0.14,
    courseTitle: "IoT Data analysis using python",
    videoUrl: "https://learn.huebits.in/courses/IoT-Data-Analysis-using-Python-660eb969cf0168138b68bec0", 
    image: NodeMCUMATLAB,
    completion: 0
  },
  {
    courseId: 0.10,
    courseTitle: "Master MQTT",
    videoUrl: "https://learn.huebits.in/courses/Mastering-MQTT-660e8acf44dad02ad4c6b89c",
    image: NternetESP32Arduino,
    completion: 0
  },
  {
    courseId: 0.16,
    courseTitle: "Machine Learning",
    videoUrl: "https://learn.huebits.in/courses/Machine-Learning-using-Python-66752d77e501170a8d812ec9", 
    image: NodeRed,
    completion: 0
  },
  {
    courseId: 0.22,
    courseTitle: "Node-Red Course",
    videoUrl: "https://learn.huebits.in/courses/Master-Node-Red-from-Basic-to-Advanced-660e805c06697760666c0abf", 
    image: BluetoothTechnology,
    completion: 0
  },
  {
    courseId: 0.23,
    courseTitle: "Industrial Internet of Things",
    videoUrl: "https://learn.huebits.in/courses/Industrial-Internet-of-Things-660e8686337f1467aec01fad", 
    image: JavaScript,
    completion: 0
  },
  {
    courseId: 0.24,
    courseTitle: "PLC, SCADA Systems and IoT",
    videoUrl: "https://learn.huebits.in/courses/PLC-SCADA-Systems-andIoT-66e817a16e1bbb1191401a75", 
    image: SystemsProject,
    completion: 0
  },
  {
    courseId: 0.25,
    courseTitle: "MING technologies",
    videoUrl: "https://learn.huebits.in/courses/MING-Technologies--MQTT-InfluxDB-Node-Red-Grafana-66e8190e268fd80c0d02f1ec", 
    image: SmartusingESP32,
    completion: 0
  },
  {
    courseId: 0.26,
    courseTitle: "Smart manufacturing and Industry4.0",
    videoUrl: "https://learn.huebits.in/courses/Smart-manufacturing-and-Industry40-66e819953939d23d140bf840",
    image: Industry,
    completion: 0
  },
  {
    courseId: 0.11,
    courseTitle: "Full stack IoT using JavaScript, MERN stack and Raspberry Pi",
    videoUrl: "https://learn.huebits.in/courses/Full-Stack-IoT-using-Raspberry-Pi-and-JavaScript-660e7f660229d83cda0e6da7",
    image: IndustrialInternet,
    completion: 0
  }
];

const Start = () => {
    const [, setMessage] = useState(null);
    
    const handleCourseClick = async (courseTitle, courseId, videoUrl) => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('User not authenticated');
        }
        window.open(videoUrl);
        const response = await axios.post(
          `https://api.huebits.in/api/learningpaths/courses/`,
          { courseId, courseTitle },
          {
            headers: {
              "content-type": "application/json",
              "Authorization": `Bearer ${token}`
            },
          }
        );
        setMessage(response.courses.message);
      } catch (error) {
        console.error('Error handling course click:', error);
        setMessage('Error: Unable to save course information.');
      }
    };


  return (
    <div>
    <div className="mylearningpaths">
      <h1 className="mylearningpaths-h1">Industrial IoT and Insutry4.0</h1>
      <p className="mylearningpaths-p">Courses</p>
      <div className="mylearningpath">
        <div className="Wrapper-sc-fvyqwl-1 iZEyIQ AssignmentList__Timeline-sc-1t9paan-2 chIzFH">
          <ul className="Circle-sc-fvyqwl-1">
            {courses.map((course, index) => (
              <li key={course.courseId}>
                <div className="learningpath-courses-dashboard">
                  <div>
                  <img src={course.image} alt={course.courseTitle} className="smart-shoe-development" />
                  </div>
                  <div>
                  <p className={`pythoncourses${course.courseId}`}>
                  {index + 1}. {course.courseTitle}
                  </p>
                  <div className="learningpath-courses-dashboard-progressbar">
                  <p className="learningpath-courses-dashboard-skills-completed">{course.completion}%</p>
                  <ProgressBar now={course.completion} className={`custom-progress-bar`} />
                  </div>
                  <div>
                     <div>
                    {course.completion === 100 ? (
                      <>
                      <button onClick={() => handleCourseClick(course.courseTitle, course.courseId, course.videoUrl)} className="learningpath-courses-dashboard-button-start">Download Certificate</button>
                      </>
                    ) : course.completion >= 10 ? (
                      <button onClick={() => handleCourseClick(course.courseTitle, course.courseId, course.videoUrl)} className="learningpath-courses-dashboard-button-start">Continue Learning</button>
                    ) : (
                      <button onClick={() => handleCourseClick(course.courseTitle, course.courseId, course.videoUrl)} className="learningpath-courses-dashboard-button-start">Subscribe</button>
                    )}
                  </div>
                  </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Industrialiot />
    </div>
  </div>
);
};

export default Start;