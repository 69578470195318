import React, { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Huebits from "../../assets/dashboard-images/images/hue2 copy.png";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import CloseIcon from "@mui/icons-material/Close";
import axios from 'axios';
import './sidebar.css';

const Item = ({ title, to, icon, selected, setSelected, handleLogout }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    setSelected(title);
    if (title === "LogOut") {
      handleLogout();
    } else {
      navigate(to);
    }
  };

  const isActive = selected === title;
  const isLogout = title === "LogOut";

  return (
    <MenuItem
      className={`custom-menu-item ${isActive ? "titleactive selected" : ""} ${isLogout ? "logout-item" : ""}`}
      active={isActive}
      style={{
        backgroundColor: isActive ? "#052659" : "transparent",
      }}
      onClick={handleClick}
      icon={React.cloneElement(icon, { className: isActive ? "icon-margin titleactive" : "icon-margin" })}
    >
      <Typography>
        <Link to={to} className={`titledashboards ${isActive ? "selected" : ""}`}>
          {title}
        </Link>
      </Typography>
    </MenuItem>
  );
};

const SidebarComponent = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const navigate = useNavigate();

  // Capture token from URL if redirected from Google authentication
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      localStorage.setItem('token', token);
      navigate('/dashboard');
    }
  }, [navigate]);

  // Check if token exists in localStorage, if not redirect to login
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
        navigate("/");
    }
  }, [navigate]);

  const handleLogout = async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    try {
        const response = await axios.get('https://api.huebits.in/api/logout');
        if (response.data.success) {
            console.log('Logged out successfully');
            localStorage.clear();
            navigate("/");
        } else {
          console.error('Logout failed:', response.data.message);
      }
    } catch (error) {
        console.error('Error logging out:', error);
    }
  };

  return (
    <div className="sidebardashboard">
      <Sidebar collapsed={isCollapsed} className="sidebar">
        <Menu className="sidebar">
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="menu-item"
            icon={isCollapsed ? <CloseIcon /> : <MenuOutlinedIcon />}
          > 
            <img className="sidebarhuebits" src={Huebits} alt="" />
          </MenuItem>
          <Box paddingLeft={isCollapsed ? undefined : "0px"}>
            <Item
              className="dashboard-sidebarmenu"
              title="Dashboard"
              to="/dashboard"
              icon={<DashboardIcon className="icon-margin" />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Learning Path"
              to="./learningpath"
              icon={<SchoolOutlinedIcon className="icon-margin" />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Courses"
              to="./courses"
              icon={<LibraryBooksIcon className="icon-margin" />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Projects"
              to="./projects"
              icon={<AssignmentTurnedInIcon className="icon-margin" />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Capstone AI Magic"
              to="./capstoneaimagic"
              icon={<MenuOutlinedIcon className="icon-margin" />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Live Sessions"
              to="./livesessions"
              icon={<CalendarTodayOutlinedIcon className="icon-margin" />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Apply For Work"
              to="./work"
              icon={<WorkOutlineOutlinedIcon className="icon-margin" />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="LogOut"
              to="/"
              icon={<ExitToAppOutlinedIcon className="icon-margin" />}
              selected={selected}
              setSelected={setSelected}
              handleLogout={handleLogout}
            />
          </Box>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default SidebarComponent;
