import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Headrepage from "../../Homepages/Headerpage/CompainesHeaderpage";
import { ToastContainer, toast } from 'react-toastify';

const Postproject6 = () => {
  const navigate = useNavigate();
  const [completionPercentage, setCompletionPercentage] = useState(80);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData(event.target);

    const toastId = toast.loading("Submitting your project...");

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('User not authenticated');
      }

      const response = await axios.post(
        "https://api.huebits.in/api/postproject6",
        formData,
        {
          headers: {
            "Authorization": `Bearer ${token}`
          },
        }
      );

      if (response.data.status === "Success") {
        console.log("Postproject submitted successfully");
        console.log("Updated User Data:", response.data.data);
        toast.update(toastId, { render: "Project submitted successfully!", type: "success", isLoading: false, autoClose: 3000 });
        setCompletionPercentage(prevPercentage => prevPercentage + 19);
        setTimeout(() => {
          navigate("/projectssubmit");
        }, 1000);
      } else {
        console.error("Postproject submission failed");
        toast.update(toastId, { render: "Submission failed. Please try again.", type: "error", isLoading: false, autoClose: 3000 });
      }
    } catch (error) {
      console.error("An error occurred during Postproject submission", error);
      console.error("Error response:", error.response);
      const errorMessage = error.response?.data?.message || "Network error: Please check your connection and try again.";
      toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 5000 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="students-flex companies-flex">
      <Headrepage />
      <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />
      <div className="homepagecolores">
        <div className="postprojects1-css">
          <div className="postprojects-css-1">
            <form action="/postproject6" method="POST" id="postForm" onSubmit={handleSubmit}>
              <div className="postprojects-css-2">
                <div>
                  <h1 className="postprojects1-container">Upload File</h1>
                  <p className="yourprojects">Upload File related to project</p>
                  <p className="yourproject">Types Allowed: .xlsx, .xls, .doc, .docx, .ppt, .pptx, and .pdf.</p>
                  <input
                    type="file"
                    id="fileInput"
                    name="fileInput"
                    className="postprojects-css-input-files"
                    accept=".doc, .pdf"
                    placeholder="File Types Allowed: .xlsx, .xls, .doc, .docx, .ppt, .pptx, and .pdf"
                    autoFocus
                    required />
                  <h4 className="yourproject-1">Upload Attachments (Max 20Mb)</h4>
                </div>
                <div className="form-group">
                  <div className="postprojects1-css-button">
                  </div>
                </div>
                <div className="form-group">
                  <div className="postprojects1-css-button">
                    <Link to="/postproject5">
                      <button className="buttonget postprojects1-button">
                        Back
                        <FontAwesomeIcon icon={faArrowLeft} className="faArrowRight" />
                      </button>
                    </Link>
                    <button type="submit" className="postprojects1-button-4" disabled={loading}>
                      {loading ? "Submitting..." : "Submit"}
                      <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <br />
          <div className="postprojects1-rangs-steps-1">
            <p>Step 7 of 7</p>
            <div className="completion-percentage">{completionPercentage}%</div>
            <meter className="postprojects1-range-meter" type="range" name="Range" min="0" max="100" value={completionPercentage} required></meter>
          </div>
          <hr className="postprojects1-hr" />
          <div className="postprojects1-rangs-steps-2">
            <p><span>@2023 Pangaea x All Rights Reserved</span></p>
            <p className="p">Terms of Business</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Postproject6;
