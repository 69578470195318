import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';
import IoT from "../../../assets/New folder/12.png";
import creativity from "../../../assets/New folder/11.png";
import connection from "../../../assets/New folder/13.png";

const Reveal = ({ children, className }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const variants = {
    hidden: { opacity: 0, y: 100, scale: 0.9 },
    visible: { 
      opacity: 1, 
      y: 0, 
      scale: 1, 
      transition: { duration: 1, type: 'spring' } 
    },
  };

  return (
      <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={variants}
      className={className}
    >
      <div className="IoTUniverse-internet-cardflexs">
        <div className="IoTUniverse-card1">
          <img className="IoTUniverse-iote" src={IoT} alt="Internet of Things" />
          <div className="IoTUniverse-card-body">
            {inView && (
              <h1 className="IoTUniverse-card-titles">
                With <CountUp end={40} duration={5} />+ courses covering every aspect of IoT.
              </h1>
            )}
          </div>
        </div>

        <div className="IoTUniverse-card2">
          <img className="IoTUniverse-iote-projects" src={creativity} alt="IoT Creativity" />
          <div className="IoTUniverse-card-body">
          {inView && (
            <h1 className="IoTUniverse-card-titles">
              <CountUp end={100} duration={5} />+ hands-on projects to spark your IoT creativity
            </h1>
          )}
          </div>
        </div>

        <div className="IoTUniverse-card3">
          <img className="IoTUniverse-iote" src={connection} alt="Industry Connections" />
          <div className="IoTUniverse-card-body">
          {inView && (
            <h1 className="IoTUniverse-card-title">
              Explore <CountUp end={1000} duration={5} />+ IoT internship opportunities for industry connections.
            </h1>
          )}
          </div>
        </div>
      </div>
      {children}
    </motion.div>
  );
};

export default Reveal;
