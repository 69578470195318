import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Headrepage from "../../Homepages/Headerpage/CompainesHeaderpage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./companiesppopup.css";

const Modal = () => {
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState("");
    const [loading, setLoading] = useState(false);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!selectedOption) {
            toast.error("Please select an option before proceeding.");
            return;
        }

        const Projects = {
            projectselectname: selectedOption === "rapid-poc" ? "Rapid POC & Product Development" : "Expert Research Consultancy",
        };

        console.log("Submitting Projects:", Projects);

        const toastId = toast.loading("Submitting your data...");

        try {
            setLoading(true);

            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('User not authenticated');
            }

            const response = await axios.post('https://api.huebits.in/api/postprojecthomepage', { Projects }, {
                headers: {
                    "content-type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.status === 'Success') {
                toast.update(toastId, { render: "option submitted successfully!", type: "success", isLoading: false, autoClose: 3000 });
                setTimeout(() => {
                    navigate("/postproject1");
                }, 1000); 
            } else {
                console.error('option submission failed');
                toast.update(toastId, { render: "Submission failed. Please try again.", type: "error", isLoading: false, autoClose: 3000 });
            }
        } catch (error) {
            console.error("An error occurred during Postproject submission", error);
            console.error("Error response:", error.response);
            const errorMessage = error.response?.data?.message || "Network error: Please check your connection and try again.";
            toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 5000 });
          } finally {
            setLoading(false);
          }
        };

    return (
        <div className="students-flex companies-flex">
            <Headrepage />
            <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />
            <div className="homepagecolores">
               <div className="modal-containers">
               <div className="modal-container">
                <h2 className="modal-container-h2">Welcome!</h2>
                <p className="modal-container-p">What would you like to do next?</p>
                <form method="POST" id="postDataForm" onSubmit={handleSubmit}>
                    <div className="modal-containers-options">
                        <label>
                            <input 
                                type="radio" 
                                name="projectsOption" 
                                value="rapid-poc" 
                                checked={selectedOption === "rapid-poc"}
                                onChange={handleOptionChange}
                            />
                            Rapid POC & Product Development
                        </label>
                        <label>
                            <input 
                                type="radio" 
                                name="projectsOption" 
                                value="expert-research" 
                                checked={selectedOption === "expert-research"}
                                onChange={handleOptionChange}
                            />
                            Expert Research Consultancy
                        </label>
                    </div>
                    <div className="modal-actions">
                        <button 
                            className="action-button popup-projects" 
                            type="submit"
                            disabled={loading} 
                        >
                            {loading ? "Submitting..." : "Post A Project"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
     </div>
    </div>
    );
};

export default Modal;