// import React from 'react';
// import Typical from 'react-typical';

// const Type = () => {
//   return (
//     <div>
//       <h1 className="huebits-homepages-spark-spark">
//         <span className="white-text">
//           <Typical
//             steps={[
//               'Experience '
//             ]}
//             wrapper="span"
//             loop={1}
//             className="no-cursor"
//           />
//         </span>
//         <span className="blue-text">
//           <Typical
//             steps={[
//               'Meets '
//             ]}
//             wrapper="span"
//             loop={1}
//             className="no-cursor"
//           />
//         </span>
//         <span className="white-text">
//           <Typical
//             steps={[
//               'Opportunity'
//             ]}
//             wrapper="span"
//             loop={1}
//             className="no-cursor"
//           />
//         </span>
//       </h1>
//     </div>
//   );
// };

// export default Type;











// import React from 'react';
// import Typical from 'react-typical';

// const Type = () => {
//   return (
//     <div>
//       <h1 className="huebits-homepages-spark-spark">
//         <Typical
//           steps={[
//             'Experience Meets Opportunity'
//           ]}
//           wrapper="span"
//           loop={1}
//           className="no-cursor"
//         />
//       </h1>
//     </div>
//   );
// };

// export default Type;


import React, { useState, useEffect } from 'react';
import ReactTypingEffect from 'react-typing-effect';

const ReactTypingEffectDemo = () => {
  const [startTyping, setStartTyping] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setStartTyping(true);
    }, 1000); 

    return () => clearTimeout(timer); 
  }, []);

  return (
    <div className="huebits-homepages-spark-spark">
      {startTyping ? (
        <ReactTypingEffect
          text={["Experience Meets Opportunity"]}
          speed={50}  
          eraseSpeed={30}  
          cursor={" "}  
          displayTextRenderer={(text, i) => {
            return (
              <h1 className='huebits-homepages-spark-spark1'>
                {text.split(' ').map((word, i) => {
                  const key = `${i}`;
                  return (
                    <span
                      key={key}
                      style={
                        word === "Meets"
                          ? { color: 'rgb(226, 225, 225)', fontWeight: 200 } 
                          : { fontWeight: 900, color: 'white' }
                      }
                    >
                      {word}{' '}
                    </span>
                  );
                })}
              </h1>
            );
          }}
        />
      ) : (
        <h1 className='huebits-homepages-spark-spark1'>Experience <span style={{fontWeight: 200}}>Meets</span> Opportunity</h1>
      )}
    </div>
  );
};

export default ReactTypingEffectDemo;

