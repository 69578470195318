import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const AdminDashboard = () => {
  const [companiesUsers, setCompaniesUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    const fetchUsers = async () => {
      try {
        const companiesUserResponse = await axios.get('https://api.huebits.in/api/admin/companies-users', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setCompaniesUsers(companiesUserResponse.data.users);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [navigate]);

  const handleDownload = async (path) => {
    try {
      const response = await axios({
        url: `https://api.huebits.in/uploads/${path}`,
        method: 'GET',
        responseType: 'blob', 
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', path);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <div className='admin-students-dashboard'>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <h2 className='admin-students-dashboard-p'>Companies Data</h2>
          <table className='admin-students-dashboard-table'>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Email_Id</th>
                <th>Name</th>
                <th>Mobile_No</th>
                <th>Company_Name</th>
                <th>Projects</th>
              </tr>
            </thead>
            <tbody>
              {companiesUsers.map((user, index) => (
                <tr key={index}>
                  <td className="index-number">{index + 1}</td>
                  <td>{user.username}</td>
                  <td>{user.name}</td>
                  <td>{user.mobile}</td>
                  <td>{user.companyName}</td>
                  <td>
                    <ul>
                      {user.postprojects && user.postprojects.length > 0 ? (
                        user.postprojects.map((postproject, idx) => (
                          <li key={idx}>
                            {postproject.projectselectname && <p className='admin-dashboard-projects-p admin-dashboard-project-type'><span className='admin-dashboard-projects-span'>Project_Type: </span>{postproject.projectselectname}</p>}
                            {postproject.title1 && <p className='admin-dashboard-projects-p'><span className='admin-dashboard-projects-span'>Project_Title: </span>{postproject.title1}</p>}
                            {postproject.textarea && <p className='admin-dashboard-projects-p'><span className='admin-dashboard-projects-span'>Project_Description: </span>{postproject.textarea}</p>}
                            {postproject.skills && <p className='admin-dashboard-projects-p'><span className='admin-dashboard-projects-span'>Technical_Skills: </span>{postproject.skills}</p>}
                            {postproject.skills1 && <p className='admin-dashboard-projects-p'><span className='admin-dashboard-projects-span'>Key_Skills: </span>{postproject.skills1}</p>}
                            {postproject.skills3 && <p className='admin-dashboard-projects-p'><span className='admin-dashboard-projects-span'>Skills_&_Technologies: </span>{postproject.skills3}</p>}
                            {postproject.type && <p className='admin-dashboard-projects-p'><span className='admin-dashboard-projects-span'>Project_No.of_days: </span>{postproject.type}</p>}
                            {postproject.Type && <p className='admin-dashboard-projects-p'><span className='admin-dashboard-projects-span'>Project_Budget: </span>{postproject.Type}</p>}
                            {postproject.originalname && (
                              <div>
                                <p><span className='admin-dashboard-projects-span'>Project_Pdf: </span>{postproject.originalname}</p>
                                <button onClick={() => handleDownload(postproject.path)} className='admin-dashboard-projects-download-button'>
                                 Download Project PDF <FontAwesomeIcon icon={faDownload} />
                                </button>
                              </div>
                            )}
                          </li>
                        ))
                      ) : (
                        <p>No projects available</p>
                      )}
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default AdminDashboard;
