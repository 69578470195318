import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Headrepage from "../../Homepages/Headerpage/CompainesHeaderpage";
import { ToastContainer, toast } from 'react-toastify';

const Postproject4 = () => {
  const navigate = useNavigate();
  const [selectedSkills2Array, setSelectedSkills2Array] = useState([]);
  const [completionPercentage, setCompletionPercentage] = useState(30);
  const [loading, setLoading] = useState(false);
  const postDataSelectRef = useRef(null);

  const handlePostDataChange = useCallback(() => {
    const selectedSkill = postDataSelectRef.current.value.trim();
    if (selectedSkill) {
      if (selectedSkills2Array.length < 9) {
        if (!selectedSkills2Array.includes(selectedSkill)) {
          const newSkillsArray = [...selectedSkills2Array, selectedSkill];
          setSelectedSkills2Array(newSkillsArray);
          postDataSelectRef.current.value = ""; // Reset the dropdown
        }
      } else {
        toast.error("You can select a maximum of 9 skills.");
      }
    }
  }, [selectedSkills2Array]);

  useEffect(() => {
    const postDataSelect = postDataSelectRef.current;
    if (postDataSelect) {
      postDataSelect.addEventListener("change", handlePostDataChange);
      return () => {
        postDataSelect.removeEventListener("change", handlePostDataChange);
      };
    }
  }, [handlePostDataChange]);

  const handleSkillDelete = (skillToDelete) => {
    const updatedSkills = selectedSkills2Array.filter(skill => skill !== skillToDelete);
    setSelectedSkills2Array(updatedSkills);
  };

  const validateSkills = () => {
    if (selectedSkills2Array.length === 0) {
      toast.error("Please select at least one skill.");
      return false;
    }
    if (selectedSkills2Array.length > 9) {
      toast.error("You can select a maximum of 9 skills.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateSkills()) {
      return;
    }

    setLoading(true);

    const Projects = {
      skills3: selectedSkills2Array.join(", ")
    };

    const toastId = toast.loading("Submitting your data...");

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('User not authenticated');
      }
      const response = await axios.post(
        "https://api.huebits.in/api/postproject4", { Projects },
        {
          headers: {
            "content-type": "application/json",
            "Authorization": `Bearer ${token}`
          },
        });

      if (response.data.status === "Success") {
        console.log("Data submitted successfully");
        console.log("Updated User Data:", response.data.data);
        toast.update(toastId, { render: "Data submitted successfully!", type: "success", isLoading: false, autoClose: 3000 });
        setCompletionPercentage(prevPercentage => prevPercentage + 10);
        setTimeout(() => {
          toast.error(null);
          navigate("/postproject5");
        }, 1500);
      } else {
        console.error("Data submission failed");
        toast.update(toastId, { render: "Submission failed. Please try again.", type: "error", isLoading: false, autoClose: 3000 });
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Network error: Please check your connection and try again.";
      toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 5000 });
  } finally {
      setLoading(false);
  }
};

  return (
    <div className="students-flex companies-flex">
      <Headrepage />
      <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />
      <div className="homepagecolores">
        <div className="postprojects1-css">
          <div className="postprojects-css-1">
            <form
              action="/postproject4"
              method="POST"
              id="lable"
              onSubmit={handleSubmit}>
              <div className="postprojects-css-2">
                <h1 className="postprojects1-container">Which Programming languages do you need?</h1>
                <p className="yourproject1-p">
                  <span>Tell us which languages you'd like your dedicated freelancer to use.</span>
                </p>
                <div className="postprojects-options">
                  <select
                    id="postData"
                    className="postprojects-option-select4"
                    ref={postDataSelectRef}>
                    <option value="">select languages</option>
                    <option>Python</option>
                    <option>C/C++</option>
                    <option>Arduino</option>
                    <option>JavaScript</option>
                    <option>Lua</option>
                    <option>Nodejs</option>
                    <option>node-red</option>
                    <option>flutter</option>
                    <option>R</option>
                    <option>SQL</option>
                    <option>Scala</option>
                    <option>Julia</option>
                    <option>CUDA (for GPU acceleration)</option>
                    <option>TensorFlow</option>
                    <option>PyTorch</option>
                    <option>ROS (Robot Operating System)</option>
                    <option>MATLAB</option>
                    <option>Go</option>
                    <option>Rust</option>
                  </select>
                </div>
                <div className="projects-selected-skills">
                  {selectedSkills2Array.map((skill, index) => (
                    <div key={index} className="selected-skill">
                      <span>{skill}</span>
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="delete-icon"
                        onClick={() => handleSkillDelete(skill)}
                      />
                    </div>
                  ))}
                </div>
                <div className="form-group">
                  <div className="postprojects1-css-button">
                  </div>
                </div>
                <div className="form-group">
                  <div className="postprojects1-css-button">
                    <Link to="/postproject3"><button className="postprojects1-button">Back
                      <FontAwesomeIcon icon={faArrowLeft} className="faArrowRight" />
                    </button></Link>
                    <error id="alert "></error>
                    <button type="submit" className="postprojects1-button-4" disabled={loading}>
                      {loading ? "Submitting..." : "Next"}
                      <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="postprojects1-rangs-steps-1">
            <p>Step 5 of 7</p>
            <div className="completion-percentage">{completionPercentage}%</div>
            <meter className="postprojects1-range-meter" type="Range" name="Range" min="0" max="100" value={completionPercentage} required ></meter>
          </div>
          <hr className="postprojects1-hr" />
          <div className="postprojects1-rangs-steps-2">
            <p><span>@2023 Pangaea x All Rights Reserved</span></p>
            <p className="p">Terms of Business</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Postproject4;
