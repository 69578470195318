import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import Academic from './Academic';
import Skillsandinterests from './Skills-and-interests';
import AreasofInterest from './Areas-of-Interest';
import Technical from './Technical';
import "./works.css";

const Applyforworks = () => {
    const [activeLink, setActiveLink] = useState('academic');

    const handleLinkClick = (linkName) => {
        setActiveLink(linkName);
    };

  return (
    <div className='students-dashboard-workses'>
        <div className='students-dashboard-works'>
        <div className='students-dashboard-works-links'>
        <div className='profile-link' onClick={() => handleLinkClick('academic')}>
        <Link to="#" className={activeLink === 'academic' ? 'active-skills-link' : ''}>
            <p className='active-profile'>
            Academic Background <FontAwesomeIcon icon={faEdit} className='faedit'/>
            </p>
        </Link>
        </div>
        <div className='profile-link' onClick={() => handleLinkClick('skills')}>
        <Link to="#" className={activeLink === 'skills' ? 'active-skills-link' : ''}>
            <p className='active-profile'>
            Technical Skills <FontAwesomeIcon icon={faEdit} className='faedit'/>
            </p>
        </Link>
        </div>
        <div className='profile-link' onClick={() => handleLinkClick('areas')}>
        <Link to="#" className={activeLink === 'areas' ? 'active-skills-link' : ''}>
            <p className='active-profile'>
            Areas of Interest <FontAwesomeIcon icon={faEdit} className='faedit'/>
            </p>
        </Link>
        </div>
        <div className='profile-link' onClick={() => handleLinkClick('technical')}>
        <Link to="#" className={activeLink === 'technical' ? 'active-skills-link' : ''}>
            <p className='active-profile'>
            Apply For Work<FontAwesomeIcon icon={faEdit} className='faedit'/>
            </p>
        </Link>
        </div>
        </div>
        <div className='myprofilelinks'>
          {activeLink === 'academic' && <div className='myprofilelink active'><Academic /></div>}
          {activeLink === 'skills' && <div className='myprofilelink active'><Skillsandinterests /></div>}
          {activeLink === 'areas' && <div className='myprofilelink active'><AreasofInterest /></div>}
          {activeLink === 'technical' && <div className='myprofilelink active'><Technical /></div>}
        </div>
        </div>
    </div>
  )
}

export default Applyforworks;
