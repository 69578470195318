import React, { useState } from "react";
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import Headrepage from "../../components/Homepages/Headerpage/StudentsHederpage";
import EyesOpen from "../../iconsopen/EyesOpen";
import EyesClose from "../../iconsopen/EyesClose";
import behaves from "../../assets/Signup.png";
import Google from "../../assets/google.png";
//import LinkedIn from "../../assets/linkedin.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Register.css";

function Signup() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    userType: "student"
  });

  const navigate = useNavigate();

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const loginwithgoogle = () => {
    window.location.href = "https://api.huebits.in/api/auth/google";
  };

  // const loginWithLinkedIn = () => {
  //   window.location.href = "https://api.huebits.in/api/auth/linkedin/callback";
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const regExspecialChars = /^(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-z])(?!.*\s).{8,}$/;

    if (data.password.length < 8) {
      toast.error("Password should be more than 8 characters");
      setLoading(false);
    } else if (!regExspecialChars.test(data.password)) {
      toast.error("Password should contain at least 1 digit, 1 special character, and 1 lower and upper case letter");
      setLoading(false);
    } else if (data.password !== data.confirmPassword) {
      toast.error("Confirm password does not match");
      setLoading(false);
      
    } else {
      const toastId = toast.loading("Submitting your registration details...");
      try {
        const response = await axios.post("https://api.huebits.in/api/signup", data, {
          headers: { "Content-Type": "application/json" },
        });

        if (response.data.error) {
          toast.update(toastId, { render: response.data.message, type: "error", isLoading: false, autoClose: 2000 });
        } else {
          toast.update(toastId, { render: response.data.message, type: "success", isLoading: false, autoClose: 2000 });
          setData({ username: "", password: "", confirmPassword: "", userType: "student" });
          setTimeout(() => navigate('/login'), 500);
        }
      } catch (error) {
        //console.error('Signup error:', error);
        let errorMessage = "An unexpected error occurred. Please try again.";

        if (error.response) {
          errorMessage = error.response.data.message || errorMessage;
        } else if (error.request) {
          errorMessage = "Network error: Please check your connection and try again.";
        } else {
          errorMessage = `Request error: ${error.message}`;
        }

        toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 5000 });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="students-flex">
      <Headrepage />
      <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />
      <div className="studentssign-cssimagesflex-color">
        <div className="studentssignup-cssimagesflex">
          <div className="students-signup-flex">
            <div>
              <img className="students-signupimage" src={behaves} alt="Signup" />
            </div>
            <div className="students-signup-background-color">
              <h1 className="center-signup">Sign Up to your account</h1>
              <form id="signup-form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="email" className="signup-label">Email</label>
                  <input
                    type="email"
                    id="username"
                    name="username"
                    className="signup-form-control"
                    placeholder="email@gmail.com"
                    value={data.username}
                    onChange={handleOnChange}
                    required
                    disabled={loading}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password" className="signup-label">Password</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    className="signup-form-control"
                    placeholder="Enter your password"
                    value={data.password}
                    onChange={handleOnChange}
                    required
                    disabled={loading}
                  />
                  <div className='icons-password' onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <EyesOpen /> : <EyesClose />}
                  </div>
                </div>
                <div>
                  <label htmlFor="confirm_password" className="signup-label">Confirm Password</label>
                  <input
                    type="password"
                    id="confirm_password"
                    className="signup-form-control"
                    name="confirmPassword"
                    placeholder="Confirm password"
                    value={data.confirmPassword}
                    onChange={handleOnChange}
                    required
                    disabled={loading}
                  />
                </div>
                <div className="signup-checkbox">
                  <p>
                    <input type="checkbox" className="checkbox-form-controle" required />
                    <span className="signup-user-terms">I agree with the User terms and privacy policy</span>
                  </p>
                </div>
                <div>
                  <button type="submit" className="students-signup-btn" disabled={loading}>
                    {loading ? 'Sign up...' : 'Sign up'}
                  </button>
                </div>
              </form>
              <div className="loginhr">
                <hr className="loginhrline" />
                <span className="login-hr-or">Or Continue With</span>
                <hr className="loginhrline1" />
              </div>
              <div className="linkedin-google">
                <div className="signingoogle-google-login">
                  <Link className="google-link" role="button" onClick={loginwithgoogle}>
                    <img className="googleicon" src={Google} alt="" />
                    <span className="signingoogle-google"></span>
                  </Link>
                </div>
                {/* <div className="signingoogle-linkedin-login">
                  <Link className="linkedin-link">
                    <img className="linkedinicon" src={LinkedIn} alt="" />
                    <span className="signingoogle-google"></span>
                  </Link>
                </div> */}
              </div>
              <div className="students-signup-accounts">
                <p>
                  <span className="signup-already-account">Already have an account? </span>
                  <Link to="/login" className="signuphere">Login here</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
