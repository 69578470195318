import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight,faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Headrepage from "../../Homepages/Headerpage/CompainesHeaderpage";
import { ToastContainer, toast } from 'react-toastify';
import "./postprojects.css";

const Postprojects1 = () => {
    const navigate = useNavigate();
    const [completionPercentage, setCompletionPercentage] = useState(0);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true); 

        const Projects = {
            title1: event.target.title1.value,
            textarea: event.target.textarea.value,
        };

        const toastId = toast.loading("Submitting your data...");

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('User not authenticated');
            }

            const response = await axios.post('https://api.huebits.in/api/postproject1', { Projects }, {
                headers: {
                    "content-type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.status === 'Success') {
                toast.update(toastId, { render: "Data submitted successfully!", type: "success", isLoading: false, autoClose: 3000 });
                setCompletionPercentage(prevPercentage => prevPercentage + 10);
                setTimeout(() => {
                    toast.error(null);
                    navigate("/postproject2");
                }, 1500); 
            } else {
                console.error('Data submission failed');
                toast.update(toastId, { render: "Submission failed. Please try again.", type: "error", isLoading: false, autoClose: 3000 });
            }
        } catch (error) {
                const errorMessage = error.response?.data?.message || "Network error: Please check your connection and try again.";
                toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 5000 });
            } finally {
                setLoading(false);
            }
        };

    return (
        <div className="students-flex companies-flex">
            <Headrepage />
            <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />
            <div className="homepagecolores">
                <div className="postprojects1-css">
                    <div className="postprojects1-css-1">
                        <form action="/postproject1" method="POST" id="postDataForm" onSubmit={handleSubmit}>
                            <div className="postprojects1-css-2">
                                <h1 className="postprojects1-container">Let's post your project</h1>
                                <p className="yourproject1-p"><span>Post your project in minutes. Get your project work done by our skilled engineers.</span></p>
                                <h2 className="yourproject1-h2">Give your project a title</h2>
                                <div className="postprojects1-css-text">
                                    <error id="alert"></error>
                                    <input type="text" name="title1" id="name" className="postprojects1-css-input" placeholder="Building Chatbot for costumer care." autoFocus="" required />
                                </div>
                                <h2 className="yourproject1-h2">Tell us a little bit about your project</h2>
                            </div>
                            <div className="postprojects1-css-text">
                                <error id="alert"></error>
                                <textarea rows="7" cols="20" className="postprojects1-css-textarea" id="postDataDescription" name="textarea"
                                    placeholder="Tell us in two to three sentences what the aim of your project is and what you'd like to achieve. Provide as much detail as possible and clearly state your expectations"
                                    autoFocus="" required></textarea>
                            </div>
                            <div className="form-group">
                                <div className="postprojects1-css-button">
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="postprojects1-css-button">
                                    <Link to="/Homepagespostprojects"><button className="postprojects1-button">Back
                                    <FontAwesomeIcon icon={faArrowLeft} className="faArrowRight" />
                                    </button></Link>
                                    <error id="alert "></error>
                                    <button type="submit" className="postprojects1-button-4" disabled={loading}>
                                        {loading ? "Submitting..." : "Next"}
                                    <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="postprojects1-rangs-steps-1">
                        <p>Step 1 of 7</p>
                        <div className="completion-percentage">{completionPercentage}%</div>
                        <meter className="postprojects1-range-meter" type="Range" name="Range" min="0" max="100" value={completionPercentage} required ></meter>
                    </div>
                    <hr className="postprojects1-hr" />
                    <div className="postprojects1-rangs-steps-2">
                        <p><span>@2023 Pangaea x All Rights Reserved</span></p>
                        <p className="p">Terms of Business</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Postprojects1;

