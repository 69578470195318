import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Getaccesstoallcourses from "../courses/Get_access_to_all_courses";

const MyComponent = () => {
  const [projects, setProjects] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");

  const handleUserProfile = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://api.huebits.in/api/learningpaths/projects/",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("API Response:", response.data);

      if (response.status === 200) {
        const fetchedProjects = response.data.data?.projects || [];
        setProjects(fetchedProjects);
        setIsSubscribed(response.data.isSubscribed);
      } else {
        console.error("API Error:", response.statusText);
        setError("Error fetching data.");
      }
    } catch (error) {
      console.error("Error fetching user details:", error.message);
      setError("Error fetching data.");
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    handleUserProfile();
  }, [handleUserProfile]);

  const displayedProjects = [];
  for (let i = 0; i < projects.length; i += 2) {
    displayedProjects.push(projects.slice(i, i + 2));
  }

  return (
    <div className="courseslist-courses">
      <div className="courseslist-myactive-courses">
        {/* <h1 className="courseslist">My Active Projects</h1> */}
        {loading ? (
          <p>Loading...</p>
        ) : !isSubscribed ? (
          <Getaccesstoallcourses />
        ) : displayedProjects.length === 0 ? (
          <p>No active projects available.</p>
        ) : (
          displayedProjects.map((projectGroup, index) => (
            <div key={index} className="courseslist-course-row">
              {projectGroup.map((project, subIndex) => (
                <div key={subIndex} className="courseslist-course-box">
                  <p className="courseslistp-myactive-courses">
                    {project.projectTitle}
                  </p>
                </div>
              ))}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default MyComponent;
