import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Userprofile = () => {
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(null);
  const [data, setData] = useState({
    areasofinterest1: "",
    areasofinterest2: "",
    areasofinterest3: "",
    areasofinterest4: "",
    areasofinterest5: "",
    areasofinterest6: "",
    areasofinterest7: "",
    areasofinterest8: "",
    areasofinterest9: "",
    areasofinterest10: "",
    areasofinterest11: "",
    areasofinterest12: "",
    areasofinterest13: "",
    areasofinterest14: "",
    areasofinterest15: "",
    areasofinterest16: "",
    areasofinterest17: "",
    areasofinterest18: "",
    areasofinterest19: "",
    fileInput: "",
  });
  const [userId, setUserId] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [resumeLoading, setResumeLoading] = useState(false);
  const token = localStorage.getItem("token");

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUserProfile = useCallback(async (updatedData) => {
    try {
      const response = await axios.put(`https://api.huebits.in/api/userprofile/${userId}`, updatedData, {
        headers: {
          "Content-Type": "application/json",
          authorization: `jwt ${localStorage.getItem('token')}`
        },
      });
      if (response.data.success) {
        toast.success(response.data.message, { autoClose: 2000 });
        setShowToast(true);
        setData(response.data.data);
        console.log("Updated user profile data:", response.data.data);
      } else {
        toast.error(response.data.message, { autoClose: 2000 });
        setShowToast(true);
      }
    } catch (error) {
      console.error('Failed to update profile:', error);
      toast.error("Network error: Please check your connection and try again.", { autoClose: 2000 });
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    const updatedData = { ...data };
    await handleUserProfile(updatedData);
  };

  useEffect(() => {
    if (token) {
      setUserId(userId);
      handleUserProfile();
    }
  }, [token, userId, handleUserProfile]);

  const renderDynamicInput = () => {
    switch (data.areasofinterest3) {
      case 'select':
        return <input type="text" name="dynamicInput" className="form-control" placeholder='select' onChange={handleOnChange} />;
      case 'previous internships or Work Experience':
        return <input type="text" name="dynamicInput" className="form-control" placeholder='Describe your previous internships or work experience' onChange={handleOnChange} />;
      case 'Freelancing Projects':
        return <input type="text" name="dynamicInput" className="form-control" placeholder='Describe your freelancing projects' onChange={handleOnChange} />;
      case 'Academic Projects':
        return <input type="text" name="dynamicInput" className="form-control" placeholder='Describe your academic projects' onChange={handleOnChange} />;
      default:
        return null;
    }
  };

  const handleResumeSubmit = async (event) => {
    event.preventDefault();
    setResumeLoading(true);

    const formData = new FormData(event.target);
    formData.forEach((value, key) => {
      console.log(`${key}:`, value);
    });

      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('User not authenticated');
        }
        const response = await axios.post(
          "https://api.huebits.in/api/studentsresume",
          formData,
          {
            headers: {
              "Authorization": `Bearer ${token}`,
              "Content-Type": "multipart/form-data"
            }
          }
        );

        if (response.data.status === "Success") {
          console.log("Resume Upload successfully");
          console.log("Updated User Data:", response.data.data);
          toast.success("Resume Upload Successful");
        } else {
          console.error("Resume Upload failed");
          toast.error("Resume Upload failed: " + response.data.message || "Please try again.");
        }
      } catch (error) {
        console.error("An error occurred during Resume Upload", error);
        console.error("Error response:", error.response);
      } finally {
        setResumeLoading(false);
      }
    };

  return (
    <div>
      {showToast && <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />}
      <h1 className="form-profileh1">Areas of Interest</h1>
      <div className="students-dashboard-profile">
        <form id="lable" onSubmit={handleSubmit}>
          <div className="form-group students-dashboard-profile-label">
          <div className="students-dashboard-profile-label">
            <div className="students-dashboard-profile-skills">
              <label htmlFor="areasofinterest1">Preferred Industries</label>
              <input type="text" name="areasofinterest1" id="areasofinterest1" value={data.areasofinterest1} className="form-control students-profile-label" placeholder='(e.g., IoT, Data Science, Embedded Systems)' onChange={handleOnChange} />
            </div>
            <div className="students-dashboard-profile-skills">
              <label htmlFor="areasofinterest2">Topics of Interest</label>
              <input type="text" name="areasofinterest2" id="areasofinterest2" className="form-control students-profile-label" placeholder='e.g., AI, Web Development, Data Science' value={data.areasofinterest2} onChange={handleOnChange} />
            </div>
            <p className="students-form-profilep">Experience:</p>
            <div className="custom-select-wrapper">
              <select name="areasofinterest3" id="areasofinterest3" className="form-control" value={data.areasofinterest3} onChange={handleOnChange}>
                <option value="">Select</option>
                <option value="previous internships or Work Experience">Previous Internships or Work Experience</option>
                <option value="Freelancing Projects">Freelancing Projects</option>
                <option value="Academic Projects">Academic Projects</option>
              </select>
              <i className="fas fa-caret-down dropdown-icon"></i>
            </div>
            <div className="students-dashboard-profile-skills">
              {renderDynamicInput()}
            </div>
            <p className="students-form-profilep">Certifications and Courses:</p>
            <div className="students-dashboard-profile-skills">
              <label htmlFor="areasofinterest4">Certifications</label>
              <input type="text" name="areasofinterest4" id="areasofinterest4" className="form-control students-profile-label" placeholder='(e.g., AWS, Microsoft, Cisco)' value={data.areasofinterest4} onChange={handleOnChange} />
            </div>
            <div className="students-dashboard-profile-skills">
              <label htmlFor="areasofinterest5">Portfolio</label>
              <input type="text" name="areasofinterest5" id="areasofinterest5" className="form-control students-profile-label" placeholder='e.g., www.myportfolio.com' value={data.areasofinterest5} onChange={handleOnChange} />
            </div>
            <div>
              <p className="students-form-profilep">Project Descriptions:</p>
              <div className="students-dashboard-profile-skills">
                <label htmlFor="areasofinterest6">Title</label>
                <input type="text" name="areasofinterest6" id="areasofinterest6" className="form-control students-profile-label" placeholder='e.g., Software Engineer, Data Analyst' value={data.areasofinterest6} onChange={handleOnChange} />
              </div>
              <div className="students-dashboard-profile-skills">
                <label htmlFor="areasofinterest7">Description</label>
                <input type="text" name="areasofinterest7" id="areasofinterest7" className="form-control students-profile-label" placeholder='e.g., Experienced in full-stack development and data analysis' value={data.areasofinterest7} onChange={handleOnChange} />
              </div>
            </div>
            <div className="students-dashboard-profile-skills">
              <label htmlFor="areasofinterest8">Technical Skills</label>
              <input type="text" name="areasofinterest8" id="areasofinterest8" className="form-control students-profile-label" placeholder='e.g., JavaScript, Python, React' value={data.areasofinterest8} onChange={handleOnChange} />
            </div>
            <div className="students-dashboard-profile-skills">
              <label htmlFor="areasofinterest9">Languages</label>
              <input type="text" name="areasofinterest9" id="areasofinterest9" className="form-control students-profile-label" placeholder='e.g., English, Spanish' value={data.areasofinterest9} onChange={handleOnChange} />
            </div>
            <div className="students-dashboard-profile-skills">
              <label htmlFor="areasofinterest10">Hobbies</label>
              <input type="text" name="areasofinterest10" id="areasofinterest10" className="form-control students-profile-label" placeholder='e.g., Reading, Hiking' value={data.areasofinterest10} onChange={handleOnChange} />
            </div>
            <div className="students-dashboard-profile-skills">
              <label htmlFor="areasofinterest11">Preferred Job Role</label>
              <input type="text" name="areasofinterest11" id="areasofinterest11" className="form-control students-profile-label" placeholder='e.g., Software Developer' value={data.areasofinterest11} onChange={handleOnChange} />
            </div>
            <div className="students-dashboard-profile-skills">
              <label htmlFor="areasofinterest12">Preferred Location</label>
              <input type="text" name="areasofinterest12" id="areasofinterest12" className="form-control students-profile-label" placeholder='e.g., New York, San Francisco' value={data.areasofinterest12} onChange={handleOnChange} />
            </div>
            <div className="students-dashboard-profile-skills">
              <label htmlFor="areasofinterest13">Professional Summary</label>
              <textarea name="areasofinterest13" id="areasofinterest13" className="form-control students-profile-label" placeholder='Write a brief professional summary' value={data.areasofinterest13} onChange={handleOnChange} />
            </div>
            <div className="students-dashboard-profile-skills">
              <label htmlFor="areasofinterest14">References</label>
              <input type="text" name="areasofinterest14" id="areasofinterest14" className="form-control students-profile-label" placeholder='e.g., John Doe, Jane Smith' value={data.areasofinterest14} onChange={handleOnChange} />
            </div>
            <div className="students-dashboard-profile-skills">
              <label htmlFor="areasofinterest15">Additional Information</label>
              <textarea name="areasofinterest15" id="areasofinterest15" className="form-control students-profile-label" placeholder='e.g., Any additional details you want to provide' value={data.areasofinterest15} onChange={handleOnChange} />
            </div>
            <div className="students-dashboard-profile-skills">
              <label htmlFor="areasofinterest16">Goals and Aspirations</label>
              <textarea name="areasofinterest16" id="areasofinterest16" className="form-control students-profile-label" placeholder='e.g., Long-term career goals' value={data.areasofinterest16} onChange={handleOnChange} />
            </div>
            <div className="students-dashboard-profile-skills">
              <label htmlFor="areasofinterest17">Current Occupation</label>
              <input type="text" name="areasofinterest17" id="areasofinterest17" className="form-control students-profile-label" placeholder='e.g., Student, Full-time Employee' value={data.areasofinterest17} onChange={handleOnChange} />
            </div>
            <div className="students-dashboard-profile-skills">
              <label htmlFor="areasofinterest18">Availability</label>
              <input type="text" name="areasofinterest18" id="areasofinterest18" className="form-control students-profile-label" placeholder='e.g., Immediately, 1 month' value={data.areasofinterest18} onChange={handleOnChange} />
            </div>
            <div className="students-dashboard-profile-skills">
              <label htmlFor="areasofinterest19">LinkedIn Profile</label>
              <input type="text" name="areasofinterest19" id="areasofinterest19" className="form-control students-profile-label" placeholder='e.g., www.linkedin.com/in/username' value={data.areasofinterest19} onChange={handleOnChange} />
            </div>
          </div>
          </div>
          <div className="form-group">
            <button type="submit" className="user-update">
                {loading ? 'Loading...' : 'Save Data'}
              </button>
            </div>
        </form>
      <form id="label" onSubmit={handleResumeSubmit} encType="multipart/form-data">
        <div className="students-form-resume-profile">
        <p className="students-form-profilep">Resume Upload</p>
        </div>
        <div className="students-dashboard-works-apply">
        <input
              type="file"
              id="fileInput"
              name="fileInput"
              className="students-dashboard-resume"
              accept=".doc, .pdf"
              placeholder="resume.pdf"
              autoFocus
              required />
          <button type="submit" className="user-update" disabled={resumeLoading}>
            {resumeLoading ? 'Uploading...' : 'Upload Resume'}
          </button>
          </div>
        </form>
    </div>
    </div>
  );
};

export default Userprofile;
