import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./projectsprogress.css";
import ChangingProgressProvider from "./projectsprogeesprojects.jsx";

const getColorForProgress = (progress) => {
  if (progress >= 1 && progress <= 4) {
    return "#fb7403";
  } else if (progress >= 5 && progress <= 8) {
    return " #fbb003";
  } else if (progress >= 9 && progress <= 12) {
    return "#bffb03";
  } else if (progress >= 13 && progress <= 15) {
    return "#008000";
  } else {
    return "#008000";
  }
};

const VerticalProgressBar = () => (
  <div className="vertical-progreses-container">
  <div className="vertical-progress-container">
    <h1 className="vertical-progress-heading">Projects Progress</h1>
    <div className="projectsprogress">
      <ChangingProgressProvider values={[0]}>
        {(value) => (
          <CircularProgressbar
            value={value}
            text={`${Math.round((value * 15) / 100)}/15`}
            circleRatio={0.50}
            strokeWidth={12}
            className="projectsprogress-color"
            styles={buildStyles({
              rotation: 1 / 2 + 2 / 8,
              strokeLinecap: "round",
              textColor: "#052659",
              pathColor: getColorForProgress(value * 15 / 100),
              trailColor: "white",
              pathTransitionDuration: 0.5,
              textSize: "12px",
            })}
          />
        )}
      </ChangingProgressProvider>
    </div>
  </div>
  </div>
);

export default VerticalProgressBar;

