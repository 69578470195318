import React, { useState } from "react";
import { Link } from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Modal, Box, Button } from "@mui/material";
import Huebits from "../../../assets/hue2.png";
import PopupImage from "../../../assets/Popup6.png";
import "./Header.css";

const Pages = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [openModel, setOpenModel] = useState(false);

  const toggleMenu = () => setMenuActive(prev => !prev);
  const closeMenu = () => setMenuActive(false);

  return (
    <div className="hubits-div">
      <img className="homehuebits" src={Huebits} alt="Huebits" />
      <div className="hubits1">
        <div className="responsive-menu" onClick={toggleMenu}>
          <MenuOutlinedIcon className="menu-icon" />
        </div>
        <div className="hubitsp-1">
          <ul className={`hubits ${menuActive ? 'active' : ''}`} id="huebits">
            <li className="companies">
              <Link to='/students' className="aboutuses" onClick={closeMenu}>Students</Link>
            </li>
            <li className="students">
              <Link to='/companies' className="aboutuses" onClick={closeMenu}>Companies</Link>
            </li>
            <li>
              <Link className="aboutuses" onClick={() => { closeMenu(); setOpenModel(true); }}>Log in</Link>
            </li>
          </ul>
        </div>
      </div>

      <Modal
        open={openModel}
        onClose={() => setOpenModel(false)}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={{
          width: { xs: 300, sm: 400, md: 600 }, 
          height: { xs: 400, sm: 400, md: 500 },
          backgroundImage: `url(${PopupImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: 2,
          p: -1,
          position: 'relative'
        }}>
          <CloseIcon
            sx={{
              position: 'absolute',
              top: { xs: -16, md: -24 },
              right: { xs: -16, md: -24 },
              cursor: 'pointer',
              color: 'white'
            }}
            onClick={() => setOpenModel(false)}
          />
          <Box sx={{ mt: { xs: 4, md: 8 }, textAlign: 'center' }}>
            <Link to="/login" className="title1">
              <Button
                variant="contained"
                sx={{
                  background: 'linear-gradient(135deg, #5483B3, #052659)',
                  color: 'white',
                  borderRadius: 2,
                  px: { xs: 2, md: 4 }, 
                  py: { xs: 1.5, md: 2 },
                  fontWeight: 700,
                  fontSize: { xs: 16, md: 18 },
                  marginTop: { xs: 8, md: 8 },
                  transform: 'scale(0.90) translateY(-5px)',
                  '&:hover': {
                    background: 'linear-gradient(135deg, #052659, #5483B3)',
                    transform: 'scale(1) translateY(-5px)'
                  }
                }}
              >
                Students Log in
              </Button>
            </Link>
            <Box sx={{ mt: { xs: 1, md: 2 } }}>
              <Link to="/companiessignin" className="title">
                <Button
                  variant="contained"
                  sx={{
                    background: 'linear-gradient(135deg, #5483B3, #052659)',
                    color: 'white',
                    borderRadius: 2,
                    px: { xs: 2, md: 4 },
                    py: { xs: 1.5, md: 2 }, 
                    fontWeight: 700,
                    fontSize: { xs: 16, md: 18 },
                    marginTop: { xs: 14, md: 18 },
                    transform: 'scale(0.90) translateY(-5px)',
                    '&:hover': {
                      background: 'linear-gradient(135deg, #052659, #5483B3)',
                      transform: 'scale(1) translateY(-5px)'
                    }
                  }}
                >
                  Companies Log in
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Pages;
