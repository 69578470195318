import React,{useState} from "react";
import axios from 'axios';
import Project1 from "../../../assets/dashboard-images/images/images/project1.jpg";
import Project2 from "../../../assets/dashboard-images/images/images/project2.jpg";
import Project3  from "../../../assets/dashboard-images/images/images/project3.jpg";
import Project4 from "../../../assets/dashboard-images/images/images/project4.jpg";
import Project5 from "../../../assets/dashboard-images/images/images/project5.png";
import Project6 from "../../../assets/dashboard-images/images/images/project6.jpg";
import Project7 from "../../../assets/dashboard-images/images/images/projects/32.png";
import Project8 from "../../../assets/dashboard-images/images/images/projects/29.png";
import Project9 from "../../../assets/dashboard-images/images/images/projects/6.png";
import Project10 from "../../../assets/dashboard-images/images/images/projects/34.png";
import Project11 from "../../../assets/dashboard-images/images/images/projects/17.png";
import Project12 from "../../../assets/dashboard-images/images/images/projects/31.png";
import Project13 from "../../../assets/dashboard-images/images/images/projects/33.png";
import Project14 from "../../../assets/dashboard-images/images/images/projects/38.png";
import Project15 from "../../../assets/dashboard-images/images/images/projects/11.png";
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'bootstrap/dist/css/bootstrap.min.css';


const projects = [
  {
    projectId: 1.1,
    projectTitle: "Smart Home using ESP32 and Blynk",
    videoUrl: "https://learn.huebits.in/courses/Smart-Home-using-ESP32-and-Blynk-660e7e28c6083f17c8eb84fd",
    image: Project1,
    completion: 0
  },
  {
    projectId: 1.2,
    projectTitle: "Smart Car Parking System using ESP32 and Arduino",
    videoUrl: "https://learn.huebits.in/courses/Smart-Car-Parking-System-using-ESP32-and-Arduino-660e7ee394fd573437e5b2b4",
    image: Project2,
    completion: 0
  },
  {
    projectId: 1.3,
    projectTitle: "Smart Street Lighting using IoT",
    videoUrl: "https://learn.huebits.in/courses/Smart-Street-Lighting-Systems-Project-660e80e8a21241265847fff9",
    image: Project3,
    completion: 0
  },
  {
    projectId: 1.4,
    projectTitle: "Smart Irrigation System using ESP32",
    videoUrl: "https://learn.huebits.in/courses/Smart-Irrigation-System-using-ESP32-660e8140a21241265848004d",
    image: Project4,
    completion: 0
  },
  {
    projectId: 1.5,
    projectTitle: "Smart Shoe Development using ESP32 and Blynk",
    videoUrl: "https://learn.huebits.in/courses/Smart-Shoe-Development-using-ESP32-and-Blynk-6613a2284443c779482576e3",
    image: Project5,
    completion: 0
  },
  {
    projectId: 1.6,
    projectTitle: "Smart Shoe for Blind using ESP32 Project",
    videoUrl: "https://learn.huebits.in/courses/Smart-Shoe-for-Blind-using-ESP32-Project-66275a4609ec531accf7d82f",
    image:  Project6,
    completion: 0
  },
  {
    projectId: 1.7,
    projectTitle: "Smart Agriculture using Internet of Things and MQTT Protocol (Raspberry Pi)",
    videoUrl: "https://learn.huebits.in/courses/Smart-Agriculture-using-Internet-of-Things-and-MQTT-Protocol--Raspberry-Pi--66e82df6153e7d6569f24c18", 
    image: Project7,
    completion: 0
  },
  {
    projectId: 1.8,
    projectTitle: "MQTT based Environment Monitoring in factories for Employee Safety (raspberry Pi)",
    videoUrl: "https://learn.huebits.in/courses/MQTT-based-Environment-Monitoring-in-factories-for-Employee-Safety--Raspberry-Pi--66e82e835f6cf505f4d50eb3",
    image: Project8,
    completion: 0
  },
  {
    projectId: 1.9,
    projectTitle: "Developing Smart Workspace Based IOT with Artificial intelligence and using Telegram CHATBOT",
    videoUrl: "https://learn.huebits.in/courses/Developing-Smart-Workspace-Based-IOT-with-Artificial-intelligence-and-using-Telegram-CHATBOT--Raspberry-Pi--66e82f3e688b0e0b4daf3367", 
    image: Project9,
    completion: 0
  },
  {
    projectId: 1.10,
    projectTitle: "Smart Light Bulb Controlling on Raspberry Pi Using Flask Web App",
    videoUrl: "https://learn.huebits.in/courses/Smart-Light-Bulb-Controlling-on-Raspberry-Pi-Using-Flask-Web-App-66e82ff2db4d465ff95057c3", 
    image: Project10,
    completion: 0
  },
  {
    projectId: 1.11,
    projectTitle: "IoT Based Feeder Monitoring System",
    videoUrl: "https://learn.huebits.in/courses/IoT-Based-Feeder-Monitoring-System--ESP32--66e830a28a4ba6652360f2ac", 
    image: Project11,
    completion: 0
  },
  {
    projectId: 1.12,
    projectTitle: "Remote Health Monitoring and Protection of Transformers Using IoT",
    videoUrl: "https://learn.huebits.in/courses/Remote-Health-Monitoring-and-Protection-of-Transformers-Using-IoT-66e83202268fd80c0d02f9b3", 
    image: Project12,
    completion: 0
  },
  {
    projectId: 1.13,
    projectTitle: "Smart Home Energy Monitoring System using Thingspeak",
    videoUrl: "https://learn.huebits.in/courses/Smart-Home-Energy-Monitoring-System-using-Thingspeak-66e832992737e57c23a5f688", 
    image: Project13,
    completion: 0
  },
  {
    projectId: 1.14,
    projectTitle: "IoT-Powered Smart Energy Meter Surveillance",
    videoUrl: "https://learn.huebits.in/courses/IoT-Powered-Smart-Energy-Meter-Surveillance-66e832f5f1f27f2d14777415",
    image: Project14,
    completion: 0
  },
  {
    projectId: 1.15,
    projectTitle: "Energy Monitoring using Emoncms",
    videoUrl: "https://learn.huebits.in/courses/Energy-Monitoring-using-Emoncms-66e83407db4d465ff95058b2",
    image: Project15,
    completion: 0
  }
];

const Start = () => {
    const [, setMessage] = useState(null);
  
    const handleCourseClick = async (projectTitle, projectId, videoUrl) => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('User not authenticated');
        }
        window.open(videoUrl);
        const response = await axios.post(
          `https://api.huebits.in/api/learningpaths/projects/`,
          { projectId, projectTitle },
          {
            headers: {
              "content-type": "application/json",
              "Authorization": `Bearer ${token}`
            },
          }
        );
        setMessage(response.projects.message);
      } catch (error) {
        console.error('Error handling course click:', error);
        setMessage('Error: Unable to save course information.');
      }
    };


  return (
    <div>
      <p className="mylearningpaths-p">Recommended Projects</p>
      <div className="mylearningpath">
        <div className="Wrapper-sc-fvyqwl-1 iZEyIQ AssignmentList__Timeline-sc-1t9paan-2 chIzFH">
          <ul className="Circle-sc-fvyqwl-1">
            {projects.map((project, index) => (
              <li key={project.projectId}>
                <div className="learningpath-courses-dashboard">
                  <div>
                  <img src={project.image} alt={project.projectTitle} className="smart-shoe-development" />
                  </div>
                  <div>
                  <p className={`pythoncourses${project.projectId}`}>
                  {index + 1}. {project.projectTitle}
                  </p>
                  <div className="learningpath-courses-dashboard-progressbar">
                  <p className="learningpath-courses-dashboard-skills-completed">{project.completion}%</p>
                  <ProgressBar now={project.completion} className={`custom-progress-bar`} />
                  </div>
                  <div>
                     <div>
                    {project.completion === 100 ? (
                      <>
                      <button onClick={() => handleCourseClick(project.projectTitle, project.projectId, project.videoUrl)} className="learningpath-courses-dashboard-button-start">Download Certificate</button>
                      </>
                    ) : project.completion >= 10 ? (
                      <button onClick={() => handleCourseClick(project.projectTitle, project.projectId, project.videoUrl)}  className="learningpath-courses-dashboard-button-start">Continue Learning</button>
                    ) : (
                      <button onClick={() => handleCourseClick(project.projectTitle, project.projectId, project.videoUrl)} className="learningpath-courses-dashboard-button-start">Subscribe</button>
                    )}
                  </div>
                  </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
);
};

export default Start;