import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Headrepage from "../../Homepages/Headerpage/CompainesHeaderpage";
import { ToastContainer, toast } from 'react-toastify';

const Postproject3 = () => {
  const navigate = useNavigate();
  const [selectedSkills2Array, setSelectedSkills2Array] = useState([]);
  const [completionPercentage, setCompletionPercentage] = useState(30);
  const [loading, setLoading] = useState(false);
  const postDataSelectRef = useRef(null);

  const handlePostDataChange = useCallback(() => {
    const selectedSkill = postDataSelectRef.current.value.trim();
    if (selectedSkill) {
      if (selectedSkills2Array.length < 9) {
        if (!selectedSkills2Array.includes(selectedSkill)) {
          const newSkillsArray = [...selectedSkills2Array, selectedSkill];
          setSelectedSkills2Array(newSkillsArray);
          postDataSelectRef.current.value = ""; // Reset the dropdown
        }
      } else {
        toast.error("You can select a maximum of 9 skills.");
      }
    }
  }, [selectedSkills2Array]);

  useEffect(() => {
    const postDataSelect = postDataSelectRef.current;
    if (postDataSelect) {
      postDataSelect.addEventListener("change", handlePostDataChange);
      return () => {
        postDataSelect.removeEventListener("change", handlePostDataChange);
      };
    }
  }, [handlePostDataChange]);

  const handleSkillDelete = (skillToDelete) => {
    const updatedSkills = selectedSkills2Array.filter(skill => skill !== skillToDelete);
    setSelectedSkills2Array(updatedSkills);
  };

  const validateSkills = () => {
    if (selectedSkills2Array.length === 0) {
      toast.error("Please select at least one skill.");
      return false;
    }
    if (selectedSkills2Array.length > 9) {
      toast.error("You can select a maximum of 9 skills.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateSkills()) {
      return;
    }

    setLoading(true);

    const Projects = {
      skills1: selectedSkills2Array.join(", ")
    };

    const toastId = toast.loading("Submitting your data...");

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('User not authenticated');
      }
      const response = await axios.post(
        "https://api.huebits.in/api/postproject3", { Projects },
        {
          headers: {
            "content-type": "application/json",
            "Authorization": `Bearer ${token}`
          }
        }
      );

      if (response.data.status === "Success") {
        console.log("Data submitted successfully");
        console.log("Updated User Data:", response.data.data);
        toast.update(toastId, { render: "Data submitted successfully!", type: "success", isLoading: false, autoClose: 3000 });
        setCompletionPercentage(prevPercentage => prevPercentage + 20);
        setTimeout(() => {
          toast.dismiss();
          navigate("/postproject4");
        }, 1500);
      } else {
        console.error("Data submission failed");
        toast.update(toastId, { render: "Submission failed. Please try again.", type: "error", isLoading: false, autoClose: 3000 });
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Network error: Please check your connection and try again.";
      toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 5000 });
  } finally {
      setLoading(false);
  }
};

  return (
    <div className="students-flex companies-flex">
      <Headrepage />
      <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />
      <div className="homepagecolores">
        <div className="postprojects1-css">
          <div className="postprojects-css-1">
            <form action="/postproject3" method="POST" id="lable" onSubmit={handleSubmit}>
              <div className="postprojects-css-2">
                <h1 className="postprojects1-container">What skills are you looking for in particular?</h1>
                <p className="yourproject1-p"><span>Let's ensure that your project post attracts the talent with the right skillset.</span></p>
                <div className="postprojects-options">
                  <select id="postData" className="postprojects-option-select" ref={postDataSelectRef}>
                    <option value="">select option</option>
                    <option>Research</option>
                    <option>AWS IoT</option>
                    <option>Azure IoT</option>
                    <option>Bosch IoT Suite</option>
                    <option>Firmware Development</option>
                    <option>Hardware design</option>
                    <option>PCB Design</option>
                    <option>IoT Product Design</option>
                    <option>IoT Edge</option>
                    <option>Yocto</option>
                    <option>Azure Cloud</option>
                    <option>AWS Cloud</option>
                    <option>Embedded Systems Programming</option>
                    <option>Embedded Systems Design</option>
                    <option>Industrial Internet of Things(IIoT)</option>
                    <option>IoT communication protocols</option>
                    <option>Business Intelligence</option>
                    <option>Machine Learning</option>
                    <option>PowerBI</option>
                    <option>Databases</option>
                    <option>Data Analytics</option>
                    <option>Chatbots</option>
                    <option>LLMs</option>
                    <option>RAG</option>
                    <option>Natural Language Processing (NLP)</option>
                    <option>Data Analysis</option>
                    <option>MLOps</option>
                    <option>Web Development using MERN Stack</option>
                    <option>MEAN Stack</option>
                    <option>Robotics</option>
                  </select>
                </div>
                <div className="projects-selected-skills">
                  {selectedSkills2Array.map((skill, index) => (
                    <div key={index} className="selected-skill">
                      <span>{skill}</span>
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="delete-icon"
                        onClick={() => handleSkillDelete(skill)}
                      />
                    </div>
                  ))}
                </div>
                <div className="form-group">
                  <div className="postprojects1-css-button"></div>
                </div>
                <div className="form-group">
                  <div className="postprojects1-css-button">
                    <Link to="/postproject2">
                      <button className="postprojects1-button">
                        Back <FontAwesomeIcon icon={faArrowLeft} className="faArrowRight" />
                      </button>
                    </Link>
                    <button type="submit" className="postprojects1-button-4" disabled={loading}>
                      {loading ? "Submitting..." : "Next"} <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="postprojects1-rangs-steps-1">
            <p>Step 3 of 7</p>
            <div className="completion-percentage">{completionPercentage}%</div>
            <meter className="postprojects1-range-meter" type="range" name="Range" min="0" max="100" value={completionPercentage} required></meter>
          </div>
          <hr className="postprojects1-hr" />
          <div className="postprojects1-rangs-steps-2">
            <p><span>@2023 Pangaea x All Rights Reserved</span></p>
            <p className="p">Terms of Business</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Postproject3;
