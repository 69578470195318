import React from 'react';

const ImageComponent = () => {
  return (
    <img 
      className="brz-img-1 filtered-image" 
      srcSet="https://cloud-1de12d.b-cdn.net/media/iW=430&amp;iH=66&amp;oX=0&amp;oY=0&amp;cW=430&amp;cH=66/d08-curve-line-img/image.png 1x, https://cloud-1de12d.b-cdn.net/media/iW=860&amp;iH=132&amp;oX=0&amp;oY=0&amp;cW=860&amp;cH=132/d08-curve-line-img/image.png 2x" 
      src="https://cloud-1de12d.b-cdn.net/media/iW=1170&amp;iH=181&amp;oX=0&amp;oY=0&amp;cW=1170&amp;cH=181/d08-curve-line-img/image.png" 
      alt="" 
      draggable="false" 
      loading="lazy" 
    />
  );
};

export default ImageComponent;
