import React, { useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Headrepage from "../../Homepages/Headerpage/CompainesHeaderpage";
import { ToastContainer, toast } from 'react-toastify';
import "./postprojects.css";

const Postproject2 = () => {
    const navigate = useNavigate();
    const [completionPercentage, setCompletionPercentage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [selectedSkills1, setSelectedSkills1] = useState([]);

    const handlePostDataClick = useCallback((event) => {
        if (event.target.tagName === 'DIV') {
            const skill = event.target.textContent.trim();
            if (!selectedSkills1.includes(skill)) {
                setSelectedSkills1([...selectedSkills1, skill]);
            }
        }
    }, [selectedSkills1]);

    const handleDeleteSkill = (skillToDelete) => {
        setSelectedSkills1(selectedSkills1.filter(skill => skill !== skillToDelete));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

         // Validate that at least one skill is selected
    if (selectedSkills1.length === 0) {
        toast.error("Please select at least one skill.");
        return;
    }
        setLoading(true);

        const Projects = {
            skills: selectedSkills1.join(', '),
        };

        const toastId = toast.loading("Submitting your data...");

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('User not authenticated');
            }
            const response = await axios.post('https://api.huebits.in/api/postproject2', { Projects }, {
                headers: {
                    "content-type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });
            console.log('Response:', response);

            if (response.data.status === 'Success') {
                console.log('Data submitted successfully');
                console.log('Updated User Data:', response.data.data);
                toast.update(toastId, { render: "Data submitted successfully!", type: "success", isLoading: false, autoClose: 3000 });
                setCompletionPercentage(prevPercentage => prevPercentage + 20);
                setTimeout(() => {
                    navigate("/postproject3");
                }, 1500);
            } else {
                console.error('Data submission failed');
                toast.update(toastId, { render: "Submission failed. Please try again.", type: "error", isLoading: false, autoClose: 3000 });
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || "Network error: Please check your connection and try again.";
            toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 5000 });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="students-flex companies-flex">
            <Headrepage />
            <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />
            <div className="homepagecolores">
                <div className="postprojects1-css">
                    <div className="postprojects-css-1">
                        <form action="/postproject2" method="POST" id="lable" onSubmit={handleSubmit}>
                            <div className="postprojects-css-2">
                                <h1 className="postprojects1-container">Which industry is this project for?</h1>
                                <p className="yourproject1-p"><span>Choose the industry for your project to match you with the perfect engineering team.</span></p>
                                <div className="postprojectsoptionsdiv">
                                    <div className="skills" onClick={handlePostDataClick}>Any</div>
                                    <div className="skills" onClick={handlePostDataClick}>Internet of Things</div>
                                    <div className="skills" onClick={handlePostDataClick}>Data Science</div>
                                    <div className="skills" onClick={handlePostDataClick}>Machine Learning</div>
                                    <div className="skills" onClick={handlePostDataClick}>Embedded Systems</div>
                                    <div className="skills" onClick={handlePostDataClick}>Cyber Security</div>
                                    <div className="skills" onClick={handlePostDataClick}>Robotics</div>
                                    <div className="skills" onClick={handlePostDataClick}>Full Stack Web Development</div>
                                </div>
                                <div className="projects-selected-skills">
                                    {selectedSkills1.map((skill, index) => (
                                        <div key={index} className="selected-skill">
                                            {skill}
                                            <FontAwesomeIcon
                                                icon={faTimes}
                                                className="delete-icon"
                                                onClick={() => handleDeleteSkill(skill)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="postprojects1-css-button">
                                    <Link to="/postproject1">
                                        <button className="postprojects1-button">Back
                                            <FontAwesomeIcon icon={faArrowLeft} className="faArrowRight" />
                                        </button>
                                    </Link>
                                    <button type="submit" className="postprojects1-button-4" disabled={loading}>
                                        {loading ? "Submitting..." : "Next"}
                                        <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="postprojects1-rangs-steps-1">
                        <p>Step 2 of 7</p>
                        <div className="completion-percentage">{completionPercentage}%</div>
                        <meter className="postprojects1-range-meter animated" type="Range" name="Range" min="0" max="100" value={completionPercentage} required></meter>
                    </div>
                    <hr className="postprojects1-hr" />
                    <div className="postprojects1-rangs-steps-2">
                        <p><span>@2023 Pangaea x All Rights Reserved</span></p>
                        <p className="p">Terms of Bussiness</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Postproject2;
