import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck,faTimes } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import "./bestplan.css";

const Bestplan = () => {
    return (
            <div className="Choose-Servicess">
                  <div className="students-inr2-3-lakh-flex">
                  <div className="students-inr2-3-background">
                  <div className="inr2-3">
                    <h1 className="singlemembership-monthly-plan">Monthly Plan</h1>
                    <p className="singlemembership-monthly-plan-p"><span className="singlemembership-inr">₹</span><span className="singlemembership-mo"> 2000/-</span><span className="singlemembership-month">per month</span></p>
                    <div className="students-inr2-3-background-hr">
                      <hr />
                    </div>
                    <div className="flex-Internet-of-Things">
                      <div className="Internet-of-Things-1">
                      <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        <span className="Internet-of-Things-1-learning-paths">Access to all Courses and Projects</span></p>
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        <span className="Internet-of-Things-1-learning-paths">1-to-1 Doubt Sessions: 2 hours per month</span></p>
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        <span className="Internet-of-Things-1-learning-paths">Access to New Projects Added Monthly</span></p>
                        <p> <FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        <span className="Internet-of-Things-1-learning-paths">Guaranteed Internships</span></p>
                        <p> <FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        <span className="Internet-of-Things-1-learning-paths">Bootcamps</span></p>
                        <p> <FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        <span className="Internet-of-Things-1-learning-paths">Learning Paths</span></p>
                        <p> <FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        <span className="Internet-of-Things-1-learning-paths">Placement Assistance</span></p>
                        <p><FontAwesomeIcon icon={faTimes} className="fontawsomeicon-companies" />
                        <span className="Internet-of-Things-1-learning-paths Internet-of-Things-1-learning-pathses">Real Industrial Experience</span></p>
                        <p><FontAwesomeIcon icon={faTimes} className="fontawsomeicon-companies" />
                        <span className="Internet-of-Things-1-learning-paths Internet-of-Things-1-learning-pathses">AI Capstone Magic (Upcoming)</span></p>
                        </div>
                      <div className="iotrevolution-month">
                      <Link to="https://learn.huebits.in/membership">
                      <button className="btn-iotrevolution-students">Start
                      <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
                      </button>
                      </Link>
                      </div>
                    </div>
                    </div>
                    </div>
                    <div className="students-inr2-4-background">
                    <div className="inr2-4">
                    <h1 className="singlemembership-monthly-plan">Annual Plan</h1>
                    <p className="singlemembership1">
                    <span className="singlemembership-inr">₹</span><span className="singlemembership-mo"> 1200/-</span><span className="singlemembership-month">per month</span></p>
                    <div className="students-inr2-3-background-hr">
                      <hr />
                    </div>
                    <div className="flex-Internet-of-Things">
                      <div className="Internet-of-Things-1">
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        <span className="Internet-of-Things-1-learning-paths">Access to all Courses and Projects</span></p>
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        <span className="Internet-of-Things-1-learning-paths">1-to-1 Doubt Sessions: 2 hours per month</span></p>
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        <span className="Internet-of-Things-1-learning-paths">Access to New Projects Added Monthly</span></p>
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        <span className="Internet-of-Things-1-learning-paths">Guaranteed Internships</span></p>
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        <span className="Internet-of-Things-1-learning-paths">Bootcamps</span></p>
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        <span className="Internet-of-Things-1-learning-paths">Learning Paths</span></p>
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        <span className="Internet-of-Things-1-learning-paths">Placement Assistance</span></p>
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        <span className="Internet-of-Things-1-learning-paths">Real Industrial Experience</span></p>
                        <p><FontAwesomeIcon icon={faCheck} className="fontawsomeicon-companies" />
                        <span className="Internet-of-Things-1-learning-paths">AI Capstone Magic (Upcoming)</span></p>
                        </div>
                        <div className="">
                        <Link to="https://learn.huebits.in/membership">
                      <button className="btn-iotrevolution-students-annual">Start
                      <FontAwesomeIcon icon={faArrowRight} className="faArrowRight" />
                      </button>
                      </Link>
                  </div>
                  </div>
            </div>
        </div>
      </div>
    </div>
    )
}

export default Bestplan;