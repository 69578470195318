import React from "react";
import {Link} from "react-router-dom";
import Headway from "../../../assets/16.png";
import Headway1 from "../../../assets/17.png";

const Background = () => {
    return (
        <div>
        <div className="backgroundimages-1"> 
        <div className="backgroundcolorin-images">
            <div className="backgroundcolorin-images-ul">
            <ul>
            <li className="backgroundcolorin-images-li">
            <p className="backgroundcolorin-experience-learn-h1">Learn Skills</p>
            <p className="backgroundcolorin-experience-learn-h1">Earn Experience</p>
            <p className="backgroundcolorin-experience-p">Join huebits and become expert</p>
            <div className="buttonget-join-now-btn-link">
            <Link to="https://learn.huebits.in/membership"><button className="buttonget-join-now-btn">Join Now</button></Link>
            </div>
            </li>
            </ul>
            <div className="headway-images">
            <img className="headway-image" src={Headway} alt=""/>
            <img className="headway-image" src={Headway1} alt=""/>
            </div>
            </div>
        </div>
        </div>
        </div>
    )
}

export default Background;