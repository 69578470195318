import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import avatar from '../../../helper/profile.png';
import convertToBase64 from '../../../helper/Convert';
import { Country, State, City } from 'country-state-city';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./userprofile.css";

const Userprofile = () => {
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(null);
  const [data, setData] = useState({
    username: "",
    mobile: "",
    city: "",
    state: "",
    country: "",
    profilePic: "",
  });
  const [userId, setUserId] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);

  useEffect(() => {
    if (data.country) {
      setStates(State.getStatesOfCountry(data.country));
    }
  }, [data.country]);

  useEffect(() => {
    if (data.state) {
      setCities(City.getCitiesOfState(data.country, data.state));
    }
  }, [data.state, data.country]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onUpload = async (e) => {
    const base64 = await convertToBase64(e.target.files[0]);
    setData((prevData) => ({
      ...prevData,
      profilePic: base64
    }));
  };

  const handleUserProfile = useCallback(async (updatedData) => {
    try {
      const response = await axios.put(`https://api.huebits.in/api/userprofile/${userId}`, updatedData, {
        headers: {
          "content-type": "application/json",
          authorization: `jwt ${localStorage.getItem('token')}`
        },
      });
      if (response.data.success) {
        toast.success(response.data.message, { autoClose: 3000 });
        setShowToast(true);
        setData(response.data.data);
        console.log("Updated user profile data:", response.data.data);
      } else {
        toast.error(response.data.message, { autoClose: 3000 });
        setShowToast(true);
      }
    } catch (error) {
      console.error('Failed to update profile:', error);
      toast.error("Network error: Please check your connection and try again.", { autoClose: 3000 });
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    const { mobile } = data;

    // Validate mobile number
    const mobileRegex = /^\d{10}$/;
    if (!mobileRegex.test(mobile)) {
      toast.error('Please enter a 10-digit mobile number');
      setLoading(false);
      return;
    }

    const updatedData = { ...data };

    toast.dismiss();
    await handleUserProfile(updatedData);
  };

  useEffect(() => {
    if (token) {
      setUserId(userId);
      handleUserProfile(); 
    }
  }, [token, userId, handleUserProfile]);

  return (
    <div>
      {showToast && <ToastContainer position="top-center" className="toastcontainer" reverseOrder={false} />}
      <h1 className="form-profileh1">Contact Information</h1>
      <div className="students-dashboard-profile">
        <form action="/userprofile" method="POST" id="lable" onSubmit={(e) => handleSubmit(e)}>
          <div className='py-1' encType='multipart/form-data'>
            <div className='form-profile'>
              <label htmlFor="profile" className='profile1'>
                {data.profilePic ? (
                  <img src={data.profilePic} alt="Profile" className="profile2" />
                ) : (
                  <img src={avatar} alt="Default Avatar" className="profile2" />
                )}
              </label>
              <input onChange={onUpload} type="file" id="profile" name="profile" className='user-profile-pic' />
            </div>
          </div>
          <div className="form-group students-dashboard-profile-label">
            <div className="students-dashboard-profile-label1">
              <div>
                <label htmlFor="username">E-MAIL ID: </label>
                <input type="text" value={data.username} className="form-control students-profile-label" placeholder="enter email@gmail.com" readOnly />
              </div>
              <div>
                <label htmlFor="mobile">Mobile No: </label>
                <input
                  type="text"
                  name="mobile"
                  id="mobile"
                  className="form-control students-profile-label"
                  placeholder='e.g., +1 (123) 456-7890'
                  value={data.mobile}
                  onChange={handleOnChange}
                  pattern="\d{10}"
                  maxLength={10}
                  title="Please enter a 10-digit mobile number"
                />
              </div>
            </div>
            <div className="address-section">
              <label htmlFor="address">Address: </label>
              <div className="custom-select-wrapper">
                <select name="country" id="country" className="form-control form-address custom-select" value={data.country} onChange={handleOnChange}>
                  <option value="">Select Country</option>
                  {countries.map((country) => (
                    <option key={country.isoCode} value={country.isoCode}>{country.name}</option>
                  ))}
                </select>
                <i className="fas fa-caret-down dropdown-icon"></i>
              </div>
              <div className="custom-select-state">
                <select name="state" id="state" className="form-control form-address custom-select" value={data.state} onChange={handleOnChange} disabled={!data.country}>
                  <option value="">Select State</option>
                  {states.map((state) => (
                    <option key={state.isoCode} value={state.isoCode}>{state.name}</option>
                  ))}
                </select>
                <i className="fas fa-caret-down dropdown-icon"></i>
              </div>
              <div className="custom-select-city">
                <select name="city" id="city" className="form-control form-address custom-select" value={data.city} onChange={handleOnChange} disabled={!data.state}>
                  <option value="">Select City</option>
                  {cities.map((city) => (
                    <option key={city.name} value={city.name}>{city.name}</option>
                  ))}
                </select>
                <i className="fas fa-caret-down dropdown-icon"></i>
              </div>
            </div>
            <div>
              {loading && <p>Loading...</p>}
              <button type="submit" className="user-update">Update Profile</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Userprofile;
