import React from "react";
import SmartShoeImage from "../../assets/dashboard-images/images/images/1.png";
import Amazon from "../../assets/dashboard-images/images/images/2.png";
import Microsoft  from "../../assets/dashboard-images/images/images/3.png";
import Internet from "../../assets/dashboard-images/images/images/4.png";
import SmartHome from "../../assets/dashboard-images/images/images/5.png";
import ESP32Arduino from "../../assets/dashboard-images/images/images/6.png";
import NodeMCUMATLAB from "../../assets/dashboard-images/images/images/7.png";
import NternetESP32Arduino from "../../assets/dashboard-images/images/images/8.png";
import NodeRed from "../../assets/dashboard-images/images/images/9.png";
import BluetoothTechnology from "../../assets/dashboard-images/images/images/10.png";
import JavaScript from "../../assets/dashboard-images/images/images/11.png";
import SystemsProject from "../../assets/dashboard-images/images/images/12.png";
import SmartusingESP32 from "../../assets/dashboard-images/images/images/13.png";
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'bootstrap/dist/css/bootstrap.min.css';

import './start.css';

const courses = [
  {
    courseId: 1,
    courseTitle: "Introduction to Internet of Things",
    videoUrl: "",
    image: SmartShoeImage,
    Skills: "",
    completion: 0
  },
  {
    courseId: 2,
    courseTitle: "IoT and Embedded Systems with ESP32",
    videoUrl: "",
    image: Amazon,
    Skills: "",
    completion: 0
  },
  {
    courseId: 3,
    courseTitle: "IoT using Raspberry Pi and Python",
    videoUrl: "",
    image: Microsoft,
    Skills: "",
    completion: 0
  },
  {
    courseId: 4,
    courseTitle: "Master ESP32, Bluetooth, and BLE: Hands-On Projects for IoT Communication ",
    videoUrl: "",
    image: Internet,
    Skills: "",
    completion: 0
  },
  {
    courseId: 5,
    courseTitle: "Master WIFI communication",
    videoUrl: "",
    image: SmartHome,
    Skills: "",
    completion: 0
  },
  {
    courseId: 6,
    courseTitle: "Long distance wireless networks: GSM, Lora, NBIOT",
    videoUrl: "",
    image:  ESP32Arduino,
    completion: 0
  },
  {
    courseId: 7,
    courseTitle: "Master MQTT",
    videoUrl: "", 
    image: NodeMCUMATLAB,
    completion: 0
  },
  {
    courseId: 8,
    courseTitle: "Embedded Systems using ESP32 and ESP-IDF",
    videoUrl: "",
    image: NternetESP32Arduino,
    completion: 0
  },
  {
    courseId: 9,
    courseTitle: "Ethical Hacking and Cyber Security Fundamentals",
    videoUrl: "", 
    image: NodeRed,
    completion: 0
  },
  {
    courseId: 10,
    courseTitle: "IoT Security Mega Course",
    videoUrl: "", 
    image: BluetoothTechnology,
    completion: 0
  },
  {
    courseId: 11,
    courseTitle: "IoT Testing ",
    videoUrl: "", 
    image: JavaScript,
    completion: 0
  },
  {
    courseId: 12,
    courseTitle: "Cybersecurity with Raspberry Pi and Kali Linux",
    videoUrl: "", 
    image: SystemsProject,
    completion: 0
  },
  {
    courseId: 13,
    courseTitle: "Advanced IoT Security and Tools",
    videoUrl: "", 
    image: SmartusingESP32,
    completion: 0
  }
];

const Start = () => {


  return (
    <div>
    <div className="mylearningpaths">
      <h1 className="mylearningpaths-h1">IoT Security Specialist</h1>
      <p className="mylearningpaths-p">Courses</p>
      <div className="mylearningpath">
        <div className="Wrapper-sc-fvyqwl-1 iZEyIQ AssignmentList__Timeline-sc-1t9paan-2 chIzFH">
          <ul className="Circle-sc-fvyqwl-1">
            {courses.map((course, index) => (
              <li key={course.courseId}>
                <div className="learningpath-courses-dashboard">
                  <div>
                  <img src={course.image} alt={course.courseTitle} className="smart-shoe-development" />
                  </div>
                  <div>
                  <p className={`pythoncourses${course.courseId}`}>
                  {index + 1}. {course.courseTitle}
                  </p>
                  <p className="learningpath-courses-dashboard-skills">Skills: <span className="learningpath-courses-dashboard-skills-span">{course.Skills}</span></p>
                  <div className="learningpath-courses-dashboard-progressbar">
                  <p className="learningpath-courses-dashboard-skills-completed">{course.completion}%</p>
                  <ProgressBar now={course.completion} className={`custom-progress-bar`} />
                  </div>
                  <div>
                     <div>
                    {course.completion === 100 ? (
                      <>
                      <button className="learningpath-courses-dashboard-button-start">Download Certificate</button>
                      </>
                    ) : course.completion >= 10 ? (
                      <button  className="learningpath-courses-dashboard-button-start">Continue Learning</button>
                    ) : (
                      <button className="learningpath-courses-dashboard-button-start">Start Learning</button>
                    )}
                  </div>
                  </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <p className="mylearningpaths-p">Recommended Projects</p>
    </div>
  </div>
);
};

export default Start;