import React from "react";

const NotificationComponent = () => {
  return (
    <div className="notification">
      <h2 className="notification-h2">Notifications</h2>
    </div>
  );
};

export default NotificationComponent;