import React from "react";
import './capstoneaimagic.css';

const Capstoneaimagic = () => {
    
    return (
        <div className="dashboardheader2">
        <div className="students-comingsoon">
          <h1 className="capstone">Capstone AI Magic Gateway to your Capstone</h1>
          <button className="capstonestart livesessions">
            Coming Soon
            </button>
        </div>
        </div>
    );
};

export default Capstoneaimagic;