import React from "react";

const Data = () => {
    return (
    <div className="students-datascience1">
        <h1 className="students-datascience1-h1">Data-Science</h1>
        <button type="submit" className="students-datascience1-btn">Coming Soon</button>
    </div>
    )
}

export default Data;