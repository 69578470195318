import React from 'react';
import Shirt1 from "../../../assets/Shirt1.png";
import Shirt2 from "../../../assets/Shirt2.png";
import Shirt3 from "../../../assets/Shirt3.png";
import Shirt4 from "../../../assets/Shirt4.png";
import Shirt5 from "../../../assets/Shirt5.png";
import Shirt6 from "../../../assets/Shirt6.png";

const YourComponentName = () => {
  return (
    <div className="background-colors-works">
      <div>
        <h1 className="how-it-works">Design The Future With Creative Journey</h1>
      </div>
      <div className="colorwhitecards">
        <div className="cardflexs-1 colorwhitecard" style={{ borderLeft: '6px solid #052659', borderWidth: '4px', borderStyle: 'solid',borderRight: '0px', borderTop: '0px',borderBottom:'0px' }}>
          <div className="steps1">
            <img src={Shirt1} alt="Shirt Business Icon" className="Shirt" />
            <div className="steps-1">
              <h5 className="step-title">Step-1</h5>
              <p>
                <span className="spansp-get">Personalize</span>
              </p>
            </div>
          </div>
          <div className="steps2">
            <img src={Shirt2} alt="Shirt Business Icon" className="Shirt" />
            <div className="steps-2">
              <h5 className="step-title">Step-2</h5>
              <p>
                <span className="spansp-get">Build Industrial projects</span>
              </p>
            </div>
          </div>
          <div className="steps3">
            <img src={Shirt3} alt="Shirt Business Icon" className="Shirt" />
            <div className="steps-3">
              <h5 className="step-title">Step-3</h5>
              <p>
                <span className="spansp-get">Earn Your Certification</span>
              </p>
            </div>
          </div>
          <div className="steps4">
          <img src={Shirt4} alt="Shirt Business Icon" className="Shirt" />
            <div className="steps-4">
              <h5 className="step-title">Step-4</h5>
              <p>
                <span className="spansp-get">Participate and Solve</span>
              </p>
            </div>
          </div>
          <div className="steps5">
          <img src={Shirt5} alt="Shirt Business Icon" className="Shirt" />
            <div className="steps-5">
              <h5 className="step-title">Step-5</h5>
              <p className="step-text">
                <span className="spansp-get">
                Build network and showcase
                </span>
              </p>
            </div>
          </div>
          <div className="steps6">
          <img src={Shirt6} alt="Shirt Business Icon" className="Shirt" />
            <div className="steps-6">
              <h5 className="step-title">Step-6</h5>
              <p>
                <span className="spansp-get">Get Hired</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      </div>
  );
};

export default YourComponentName;