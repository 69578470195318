import React from 'react';
import { Link } from 'react-router-dom';
import "./getaccesstoallcourses.css";

const Getaccesstoallcourses = () => {
  return (
    <div>
        <div className='students-dashboard-courses-membership1'>
            <h1 className='students-dashboard-courses-membership2'>Looks like you are not subscribed with us</h1>
        <Link to="https://learn.huebits.in/membership"><button className='students-dashboard-courses-membership-button'>Get Access To All Courses</button></Link>
        </div>
    </div>
  )
}

export default Getaccesstoallcourses;
