import React from 'react';

const Icon = ({ svgPath, customId, iconName }) => {
  return (
    <div className={`card-img-top-1 ${iconName}-icon`} data-brz-custom-id={customId}>
      <span className="brz-icon brz-span brz-css-eWzBQ">
        <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 24 24" className="brz-icon-svg align-[initial]" data-type="glyph" data-name={iconName}>
          <g fill="currentColor" className="nc-icon-wrapper">
            {svgPath}
          </g>
        </svg>
      </span>
    </div>
  );
};

export const BasketSimpleIcon = () => (
  <Icon 
    svgPath={<>
      <path d="m1.969 14 2.061 8.243A1 1 0 0 0 5 23h13a1 1 0 0 0 .949-.684L21.721 14H1.969z"></path>
      <path d="M23 8h-3.071l-4.081-6.53a1.001 1.001 0 0 0-1.696 1.06L17.571 8H6.429l3.419-5.47a1.001 1.001 0 0 0-1.696-1.06L4.071 8H1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h22a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1z" data-color="color-2"></path>
    </>}
    customId="btiwtgigpwkdbdepclgcewghiryjfxmfdakm"
    iconName="basket-simple"
  />
);

export const MeetingIcon = () => (
  <Icon 
    svgPath={<>
      <circle cx="19" cy="16" r="3"></circle>
      <path d="M24 24H14v-1.084c0-.654.315-1.265.851-1.639C15.67 20.705 17.071 20 19 20s3.33.705 4.149 1.277c.536.375.851.986.851 1.639V24z"></path>
      <circle cx="5" cy="16" r="3"></circle>
      <path d="M10 24H0v-1.084c0-.654.315-1.265.851-1.639C1.67 20.705 3.071 20 5 20s3.33.705 4.149 1.277c.536.375.851.986.851 1.639V24z"></path>
      <circle cx="12" cy="3" r="3" data-color="color-2"></circle>
      <path d="M17 11H7V9.916c0-.654.315-1.265.851-1.639C8.67 7.705 10.071 7 12 7s3.33.705 4.149 1.277c.536.375.851.986.851 1.639V11z" data-color="color-2"></path>
    </>}
    customId="zygtblkixkszitbxyjxdneqmbfdpolbxqgzc"
    iconName="meeting"
  />
);

export const ShareIcon = () => (
  <Icon 
    svgPath={<>
      <path d="M19 17v5H2V8h2V6H1a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h19a1 1 0 0 0 1-1v-6h-2z" data-color="color-2"></path>
      <path d="M13 6.026C6.867 6.361 4 9.872 4 17v2.96l1.795-2.354C7.363 15.55 8.748 14.163 13 14.012v5.191L23.519 10 13 .796v5.23z"></path>
    </>}
    customId="hhnhdcgjvfqsoarkwpzjsczgueomtygyaqtx"
    iconName="share-91"
  />
);

export const WebDesignIcon = () => (
  <Icon 
    svgPath={<>
      <path d="M18.051 1H1a1 1 0 0 0-1 1v2h15.051l3-3z" data-color="color-2"></path>
      <path d="M18.994 9.956a4.852 4.852 0 0 1-1.433 3.427C16.487 14.456 14.374 15 11.28 15l-.331-.004-1.895-.051-.051-1.895c-.04-1.464.039-5.038 1.613-6.612.166-.164.351-.3.534-.438H0v16a1 1 0 0 0 1 1h22a1 1 0 0 0 1-1V6h-1.05l-3.956 3.956z"></path>
      <path d="M23.561.439a1.5 1.5 0 0 0-2.121 0l-6.655 6.655a2.9 2.9 0 0 0-2.753.758c-1.131 1.131-1.03 5.108-1.029 5.145.037.001 4.013.102 5.145-1.029a2.9 2.9 0 0 0 .758-2.753l6.655-6.655a1.501 1.501 0 0 0 0-2.121z" data-color="color-2"></path>
    </>}
    customId="nlrtjmkhwoarbmuozhpxmbhuzthcerrrfcbp"
    iconName="web-design"
  />
);

const AllIcons = () => {
  return (
    <div>
      <BasketSimpleIcon />
      <MeetingIcon />
      <ShareIcon />
      <WebDesignIcon />
    </div>
  );
};

export default AllIcons;
