import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Headrepage from "../../components/Homepages/Headerpage/StudentsHederpage";
import EyesOpen from "../../iconsopen/EyesOpen";
import EyesClose from "../../iconsopen/EyesClose";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ForgotPassword.css';

const ForgotPassword = () => {
  const [username, setUsername] = useState('');
  const [otp, setOTP] = useState('');
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(null); 
  const [timeLeft, setTimeLeft] = useState(0);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const startTimer = () => {
    setTimeLeft(60);
    setTimer(setInterval(() => {
        setTimeLeft((prevTime) => {
            if (prevTime === 0) {
                clearInterval(timer);
                setOTP(''); 
            }
            return prevTime - 1;
        });
    }, 1000));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const toastId = toast.loading("Sending OTP...");

    try {
      const response = await axios.post('https://api.huebits.in/api/generateOTP', { username });
      setOTP(response.data.code);
      toast.update(toastId, { render: response.data.message, type: "success", isLoading: false, autoClose: 3000 });

      startTimer(); 
    } catch (error) {
      if (error.response) {
        toast.update(toastId, { render: error.response.data.message || 'Please try again later.', type: "error", isLoading: false, autoClose: 3000 });
      } else if (error.request) {
        toast.update(toastId, { render: "Network error: Please check your connection and try again.", type: "error", isLoading: false, autoClose: 3000 });
      } else {
        toast.update(toastId, { render: `Error: ${error.message}`, type: "error", isLoading: false, autoClose: 3000 });
      }
      //console.error('Forgot Password Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const verifyOTP = async () => {
    try {
      const response = await axios.post(`https://api.huebits.in/api/verify-otp`, { username, code: otp });
      //console.log('Response from server:', response.data);
      if (response.data.success) {
        toast.success('OTP verified successfully!');
        clearInterval(timer);
        setTimeLeft(0);
        setOTP('');
        return true;
      } else {
        toast.error('Invalid OTP.');
        return false;
      }
    } catch (error) {
      if (error.response) {
        toast.error(` ${error.response.data.message || 'Please try again later.'}`);
      } else if (error.request) {
        toast.error("Network error: Please check your connection and try again.");
      } else {
        toast.error(`Error: ${error.message}`);
      }
      //console.error('Forgot Password Error:', error);
      return false;
    }
  };

  const clearPasswordError = () => {
    document.getElementById("alert").innerText = "";
  };

  const resetPassword = async () => {
   const regExspecialChars = /^(?=.*\d)(?=.*[A-Z])(?=(.*\w){2})(?=.*[a-z])(?!.*\s).{8,}$/;
  if (newPassword === "" || newPassword === null) {
    toast.error("Password should not be blank");
    return;
  } else if (newPassword.length < 8) {
    toast.error("Password should be more than 8 characters");
    return;
  } else if (!regExspecialChars.test(newPassword)) {
    toast.error("Password should contain at least 1 digit, 1 special characters, and 1 lower and upper letters");
    return;
  } else if (newPassword !== confirmPassword) {
    toast.error("Confirm password does not match");
    return;
  }

  if (username === "") {
    toast.error("Username cannot be empty");
    return;
  }

  const toastId = toast.loading("Resetting password...");

  //console.log('Reset password request payload:', { username, newPassword, confirmPassword });
    try {
      const response = await axios.post('https://api.huebits.in/api/forgot-password', {
        username,
        newPassword,
        confirmPassword
      });

      //console.log('Password reset response:', response.data);

      if (response.data.success) {
        toast.update(toastId, { render: "Password reset successful!", type: "success", isLoading: false, autoClose: 3000 });
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      } else {
        toast.update(toastId, { render: response.data.message || 'Failed to reset password.', type: "error", isLoading: false, autoClose: 3000 });
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      if (error.response) {
        toast.update(toastId, { render: error.response.data.message || "An unexpected error occurred. Please try again.", type: "error", isLoading: false, autoClose: 3000 });
      } else if (error.request) {
        toast.update(toastId, { render: "Network error: Please check your connection and try again.", type: "error", isLoading: false, autoClose: 3000 });
      } else {
        toast.update(toastId, { render: `Request error: ${error.message}`, type: "error", isLoading: false, autoClose: 3000 });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (timeLeft === 0) {
      clearInterval(timer);
    }
  }, [timeLeft, timer]);

  return (
    <div className="students-flex">
      <Headrepage />
      <div className="studentssign-cssimagesflex-color">
        <ToastContainer position="top-center" />
          <div className="recoverpassword">
            <h1 className="recoveres">Forgot Password</h1>
            <div className="recoverpasswords">
              <form onSubmit={handleSubmit}>
                <label htmlFor="email" className="recoverpassword-label1">Enter E-Mail Id</label>
                <div className="form-group">
                  <input type="email" placeholder="Enter your email" className="recoverpassword-form-control" id="username" name="username" value={username} onChange={(e) => setUsername(e.target.value)} required />
                  <button type="submit" className='recoverpassword-send-button' disabled={loading} >
                  {loading ? "Sending OTP..." : "Send OTP"}
                  </button>
                </div>
              </form>
              <label className="recoverpassword-label1">Enter 6 digit OTP sent to your email address.</label>
              <div className="form-group">
                <input type="text" placeholder="Enter OTP" className="recoverpassword-form-control" value={otp} onChange={(e) => setOTP(e.target.value)} />
                <button onClick={verifyOTP} className='recoverpassword-send-button'>Verify OTP</button>
              </div>
              {timeLeft > 0 && (
                <div style={{ color: timeLeft <= 10 ? 'red' : 'red', marginLeft: '140px', fontWeight: 700, fontSize: '1rem' }}>
                  Time left for OTP verification: {timeLeft} seconds
                </div>
              )}
              <form onSubmit={(e) => handleSubmit(e)} >
              <div id="alert"></div>
              <div className="form-group forgot-paswword">
                <label htmlFor="password" className="recoverpassword-label1">Enter New Password</label>
                <input 
                  type={showPassword ? "text" : "password"} 
                  id="password" 
                  name="password" 
                  className="recoverpassword-form-control"  
                  placeholder="Enter your password" 
                  autoComplete="off" 
                  value={newPassword} 
                  disabled={loading} 
                  onChange={(e) => setNewPassword(e.target.value)} 
                  required 
                  onBlur={clearPasswordError} 
                />
                <div className='recoverpassword-icons-password' onClick={()=>setShowPassword(prev => !prev)}>
                  {
                    showPassword ? <EyesOpen/> :  <EyesClose/>
                  }
                </div>
              </div>
              <div className='forgot-paswword'>
                <label htmlFor="confirm_password" className="recoverpassword-label1">Enter Confirm Password</label>
                <input 
                  type="password" 
                  id="confirm_password" 
                  className="recoverpassword-form-control"  
                  name="confirmPassword" 
                  placeholder="Confirm password"
                  value={confirmPassword} 
                  disabled={loading} 
                  onChange={(e) => setConfirmPassword(e.target.value)} 
                  autoComplete="off" 
                  onBlur={clearPasswordError} 
                  required 
                />
              </div>
              </form>
              <button type="button" className="recoverpassword-send-button-password" id="signin" onClick={resetPassword} >
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>
  );
};

export default ForgotPassword;
